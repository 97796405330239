import dayjs from 'dayjs'
import { Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ReportCriteria } from '../../../api/reports/types/globalTypes'
import { FormikError } from '../globalTypes'
import ReportFilterForm from '../ReportFilterForm/ReportFilterForm'
import { FormFields, ReportListFilterProps } from './types'

const ReportListFilter = (props: ReportListFilterProps) => {
    const { t } = useTranslation()
    const { reportType, criteria, onFilterSubmit, onFilterReset } = props

    const initialValues = {
        dateFrom: '',
        dateTo: '',
        agents: [],
        activityTypes: [],
        contactStatuses: [],
        propertyStatuses: [],
        dataSourcees: [],
    }

    const validation = Yup.object({
        dateFrom: Yup.date().required(),
        dateTo: Yup.date().required(),
    })

    const setDateErrorMessage = (fields: FormFields) => {
        const errors = {} as FormikError
        const { dateFrom, dateTo } = fields

        if (dateFrom && dateTo) {
            if (dayjs(dateFrom).isAfter(dateTo)) {
                errors.dateFrom = t('reports.form.errors.dateValidation')
            }

            const monthsDiff = dayjs(dateTo).diff(dayjs(dateFrom), 'month', true)
            if (monthsDiff > 11) {
                errors.dateTo = t('reports.form.errors.dateRange')
            }
        }

        return errors
    }

    return (
        <Formik
            component={(props: FormikProps<ReportCriteria>) => <ReportFilterForm {...props} reportType={reportType} />}
            initialValues={criteria || initialValues}
            validate={setDateErrorMessage}
            validationSchema={validation}
            onSubmit={onFilterSubmit}
            onReset={onFilterReset}
        />
    )
}

export default ReportListFilter
