import values from 'lodash/values'
import { stateKey } from './actions'

export const selectCompanies = (state) => values(state[stateKey].items)
export const selectCompany = (state) => state[stateKey].item
export const selectCompaniesFetching = (state) => state[stateKey].fetching
export const selectCompaniesCount = (state) => state[stateKey].count
export const selectCompanyFetchingDetails = (state) => state[stateKey].fetchingDetails
export const selectCompanyProfile = (state, id) => state[stateKey].item?.profiles?.[id]
export const selectCompaniesPagination = (state) => state[stateKey].pagination
export const selectCompanyLinks = (state) => state[stateKey].links

// used for displaying company name inside document form 
export const selectCompanyAsyncDocumentCompanyFormOptions = (state) => {
    const data = state[stateKey].item

    if (data) {
        return [
            {
                label: data?.contactCompany?.name,
                value: data.internalAppId?.toString(),
            },
        ]
    }

    return []
}

// used for displaying company name inside activity modal 
export const selectCompanyAsyncActivityCompanyFormOptions = (state) => {
    const data = state[stateKey].item

    if (data) {
        return [
            {
                label: data?.contactCompany?.name,
                value: data.id?.toString(),
            },
        ]
    }

    return []
}
