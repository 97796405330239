import QueryString from 'qs'
import { toast } from 'react-toastify'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { apiRequest } from '../api'
import i18n from '../../i18n'
import {
    fetchDiaries,
    fetchDiariesRequest,
    fetchDiariesSuccess,
    fetchDiariesFailure,
    fetchDiary,
    fetchDiaryRequest,
    fetchDiarySuccess,
    fetchDiaryFailure,
    updateDiary,
    updateDiaryRequest,
    updateDiarySuccess,
    updateDiaryFailure,
    deleteDiary,
    deleteDiaryRequest,
    deleteDiarySuccess,
    fetchDiariesNoStore,
    fetchDiariesNoStoreRequest,
    fetchDiariesNoStoreSuccess,
    fetchDiariesNoStoreFailure,
    downloadDiaryDocument,
    downloadDiaryDocumentRequest,
    downloadDiaryDocumentSuccess,
    downloadDiaryDocumentFailure,
} from './actions'

export function* fetchDiariesSaga({ payload, meta }) {
    const params = payload

    yield put(fetchDiariesRequest())
    try {
        const response = yield apiRequest(`v1/intermediary-diary`, {
            method: 'GET',
            params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchDiariesSuccess(response.data))
        meta(response.data.count)
    } catch (error) {
        yield put(fetchDiariesFailure(error))
    }
}

export function* fetchDiarySaga({ payload, meta }) {
    const { id, order, criteria } = payload

    yield put(fetchDiaryRequest())
    try {
        const response = yield apiRequest(`v1/intermediary-diary/${id}`, {
            method: 'GET',
            params: { order, criteria },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchDiarySuccess(response.data))

        if (meta) {
            meta({
                data: response.data,
                status: SUBMIT_RESPONSE_STATUS.SUCCESS,
            })
        }
    } catch (error) {
        yield put(fetchDiaryFailure(error))
    }
}

export function* updateDiarySaga({ payload, meta }) {
    yield put(updateDiaryRequest())
    const { id, ...data } = payload
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest(`v1/intermediary-diary/${id}`, {
            method: 'PUT',
            data: data,
        })
        yield put(updateDiarySuccess(response))
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
            })
        }
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(updateDiaryFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* deleteDiariesById({ payload }) {
    yield put(deleteDiaryRequest())
    const progressToast = toast.loading(i18n.t('toast.loading.delete'))
    try {
        const response = yield apiRequest(`v1/intermediary-diary/${payload}`, {
            method: 'DELETE',
        })
        yield put(deleteDiarySuccess(response))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(updateDiaryFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* fetchDiariesNoStoreSaga({ payload, meta }) {
    const params = payload
    yield put(fetchDiariesNoStoreRequest())
    try {
        const response = yield apiRequest(`v1/intermediary-diary`, {
            method: 'GET',
            params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchDiariesNoStoreSuccess())
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
    } catch (error) {
        yield put(fetchDiariesNoStoreFailure(error))
        meta.callback({
            submitStatus: SUBMIT_RESPONSE_STATUS.FAILURE,
        })
    }
}

export function* downloadDiaryDocumentSaga({ payload }) {
    yield put(downloadDiaryDocumentRequest())
    try {
        console.log(payload)
        const response = yield apiRequest(`v1/intermediary-diary/download/${payload}`, {
            method: 'GET',
        })

        yield put(downloadDiaryDocumentSuccess(response.data))
    } catch (error) {
        yield put(downloadDiaryDocumentFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchDiaries}`, fetchDiariesSaga)
    yield takeLatest(`${fetchDiary}`, fetchDiarySaga)
    yield takeEvery(`${updateDiary}`, updateDiarySaga)
    yield takeEvery(`${deleteDiary}`, deleteDiariesById)
    yield takeEvery(`${downloadDiaryDocument}`, downloadDiaryDocumentSaga)
    yield takeLatest(`${fetchDiariesNoStore}`, fetchDiariesNoStoreSaga)
}
