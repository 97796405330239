import i18n from '../../../i18n'

export const removeSpecialChar = (string) => {
    return string?.toLowerCase()?.replace('č', 'c')?.replace('ć', 'c')?.replace('ž', 'z')?.replace('š', 's')?.replace('đ', 'd')
}

export const cleanObject = (obj) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName]
        }
    }
    return obj
}

// if searchItem is "bedrooms 2" returns "2" etc...
export const getSearchValue = (searchItem, filterByChar) => {
    const [searchText, searchValue] = searchItem.trim().split(/\s+/, 2)
    const isNotRangeSearch = !searchItem.includes(i18n.t('quickSearch.rangeFrom')) && !searchItem.includes(i18n.t('quickSearch.rangeTo'))

    if (!searchText || !searchValue) return false

    if (searchText === filterByChar && isNotRangeSearch) {
        return searchValue
    }

    return false
}

// if searchItem is "bedrooms from 2 up to 3" returns ["2", "3"] etc...
export const getRange = (searchItem, filterByChar) => {
    const [searchText] = searchItem.trim().split(/\s+/, 1)
    const isCorrectFilter = searchText === filterByChar
    const isRangeSearch = searchItem.includes(i18n.t('quickSearch.rangeFrom')) || searchItem.includes(i18n.t('quickSearch.rangeTo'))

    if (!isCorrectFilter || !isRangeSearch) return false

    const rangeText = searchItem.replaceAll(filterByChar, '').replace(i18n.t('quickSearch.rangeFrom'), '').split(i18n.t('quickSearch.rangeTo'))
    const rangeValues = rangeText.filter((item) => item && item.trim()).map((item) => item.trim())

    return rangeValues.length > 0 ? rangeValues : false
}
