import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Button, Card, CardBody, CardHeader, Col, Input, Row, UncontrolledTooltip } from 'reactstrap'
import { getQueryData, setQueryData } from '../api/helpers'
import { API_URL } from '../common/constants'
import { photosFields } from '../common/forms/photos/fields'
import { convertDateStringToTimeStamp } from '../utils/general'

const PhotoCard = ({
    photo,
    isUploaded = true,
    handleFullSizeClick,
    className,
    handleCheckboxes,
    handlePhotoSync,
    localeOptions,
    checkboxClassName = 'materialsPhotoCheckbox',
}) => {
    const { t } = useTranslation()
    
    // TODO: /original?timestamp url can be removed when backend fix the issue
    const timestamp = convertDateStringToTimeStamp(photo?.updated)
    const originalImageSizeUrl = `${API_URL}v1/file/show/${photo?.id}/original?timestamp=${timestamp}`
    
    const currentLang = localStorage.getItem('i18nextLng')
    const defaultOption = localeOptions.find(option => option.value === currentLang)
    
    const [selectedLocale, setSelectedLocale] = useState(defaultOption)

    const fileDescription = photo?.translations?.[selectedLocale?.value]?.fileDescription ?? ''

    const handleChange = (e) => {
        const { id: imageId, value: updatedDescription } = e.target
        const locale = selectedLocale.value

        const { collection, module, parent } = photo
        const images = getQueryData([collection, module, parent.toString()])

        const updatedImages = images?.map((image) =>
            image.id === +imageId
                ? {
                      ...image,
                      translations: {
                          ...image.translations,
                          [locale]: {
                              fileDescription: updatedDescription,
                              isTranslationsUpdate: true,
                          },
                      },
                  }
                : image
        )

        setQueryData([collection, module, parent.toString()], updatedImages)
    }

    if (!photo) return

    return (
        <Card className={classNames('dmn-photo-card', className)}>
            {isUploaded && (
                <CardHeader className="bg-dark border-0 px-2 py-1">
                    <div className="float-end d-flex align-items-center">
                        <Button onClick={() => handleFullSizeClick(photo)} color="dark" className="btn-icon">
                            <i className="mdi mdi-arrow-top-right-bottom-left fs-19" />{' '}
                        </Button>
                        <div className="form-check ms-2">
                            <Input
                                onChange={() => handleCheckboxes()}
                                type="checkbox"
                                className={`form-check-input ${checkboxClassName}`}
                                value={photo.id}
                            />
                        </div>
                    </div>
                    <Button color="dark" className="btn-icon" style={{ cursor: 'move' }} onClick={(e) => e.preventDefault()}>
                        {' '}
                        <i className="mdi mdi-menu fs-19" />{' '}
                    </Button>
                </CardHeader>
            )}
            <div className="card-img" style={{ position: 'relative', overflow: 'hidden' }}>
                <UncontrolledTooltip target={`fileSync_${photo.id}`}>
                    {photo.fileSync ? t('form.common.photo.synchronized') : t('form.common.photo.markForSync')}
                </UncontrolledTooltip>
                <span
                    id={`fileSync_${photo.id}`}
                    className={`text-success dmn-photo-sync-icon rounded-circle ${photo.fileSync ? 'dmn-photo-synced' : ''}`}
                    onClick={() => handlePhotoSync(photo.id)}
                >
                    <i className={`mdi mdi-check`}></i>
                </span>

                <img className="img-fluid" src={originalImageSizeUrl} alt={photo.fileDescription} />
            </div>
            {isUploaded && (
                <CardBody>
                    <Row className="align-items-center">
                        <Col sm={7}>
                            <Input type="text" className="form-control" onChange={handleChange} value={fileDescription} id={photo.id} />
                        </Col>
                        <Col sm={5}>
                            <Select
                                onChange={(e) => setSelectedLocale(e)}
                                options={localeOptions}
                                name={`${photosFields.PHOTO_LOCALE}${photo.id}`}
                                id={`${photosFields.PHOTO_LOCALE}${photo.id}`}
                                value={selectedLocale}
                                placeholder=""
                            />
                        </Col>
                    </Row>
                </CardBody>
            )}
        </Card>
    )
}

export default PhotoCard