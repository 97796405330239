import i18next from 'i18next'

const crop1_1button = () => {
    const squareBtn = document.querySelector('.preset-square')

    if (squareBtn) {
        const labelElement = squareBtn.querySelector('label')

        if (labelElement) {
            labelElement.textContent = '1:1'
        }
    }
}

const cropNewButton = (editorInstance, prevBtnClass, text, cropMode) => {
    const prevBtn = document.querySelector(prevBtnClass)

    const newBtn = document.createElement('div')
    newBtn.classList.add('preset', 'preset-new')

    newBtn.insertAdjacentHTML(
        'beforeend',
        `
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
            <g fill="none" fill-rule="evenodd">
                <path d="M0 0h24v24H0z"/>
                <path fill="currentColor" d="M4 0h1v20a1 1 0 0 1-1-1V0zM20 17h-1V5h1v12zm0 2v5h-1v-5h1z"/>
                <path fill="currentColor" d="M5 19h19v1H5zM4.762 4v1H0V4h4.762zM7 4h12a1 1 0 0 1 1 1H7V4z"/>
            </g>
        </svg>
    `
    )

    const textCrop = document.createElement('span')
    textCrop.textContent = text
    newBtn.appendChild(textCrop)

    if (prevBtn && newBtn) {
        prevBtn.insertAdjacentElement('afterend', newBtn)
    }

    newBtn.addEventListener('click', () => editorInstance.setCropzoneRect(cropMode))
}

const undoButton = (editorInstance) => {
    const { t } = i18next
    const undo = document.createElement('li')
    undo.classList.add('undo', 'tui-image-editor-item')
    undo.setAttribute('tooltip-content', t('photoeditor.undo'))

    undo.insertAdjacentHTML(
        'beforeend',
        `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fill-rule="evenodd">
                <path d="M24 0H0v24h24z" opacity=".5"/>
                <path fill="#8a8a8a" d="M3 6h12a6 6 0 1 1 0 12H3v1h12a7 7 0 0 0 0-14H3v1z"/>
                <path stroke="#8a8a8a" stroke-linecap="square" d="M5 3L2.5 5.5 5 8"/>
            </g>
        </svg>
    `
    )

    const cropModeButton = document.querySelector('.tie-btn-crop')

    if (cropModeButton) {
        cropModeButton.insertAdjacentElement('beforebegin', undo)
    }

    const handleUndo = () => {
        const isEmpty = editorInstance.isEmptyUndoStack()

        if (!isEmpty) {
            editorInstance.discardSelection()
            editorInstance.undo()
        }
    }

    undo.addEventListener('click', handleUndo)
}

const handleInputTooltip = () => {
    const inputs = document.querySelectorAll('.tui-image-editor-range-value')

    inputs.forEach((input) => {
        const { t } = i18next
        const targetClasses = ['tie-width-range-value', 'tie-height-range-value', 'tie-rotate-range-value']
        const hasTargetClass = targetClasses.some((target) => input.classList.contains(target))

        if (hasTargetClass) {
            const tooltip = document.createElement('div')
            tooltip.className = 'custom-tooltip'
            tooltip.textContent = t('photoeditor.sliderNumberTooltip')

            input.parentNode.appendChild(tooltip)

            input.addEventListener('mouseenter', () => {
                tooltip.style.display = 'block'
            })

            input.addEventListener('mouseleave', () => {
                tooltip.style.display = 'none'
            })
        }
    })
}

export { crop1_1button, cropNewButton, handleInputTooltip, undoButton }
