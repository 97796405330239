export const contactResponseFields = {
    // ADDITIONAL_CONTACT: 'additionalContact',
    CITY: 'city',
    CONTACT_COMPANY: 'contactCompany',
    CONTACT_DESCRIPTION: 'contactDescription',
    CONTACT_TITLE: 'contactTitle',
    CONTACT_EMAIL: 'contactEmail',
    CONTACT_PERSON: 'contactPerson',
    CONTACT_PERSON_COMPANY: 'contactPersonCompany',
    CONTACT_PHONE: 'contactPhone',
    CONTACT_PRIORITY: 'contactPriority',
    CONTACT_QUERY_CATEGORY: 'contactQueryCategory',
    CONTACT_STATUS: 'contactStatus',
    CONTACT_TYPE: 'contactType',
    COUNTRY: 'country',
    DATA_SOURCE: 'dataSource',
    ID: 'id',
    INTERNAL_APP_ID: 'internalAppId',
    INITIAL_CONTACT_DATE: 'initialContactDate',
    NOTES: 'notes',
    STREET: 'street',
    STREET_NUMBER: 'streetNumber',
    TAX_NUMBER: 'taxNumber',
    ZIP_CODE: 'zipCode',
    FIRST_NAME: 'firstName',
    MIDDLE_NAME: 'middleName',
    LAST_NAME: 'lastName',
    GENDER: 'contactGender',
    BIRTH_DATE: 'birthDate',
    CONTACT_MARITAL_STATUS: 'contactMaritalStatus',
    CONTACT_JOB_TITLE: 'contactJobTitle',
    EMPLOYMENT_TYPE: 'employmentType',
    AGENT: 'agent',
    INITIAL_CALL_INFO: 'initialCallInfo',
    LAST_CONTACT_PROFILE: 'lastContactProfile',
    CONTACT_PROFILE_STATUS: 'contactProfileStatus',
    CONTACT_DEFINITION: 'contactDefinition',
    COMPANY_DEFINITION: 'companyDefinition',
    CONTACT_MARTIAL_STATUS: 'contactMartialStatus',
    CONTACT_GENDER: 'contactGender',
    UPDATED: 'updated',
    CREATED: 'created',
    PROPERTY_SUB_TYPE: 'propertySubType',
    COUNTY: 'county',
    WIDER_AREA: 'widerArea',
    QUARTER: 'quarter',
    ISLAND: 'island',
    PRICE_FROM: 'priceFrom',
    PRICE_TO: 'priceTo',
    PRICE_M2_FROM: 'priceM2From',
    PRICE_M2_TO: 'priceM2To',
    ROOMS_FROM: 'roomsFrom',
    ROOMS_TO: 'roomsTo',
    BEDROOMS_FROM: 'bedroomsFrom',
    BEDROOMS_TO: 'bedroomsTo',
    AREA_FROM: 'areaFrom',
    AREA_TO: 'areaTo',
    PROPERTY_FLOOR_POSITION: 'propertyFloorPosition',
    PROPERTY_FLOOR: 'propertyFloor',
    EXCLUDE_BASEMENT: "excludeBasement",
    EXCLUDE_LAST_FLOOR: 'excludeLastFloor',
    PROPERTY_SPACE: 'propertySpace',
    PROPERTY_DESCRIPTION: 'propertyDescription',
    PROPERTY_EQUIPMENT: 'propertyEquipment',
    PROPERTY_UTILITY: 'propertyUtility',
    PROPERTY_HEATING: 'propertyHeating',
    PROFILE_REMARK: 'contactProfileRemark',
    CONTACT_FINANCING: 'contactFinancing',
    CONTACT_PROFILE_DURATION: 'contactProfileDuration',
    FLOOR_FROM: 'floorFrom',
    FLOOR_TO: 'floorTo',
    PROPERTY_DESCRIPTIONS: 'propertyDescriptions',
    CONTACT_PROFILE_SERVICE: 'contactProfileService',
    CONTACT_ABOUT: 'contactAbout',
    CONTACT_COUNTRY: 'contactCountry',
    SPECIAL_REQUEST: 'specialRequest',
    CONTACT_SOURCE: 'contactSource',
    PREVIOUS_ACTIVITY: 'previousActivity',
    NEXT_ACTIVITY: 'nextActivity'
}