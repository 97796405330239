import classNames from 'classnames'
import dayjs from 'dayjs'
import { forwardRef } from 'react'
import Select from 'react-select'
import { Label, Input, FormFeedback } from 'reactstrap'
import { findObjectByQuestionId } from '../helpers'
import styles from './DynamicUI.module.css'
import InputPrice from './UI/InputPrice'
import { ConnectedQuestionsIdsToDelete, DynamicUIProps } from './DynamicUI.types'
import { DepthAnalysisOptions, FormSubmissionAnswers } from '../../../api/inDepthAnalysis/types'

const DynamicUI = forwardRef<HTMLDivElement, DynamicUIProps>((props, _ref) => {
    const {
        question: { id, type, text, required, riskFactor, options, hidden },
        questionIndex,
        errors,
        formik,
        connectedQuestionsIdsToDelete,
        sectionRefs,
        selectRefs,
        questions,
        updateRequiredBySection,
        updateRequiredByQuestion,
    } = props

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Flatpickr = require('react-flatpickr').default

    const findErrorsById = (obj: any, number: number) => {
        const key = Object.keys(obj).find((key) => key.includes(`_${number}_`))
        return key ? { key, value: obj[key] } : null
    }

    const resetSelectAtIndex = (index: string | number) => {
        if (selectRefs.current[index as number]) {
            selectRefs.current[index as number].clearValue()
        }
    }

    const resetAllInputsForHiddenSections = (id: number) => {
        const question: {
            questionsToDelte: number[]
            section: number[]
            questionsToDelete: number[]
        } = findObjectByQuestionId(connectedQuestionsIdsToDelete, id) as ConnectedQuestionsIdsToDelete

        //hide additional sections
        question?.section?.forEach((element: number) => {
            sectionRefs.current[element].style.display = 'none'
        })

        //reset select input
        question?.questionsToDelte?.forEach((element: number) => {
            resetSelectAtIndex(element)
        })

        //reset all other inputs in selected sections
        const form = document.getElementById('inDepthAnalysis')
        const sectionSelector = question?.section.map((id: number) => `div[id*="section_${id}"]`).join(', ')
        const findSections = form?.querySelectorAll(sectionSelector)

        let allInputs: HTMLInputElement[] = []
        findSections?.forEach((div) => {
            const inputsInDiv = div.querySelectorAll('input')
            allInputs = allInputs.concat([...inputsInDiv])
        })

        allInputs.forEach((input) => {
            input.value = ''
            input.checked = false
        })

        //clear formik values
        formik.setValues((prevValues: FormSubmissionAnswers) => {
            const newValues = { ...prevValues }

            Object.keys(newValues).forEach((key) => {
                const match = key.match(/\d+/)
                const questionNumber = match ? parseInt(match[0], 10) : null

                if (questionNumber && question?.questionsToDelte.includes(questionNumber)) {
                    delete newValues[key]
                }
            })

            return newValues
        })
    }

    const fieldError = findErrorsById(errors, id)
    const questionName = `question_${id}_${type}_${riskFactor}`
    const isInvalid = fieldError ? true : false

    const renderInputs = () => {
        switch (type) {
            case 'boolean': {
                const additionalQuestionId = options.find((item: { questionVisible: boolean }) => item.questionVisible === true) ?? {
                    questionVisibilityToggleId: 0,
                    id: 0,
                }
                const additionalQuestion = questions.find((item: { id: number }) => item.id === additionalQuestionId?.questionVisibilityToggleId)
                const additionalQuestionSelected = Object?.keys(formik?.values ?? {})?.find(
                    (key) => Number(formik?.values[key]) === additionalQuestionId?.id
                )
                const additionalQuestionName = `question_${additionalQuestion?.id}_${additionalQuestion?.type}_${additionalQuestion?.riskFactor}`
                const showAdditionalQuestion = additionalQuestion && additionalQuestionSelected
                const additionalQuestionSectionId = additionalQuestion?.section as number
                const aditionalFieldError = findErrorsById(errors, additionalQuestionId?.questionVisibilityToggleId)

                return (
                    <>
                        <div
                            className="d-flex align-items-start justify-content-between p-3"
                            style={{ flexBasis: '100%', borderTop: `${questionIndex !== 0 ? '1px solid #eff2f7' : 'none'}` }}
                        >
                            <span style={{ width: '80%' }}>
                                {text}
                                {required && <span style={{ color: '#e82c2c' }}>*</span>}
                            </span>
                            <span style={{ width: '20%' }}>
                                <div className="d-flex justify-content-end align-items-center">
                                    {options.map((option: DepthAnalysisOptions, index: number) => {
                                        const defaultValue = formik?.values[questionName] === option.id ? !!option.id : undefined
                                        return (
                                            <>
                                                <span className="d-flex justify-content-end align-items-center" key={index}>
                                                    <Label className={`p-2 m-0 ${styles.delayedTextColor} ${fieldError ? styles.error : ''}`}>
                                                        {option.label}
                                                    </Label>
                                                    <Input
                                                        className={`p-2 m-0 me-1`}
                                                        name={questionName}
                                                        type="radio"
                                                        value={option.id}
                                                        onChange={(e) => {
                                                            if (option?.sectionVisibilityToggleId && sectionRefs) {
                                                                if (option.sectionVisible) {
                                                                    sectionRefs.current[option?.sectionVisibilityToggleId].style.display = 'block'
                                                                    updateRequiredBySection(option?.sectionVisibilityToggleId, true, false)
                                                                } else {
                                                                    sectionRefs.current[option?.sectionVisibilityToggleId].style.display = 'none'
                                                                    updateRequiredBySection(option?.sectionVisibilityToggleId, false, false)
                                                                }
                                                            }

                                                            if (option.value === 'false') {
                                                                resetAllInputsForHiddenSections(id)
                                                            }

                                                            if (option?.questionVisibilityToggleId && sectionRefs && option.value === 'true') {
                                                                updateRequiredByQuestion(
                                                                    additionalQuestionSectionId,
                                                                    additionalQuestionId?.questionVisibilityToggleId,
                                                                    true
                                                                )
                                                            } else {
                                                                updateRequiredByQuestion(
                                                                    additionalQuestionSectionId,
                                                                    additionalQuestionId?.questionVisibilityToggleId,
                                                                    false
                                                                )
                                                                formik.setFieldValue(additionalQuestionName, undefined)
                                                            }

                                                            formik.handleChange(e)
                                                            formik.validateField(questionName)
                                                        }}
                                                        defaultChecked={defaultValue}
                                                    />
                                                </span>
                                            </>
                                        )
                                    })}
                                </div>

                                <FormFeedback
                                    className={`d-flex justify-content-end align-items-center ${styles.delayedShow} ${fieldError ? styles.show : ''}`}
                                    type="invalid"
                                >
                                    {fieldError?.value}
                                </FormFeedback>
                            </span>
                        </div>
                        {showAdditionalQuestion ? (
                            <div className="d-flex flex-column justify-content-end p-3" style={{ flexGrow: '1' }}>
                                <span style={{ width: '100%', paddingBottom: '10px' }}>
                                    {additionalQuestion?.text}
                                    {required && <span style={{ color: '#e82c2c' }}>*</span>}
                                </span>
                                <Input
                                    invalid={aditionalFieldError ? true : false}
                                    className="p-2 m-0"
                                    name={additionalQuestionName}
                                    type={additionalQuestion?.type}
                                    onChange={formik.handleChange}
                                    defaultValue={formik?.values[additionalQuestionName]}
                                />
                                {aditionalFieldError ? (
                                    <FormFeedback className="d-block" type="invalid">
                                        {aditionalFieldError.value}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        ) : null}
                    </>
                )
            }
            case 'string':
                return hidden ? null : (
                    <div className="align-items-end justify-content-between p-3 pb-4" style={{ flexBasis: '50%' }}>
                        <div className="d-flex flex-column justify-content-end" style={{ flexGrow: '1' }}>
                            <span style={{ width: '100%', paddingBottom: '10px' }}>
                                {text}
                                {required && <span style={{ color: '#e82c2c' }}>*</span>}
                            </span>
                            <span className="d-flex justify-content-center align-items-center">
                                <Input
                                    invalid={isInvalid}
                                    className="p-2 m-0"
                                    id={questionName}
                                    type="text"
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        formik.validateField(questionName)
                                    }}
                                    defaultValue={formik?.values[questionName]}
                                />
                            </span>
                            <FormFeedback className={`d-block ${styles.delayedShow} ${fieldError ? styles.show : ''}`} type="invalid">
                                {fieldError?.value}
                            </FormFeedback>
                        </div>
                    </div>
                )
            case 'text': {
                return (
                    <div className="d-flex align-items-end justify-content-between p-3" style={{ flexBasis: '50%' }}>
                        <div className="d-flex flex-column justify-content-end" style={{ flexGrow: '1' }}>
                            <span style={{ width: '100%', paddingBottom: '10px' }}>
                                {text}
                                {required && <span style={{ color: '#e82c2c' }}>*</span>}
                            </span>
                            <span className="d-flex justify-content-center align-items-center">
                                <Input
                                    invalid={isInvalid}
                                    className="p-2 m-0"
                                    id={questionName}
                                    type="textarea"
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        formik.validateField(questionName)
                                    }}
                                    defaultValue={formik?.values[questionName]}
                                />
                            </span>
                            <FormFeedback className={`d-block ${styles.delayedShow} ${fieldError ? styles.show : ''}`} type="invalid">
                                {fieldError?.value}
                            </FormFeedback>
                        </div>
                    </div>
                )
            }
            case 'float':
                return (
                    <div className="align-items-end justify-content-between p-3" style={{ flexBasis: '50%', display: `${hidden ? 'none' : 'flex'}` }}>
                        <InputPrice
                            text={text}
                            required={required}
                            isInvalid={isInvalid}
                            questionName={questionName}
                            formik={formik}
                            fieldError={fieldError}
                        />
                    </div>
                )
            case 'date': {
                return (
                    <div className="d-flex align-items-end justify-content-between p-3" style={{ flexBasis: '50%' }}>
                        <div className="d-flex flex-column justify-content-end" style={{ flexGrow: '1' }}>
                            <span style={{ width: '100%', paddingBottom: '10px' }}>
                                {text}
                                {required && <span style={{ color: '#e82c2c' }}>*</span>}
                            </span>
                            <Flatpickr
                                className={classNames(`form-control ${isInvalid && 'is-invalid'}`)}
                                id={id}
                                options={{
                                    dateFormat: 'd-m-Y',
                                }}
                                onChange={(e: string) => formik?.setFieldValue(questionName, dayjs(e).format('YYYY-MM-DD'))}
                                value={formik?.values[questionName] ? new Date(formik?.values[questionName]) : ''}
                            />
                            {fieldError ? (
                                <FormFeedback className="d-block" type="invalid">
                                    {fieldError.value}
                                </FormFeedback>
                            ) : null}
                        </div>
                    </div>
                )
            }
            case 'select': {
                const additionalQuestionId = options.find((item: { questionVisible: boolean }) => item.questionVisible === true) ?? {
                    questionVisibilityToggleId: 0,
                    id: 0,
                }
                const additionalQuestion = questions.find((item: { id: number }) => item.id === additionalQuestionId?.questionVisibilityToggleId)
                const additionalQuestionSelected = Object?.keys(formik?.values ?? {})?.find(
                    (key) => Number(formik?.values[key]) === additionalQuestionId?.id
                )
                const additionalQuestionName = `question_${additionalQuestion?.id}_${additionalQuestion?.type}_${additionalQuestion?.riskFactor}`
                const showAdditionalQuestion = additionalQuestion && additionalQuestionSelected
                const additionalQuestionSectionId = additionalQuestion?.section as number

                const aditionalFieldError = findErrorsById(errors, additionalQuestionId?.questionVisibilityToggleId)
                const defaultValue = options.find((option: { id: number }) => option.id === formik?.values[questionName])

                return (
                    <div
                        id={id.toString()}
                        className="d-flex align-items-end justify-content-between p-3"
                        style={{ flexBasis: `${showAdditionalQuestion ? '100%' : '50%'}` }}
                    >
                        <div id={id.toString()} className="d-flex align-items-end justify-content-between" style={{ flexBasis: '100%' }}>
                            <div
                                className={`d-flex flex-column justify-content-end ${showAdditionalQuestion ? 'pe-3' : ''}`}
                                style={{ flexGrow: '1' }}
                            >
                                <span style={{ width: '100%', paddingBottom: '10px' }}>
                                    {text}
                                    {required && <span style={{ color: '#e82c2c' }}>*</span>}
                                </span>
                                <Select
                                    className={isInvalid ? 'is-invalid' : ''}
                                    ref={(el) => {
                                        selectRefs.current[id] = el
                                    }}
                                    onChange={(e) => {
                                        resetAllInputsForHiddenSections(id)

                                        if (e?.questionVisibilityToggleId && sectionRefs) {
                                            updateRequiredByQuestion(
                                                additionalQuestionSectionId,
                                                additionalQuestionId?.questionVisibilityToggleId,
                                                true
                                            )
                                        } else {
                                            updateRequiredByQuestion(
                                                additionalQuestionSectionId,
                                                additionalQuestionId?.questionVisibilityToggleId,
                                                false
                                            )
                                        }

                                        if (e?.sectionVisibilityToggleId && sectionRefs) {
                                            if (e.sectionVisible) {
                                                sectionRefs.current[e?.sectionVisibilityToggleId].style.display = 'block'
                                                updateRequiredBySection(e?.sectionVisibilityToggleId, true, false)
                                            } else {
                                                sectionRefs.current[e?.sectionVisibilityToggleId].style.display = 'none'
                                                updateRequiredBySection(e?.sectionVisibilityToggleId, false, false)
                                            }
                                        }

                                        formik?.setFieldValue(questionName, e?.id)
                                        if (e?.questionVisible === false) formik.setFieldValue(additionalQuestionName, undefined)
                                    }}
                                    defaultValue={defaultValue}
                                    options={options}
                                />
                                {fieldError ? (
                                    <FormFeedback className="d-block" type="invalid">
                                        {fieldError.value}
                                    </FormFeedback>
                                ) : null}
                            </div>

                            {showAdditionalQuestion ? (
                                <div className="d-flex flex-column justify-content-end ps-3" style={{ flexGrow: '1' }}>
                                    <span style={{ width: '100%', paddingBottom: '10px', paddingTop: '20px' }}>
                                        {additionalQuestion?.text}
                                        {required && <span style={{ color: '#e82c2c' }}>*</span>}
                                    </span>
                                    <Input
                                        invalid={aditionalFieldError ? true : false}
                                        className="p-2 m-0"
                                        name={additionalQuestionName}
                                        type={additionalQuestion?.type}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values[additionalQuestionName]}
                                    />
                                    {aditionalFieldError ? (
                                        <FormFeedback className="d-block" type="invalid">
                                            {aditionalFieldError.value}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </div>
                )
            }
        }
    }

    return renderInputs()
})

export default DynamicUI
