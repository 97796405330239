import i18n from '../../../i18n'
import { IS_ERSTE } from '../../constants'
import { projectDescriptionFields, projectDetailSearchFields, projectGeneralFields, projectNoteFields } from './fields'
import { projectDescriptionFieldTypes, projectDetailSearchFieldTypes, projectGeneralFieldTypes, projectNoteFieldTypes } from './fieldTypes'

export const projectGeneralInputs = (isEdit, appSettings) => {
    const inputs = projectGeneralFieldTypes(isEdit, appSettings)

    return {
        sideForm: [
            [
                inputs[projectGeneralFields.ID],
                inputs[projectGeneralFields.PROJECT_STATUS_ID],
                IS_ERSTE && inputs[projectGeneralFields.PROJECT_SIZE_ID],
                inputs[projectGeneralFields.PROJECT_TYPE_ID],
                IS_ERSTE && inputs[projectGeneralFields.PROJECT_OBJECT_TYPE_ID],
                IS_ERSTE && inputs[projectGeneralFields.PROJECT_DEVELOPMENT_STAGE_ID],
                inputs[projectGeneralFields.PROJECT_END_DATE],
                inputs[projectGeneralFields.PROJECT_RECEIPT_DATE],
                inputs[projectGeneralFields.CONTRACT_NO],
                inputs[projectGeneralFields.CONTRACT_DATE],
            ],
            [
                {
                    type: 'fieldArray',
                    name: projectGeneralFields.OWNER_ID,
                    children: [inputs[projectGeneralFields.OWNER_ID]],
                },
            ],
        ],
        mainForm: [
            {
                type: 'array',
                name: 'locationGroup',
                children: [
                    inputs[projectGeneralFields.COUNTRY_ID],
                    inputs[projectGeneralFields.COUNTY_ID],
                    inputs[projectGeneralFields.CITY_ID],
                    inputs[projectGeneralFields.WIDER_AREA_ID],
                    inputs[projectGeneralFields.QUARTER_ID],
                    inputs[projectGeneralFields.ISLAND_ID],
                    inputs[projectGeneralFields.STREET],
                    inputs[projectGeneralFields.STREET_NUMBER],
                    inputs[projectGeneralFields.STREET_SYNC],
                ],
            },
            {
                type: 'array',
                name: 'energyGroup',
                children: [inputs[projectGeneralFields.PROJECT_ENERGY_EFFICIENCY_ID]],
            },
            {
                type: 'array',
                name: 'websiteGroup',
                children: [inputs[projectGeneralFields.PROJECT_WEB_SYNC], IS_ERSTE && inputs[projectGeneralFields.PROJECT_WEB_NAVIGATION_SYNC]],
            },
            IS_ERSTE && {
                type: 'array',
                name: 'commissionGroup',
                children: [inputs[projectGeneralFields.AGENCY_COMMISSION_ID]],
            },
            {
                type: 'array',
                name: 'agentGroup',
                children: [
                    inputs[projectGeneralFields.AGENT_ID],
                    {
                        ...inputs[projectGeneralFields.AGENT_ID],
                        name: `${projectGeneralFields.AGENT_ID}_secondary`,
                        required: false,
                    },
                ],
            },
            IS_ERSTE && {
                type: 'array',
                name: 'ersteAgentGroup',
                children: [inputs[projectGeneralFields.ERSTE_REPRESENTATIVE]],
            },
        ],
    }
}

export const projectDescriptionInputs = (appSettings) => {
    const inputs = projectDescriptionFieldTypes(appSettings)
    return {
        sideForm: [inputs[projectDescriptionFields.PROJECT_TITLE]],
        mainForm: [inputs[projectDescriptionFields.PROJECT_DESCRIPTION], inputs[projectDescriptionFields.PROJECT_OFFER_DESCRIPTION]],
    }
}

export const projectNoteInputs = () => {
    const inputs = projectNoteFieldTypes()

    return {
        mainForm: [
            inputs[projectNoteFields.NOTE_TITLE],
            inputs[projectNoteFields.NOTE_DATE],
            inputs[projectNoteFields.NOTE],
            inputs[projectNoteFields.PROJECT_NOTE_AGENT_ID],
        ],
    }
}

export const projectDetailSearchInputs = () => {
    const inputs = projectDetailSearchFieldTypes()
    return {
        mainForm: [
            {
                type: 'array',
                name: 'idGroup',
                children: [inputs[projectDetailSearchFields.INTERNAL_APP_ID]],
            },
            {
                type: 'array',
                name: 'title',
                children: [inputs[projectDetailSearchFields.PROJECT_TITLE]],
            },
            {
                type: 'array',
                name: 'locationGroup',
                children: [inputs[projectDetailSearchFields.CITY]],
            },
            {
                type: 'array',
                name: 'typeStatusGroup',
                children: inputs[projectDetailSearchFields.PROJECT_TYPE],
            },
            {
                type: 'array',
                name: 'status',
                children: inputs[projectDetailSearchFields.PROJECT_STATUS],
            },
            {
                type: 'array',
                name: 'receiptDate',
                children: {
                    type: 'grouped',
                    name: 'receiptDateGrouped',
                    label: i18n.t('app.common.project.receiptDate'),
                    colProps: {
                        sm: 12,
                        xl: 12,
                    },
                    items: [inputs[projectDetailSearchFields.PROJECT_RECEIPT_DATE_FROM], inputs[projectDetailSearchFields.PROJECT_RECEIPT_DATE_TO]],
                },
            },
            {
                type: 'array',
                name: 'agentGroup',
                children: [inputs[projectDetailSearchFields.AGENT]],
            },
        ],
    }
}
