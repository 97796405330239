import i18n from 'i18next'
import * as Yup from 'yup'
import { AnySchema } from 'yup'
import { DepthAnalysisSections } from '../../../api/inDepthAnalysis/types'

export const createValidationSchema = (data: DepthAnalysisSections[] | undefined) => {
    const errorMsg = i18n.t('validation.message')
    const validationObject: Record<string, AnySchema> = {}

    data?.forEach(({ questions }) => {
        questions?.forEach(({ id, type, required, riskFactor }) => {
            const questionName = `question_${id}_${type}_${riskFactor}`

            const schemaValue =
                type === 'boolean'
                    ? required
                        ? Yup.string().nullable().required(errorMsg)
                        : Yup.string().nullable()
                    : required
                    ? Yup.string().nullable().required(errorMsg)
                    : Yup.string().nullable()

            validationObject[questionName] = schemaValue
        })
    })

    return validationObject
}
