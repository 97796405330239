import { TimePeriod } from '../globalTypes'
import { groupByFieldValues } from '../utils'
import { ContactData, ContactTotal } from './types'

// "Sveukupno" row
export const getContactOveralls = (reports: ContactData[], months: TimePeriod[]) => {
    if (!reports || !months) return { monthOveralls: [], grandOverall: 0 }

    const groupedData = groupByFieldValues(reports, 'contactTotals', 'month')

    const monthOveralls = months.map((obj) => {
        const totalsByMonth = groupedData[obj.month] as ContactTotal[]

        const overall = totalsByMonth?.reduce((acc, curr) => acc + curr.total, 0) || 0

        return {
            id: obj.month,
            monthName: obj.monthName,
            count: overall,
        }
    })

    const grandOverall = monthOveralls.reduce((acc, curr) => acc + curr.count, 0)

    return { monthOveralls, grandOverall }
}
export const getGroupData = (contactTotal: ContactTotal, dataSourceName: string) => {
    const id = contactTotal.month
    const value = contactTotal.total
    const newValueObj = {
        id,
        [dataSourceName]: value,
    }

    return { id, value, newValueObj }
}
