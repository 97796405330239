import { ReportRoute } from '../constants'
import { FilterPropsType } from './types'
export const handleFilterRender = (props: FilterPropsType) => {
    const { reportType, agents, activityTypes, contactStatuses, propertyStatuses, dataSources, t } = props

    const filterConfigs = {
        agent: {
            includeFilter: [
                ReportRoute.agentActivities,
                ReportRoute.agentRevenues,
                ReportRoute.agentTransactions,
                ReportRoute.agentContactStatus,
            ].includes(reportType),
            config: {
                label: t('reports.filter.agent'),
                fieldValue: 'agents',
                options: agents || [],
            },
        },

        activityType: {
            includeFilter: reportType === ReportRoute.agentActivities,
            config: {
                label: t('reports.filter.activityType'),
                fieldValue: 'activityTypes',
                options: activityTypes || [],
            },
        },

        dataSource: {
            includeFilter: [ReportRoute.dataSourceContacts, ReportRoute.dataSourceProperties].includes(reportType),
            config: {
                label: t('reports.filter.dataSource'),
                fieldValue: 'dataSources',
                options: dataSources || [],
            },
        },

        contactStatus: {
            includeFilter: reportType === ReportRoute.agentContactStatus,
            config: {
                label: t('reports.filter.contactStatus'),
                fieldValue: 'contactStatuses',
                options: contactStatuses || [],
            },
        },

        propertyStatus: {
            includeFilter: [ReportRoute.agentRevenues, ReportRoute.agentTransactions].includes(reportType),
            config: {
                label: t('reports.filter.propertyStatus'),
                fieldValue: 'propertyStatuses',
                options: propertyStatuses?.filter((status) => ['3', '4'].includes(status.value)) || [],
            },
        },
    }

    return Object.values(filterConfigs)
        .filter(({ includeFilter }) => includeFilter)
        .map(({ config }) => config)
}
