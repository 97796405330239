import i18n from 'i18next'
import * as Yup from 'yup'
import { CONTACT_STATUS, IS_ERSTE, REAL_ESTATE_TYPES } from '../../constants'
import responseFields from '../../constants/responseFields'
import { companyResponseFields } from '../../response/company'
import { contactResponseFields } from '../../response/contact'
import { companyGeneralFields, companyOtherInfoFields, companyProfileFields } from './fields'

export const companyGeneralValidation = (data) => {
    return {
        initialValues: {
            id: data?.internalAppId ?? '', // internalAppId is used for display, id is sent in prepareModelForSubmit
            [companyGeneralFields.NAME]: data?.[companyResponseFields.CONTACT_COMPANY]?.name ?? '',
            [companyGeneralFields.FULL_NAME]: data?.[companyResponseFields.CONTACT_COMPANY]?.fullName ?? '',
            [companyGeneralFields.CONTACT_LEGAL_FORM_ID]:
                data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.CONTACT_LEGAL_FORM]?.id ?? '',
            [companyGeneralFields.TAX_NUMBER]: data?.taxNumber ?? '',
            [companyGeneralFields.INITIAL_CONTACT_DATE]: data?.initialContactDate ?? '',
            [companyGeneralFields.CONTACT_PRIORITY_ID]: data?.contactPriority?.id ?? '',
            [companyGeneralFields.DATA_SOURCE_ID]: data?.dataSource?.id ?? '',
            ...(IS_ERSTE && { [companyGeneralFields.CONTACT_SOURCE_ID]: data?.contactSource?.id ?? '' }),
            contact_phone_group: data?.contactPhone?.length
                ? data?.contactPhone?.map((item) => ({
                      [companyGeneralFields.PHONE_NUMBER]: item?.phoneNumber ?? '',
                      [companyGeneralFields.CONTACT_PHONE_TYPE_ID]: item?.contactPhoneType?.id ?? '',
                  }))
                : [
                      {
                          [companyGeneralFields.PHONE_NUMBER]: '',
                          [companyGeneralFields.CONTACT_PHONE_TYPE_ID]: '',
                      },
                  ],
            contact_email_group: data?.contactEmail?.length
                ? data?.contactEmail?.map((item) => ({
                      [companyGeneralFields.EMAIL]: item?.email ?? '',
                      [companyGeneralFields.CONTACT_EMAIL_TYPE_ID]: item?.contactEmailType?.id ?? '',
                  }))
                : [
                      {
                          [companyGeneralFields.EMAIL]: '',
                          [companyGeneralFields.CONTACT_EMAIL_TYPE_ID]: '',
                      },
                  ],
            [companyGeneralFields.WEBSITE]: data?.website ?? '',
            [companyGeneralFields.STREET]: data?.street ?? '',
            [companyGeneralFields.STREET_NUMBER]: data?.streetNumber ?? '',
            [companyGeneralFields.CONTACT_COUNTRY_ID]: data?.contactCountry?.id ?? '',
            [companyGeneralFields.CITY]: data?.city ?? '',
            [companyGeneralFields.ZIP_CODE]: data?.zipCode ?? '',
            [companyGeneralFields.CONTACT_STATUS_ID]: data?.contactStatus?.id?.toString() ?? '',
            [companyGeneralFields.AGENT_ID]: data?.agent?.id ?? '',
            ...(IS_ERSTE && { [companyGeneralFields.CONTACT_ABOUT]: data?.contactAbout ?? '' }),
            [companyGeneralFields.CONTACT_QUERY_CATEGORY_ID]: data?.contactQueryCategory?.id?.toString() ?? '',
        },
        validationSchema: Yup.object({
            [companyGeneralFields.NAME]: Yup.string().required(i18n.t('validation.message')),
            [companyGeneralFields.FULL_NAME]: Yup.string(),
            [companyGeneralFields.CONTACT_LEGAL_FORM_ID]: Yup.string(),
            [companyGeneralFields.TAX_NUMBER]: Yup.string(),
            [companyGeneralFields.INITIAL_CONTACT_DATE]: Yup.string(),
            [companyGeneralFields.CONTACT_PRIORITY_ID]: Yup.string(),
            [companyGeneralFields.DATA_SOURCE_ID]: Yup.string(),
            contact_phone_number_group: Yup.array().of(
                Yup.object().shape({
                    [companyGeneralFields.PHONE_NUMBER]: Yup.string(),
                    [companyGeneralFields.CONTACT_PHONE_TYPE_ID]: Yup.string(),
                })
            ),
            contact_email_group: Yup.array().of(
                Yup.object().shape({
                    [companyGeneralFields.EMAIL]: Yup.string(),
                    [companyGeneralFields.CONTACT_EMAIL_TYPE_ID]: Yup.string(),
                })
            ),
            [companyGeneralFields.STREET]: Yup.string(),
            [companyGeneralFields.STREET_NUMBER]: Yup.string(),
            [companyGeneralFields.CONTACT_COUNTRY_ID]: Yup.string(),
            [companyGeneralFields.CITY]: Yup.string(),
            [companyGeneralFields.ZIP_CODE]: Yup.string(),
            [companyGeneralFields.AGENT_ID]: Yup.string().required(i18n.t('validation.message')),
        }),
        prepareModelForSubmit: (values) => {
            return {
                ...values,
                [companyGeneralFields.ID]: data?.id ?? '',
                contact_email_group: values.contact_email_group?.some((x) => x.email || x.contact_email_type_id) ? values.contact_email_group : [],
                contact_phone_group: values.contact_phone_group?.some(
                    (x) => x[companyGeneralFields.PHONE_NUMBER] || x[companyGeneralFields.CONTACT_PHONE_TYPE_ID]
                )
                    ? values.contact_phone_group
                    : [],
            }
        },
    }
}

export const companyOtherInfoValidation = (data) => {
    return {
        initialValues: {
            [companyOtherInfoFields.CONTACT_CORE_BUSINESS_ID]:
                data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.CONTACT_CORE_BUSINESS]?.id ?? '',
            [companyOtherInfoFields.CONTACT_COMPANY_SIZE_ID]: data?.[companyResponseFields.CONTACT_COMPANY]?.contactCompanySize?.id ?? '',
            [companyOtherInfoFields.EMPLOYEES_NO]: data?.[companyResponseFields.CONTACT_COMPANY]?.employeesNo ?? '',
            [companyOtherInfoFields.BANK_ACCOUNT_NO]: data?.contactCompany?.bankAccountNo ?? '',
            [companyOtherInfoFields.SHARE_CAPITAL]: data?.[companyResponseFields.CONTACT_COMPANY]?.shareCapital ?? '',
            [companyOtherInfoFields.REGISTRY_COURT]: data?.[companyResponseFields.CONTACT_COMPANY]?.registryCourt ?? '',
            [companyOtherInfoFields.NOTES]: data?.notes ?? '',
        },
        validationSchema: Yup.object({
            [companyOtherInfoFields.CONTACT_CORE_BUSINESS_ID]: Yup.number(),
            [companyOtherInfoFields.CONTACT_COMPANY_SIZE_ID]: Yup.number(),
            [companyOtherInfoFields.EMPLOYEES_NO]: Yup.number(),
            [companyOtherInfoFields.BANK_ACCOUNT_NO]: Yup.string(),
            [companyOtherInfoFields.REGISTRY_COURT]: Yup.string(),
            [companyOtherInfoFields.SHARE_CAPITAL]: Yup.string(),
            [companyOtherInfoFields.NOTES]: Yup.string(),
        }),
    }
}

export const companyProfileValidation = (data, propertyTypeValue) => {
    return {
        initialValues: {
            id: data?.id ?? '',
            [companyProfileFields.CONTACT_PROFILE_SERVICE_ID]: data?.[contactResponseFields.CONTACT_PROFILE_SERVICE]?.id ?? '',
            [companyProfileFields.PROPERTY_TYPE_ID]: data?.propertyType?.id ?? '',
            [companyProfileFields.PROPERTY_SUB_TYPE_ID]: data?.propertySubType?.map((item) => item.id?.toString()) ?? [],
            [companyProfileFields.CONTACT_PROFILE_STATUS_ID]: data?.[contactResponseFields.CONTACT_PROFILE_STATUS]?.id?.toString() ?? '',
            [companyProfileFields.CONTACT_FINANCING_ID]: data?.[contactResponseFields.CONTACT_FINANCING]?.map((item) => item.id?.toString()) ?? [],
            [companyProfileFields.CONTACT_PROFILE_DURATION_ID]: data?.[contactResponseFields.CONTACT_PROFILE_DURATION]?.id?.toString() ?? '',
            [companyProfileFields.COMPANY_DEFINITION_ID]: data?.[contactResponseFields.COMPANY_DEFINITION]?.id?.toString() ?? '',
            [companyProfileFields.COUNTRY_ID]: data?.[contactResponseFields.COUNTRY]?.id?.toString() ?? '',
            [companyProfileFields.COUNTY_ID]: data?.[contactResponseFields.COUNTY]?.id?.toString() ?? '',
            [companyProfileFields.WIDER_AREA_ID]: data?.[contactResponseFields.WIDER_AREA]?.map((item) => item.id?.toString()) ?? [],
            [companyProfileFields.CITY_ID]: data?.[contactResponseFields.CITY]?.map((item) => item.id?.toString()) ?? [],
            [companyProfileFields.QUARTER_ID]: data?.[contactResponseFields.QUARTER]?.map((item) => item.id?.toString()) ?? [],
            [companyProfileFields.ISLAND_ID]: data?.[contactResponseFields.ISLAND]?.map((item) => item.id?.toString()) ?? [],
            [companyProfileFields.PRICE_FROM]: data?.[contactResponseFields.PRICE_FROM] ?? '',
            [companyProfileFields.PRICE_TO]: data?.[contactResponseFields.PRICE_TO] ?? '',
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                ? {
                      [companyProfileFields.ROOMS_FROM]: data?.[contactResponseFields.ROOMS_FROM] ?? '',
                      [companyProfileFields.ROOMS_TO]: data?.[contactResponseFields.ROOMS_TO] ?? '',
                  }
                : {}),
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString()
                ? {
                      [companyProfileFields.BEDROOMS_FROM]: data?.[contactResponseFields.BEDROOMS_FROM] ?? '',
                      [companyProfileFields.BEDROOMS_TO]: data?.[contactResponseFields.BEDROOMS_TO] ?? '',
                  }
                : {}),
            [companyProfileFields.SPECIAL_REQUEST]: data?.[contactResponseFields.SPECIAL_REQUEST] ?? '',
            [companyProfileFields.AREA_FROM]: data?.[contactResponseFields.AREA_FROM] ?? '',
            [companyProfileFields.AREA_TO]: data?.[contactResponseFields.AREA_TO] ?? '',
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                ? {
                      [companyProfileFields.PROPERTY_FLOOR_POSITION_ID]:
                          data?.[contactResponseFields.PROPERTY_FLOOR_POSITION]?.map((item) => item.id?.toString()) ?? [],
                      [companyProfileFields.FLOOR_NO_FROM]: data?.[contactResponseFields.FLOOR_FROM] ?? '',
                      [companyProfileFields.FLOOR_NO_TO]: data?.[contactResponseFields.FLOOR_TO] ?? '',
                  }
                : {}),
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString()
                ? {
                      [companyProfileFields.EXCLUDE_BASEMENT]: data?.[contactResponseFields.EXCLUDE_BASEMENT] ?? false,
                      [companyProfileFields.EXCLUDE_LAST_FLOOR]: data?.[contactResponseFields.EXCLUDE_LAST_FLOOR] ?? false,
                  }
                : {}),
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                ? {
                      [companyProfileFields.PROPERTY_SPACE_ID]: data?.[companyResponseFields.PROPERTY_SPACE]?.map((x) => x.id.toString()) ?? [],
                  }
                : {}),
            ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString()
                ? {
                      [companyProfileFields.PROPERTY_DESCRIPTIONS_ID]:
                          data?.[contactResponseFields.PROPERTY_DESCRIPTIONS]?.map((item) => item.id?.toString()) ?? [],
                      [companyProfileFields.PROPERTY_UTILITY_ID]:
                          data?.[contactResponseFields.PROPERTY_UTILITY]?.map((item) => item.id?.toString()) ?? [],
                  }
                : {}),
            ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString() &&
            propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.land.toString()
                ? {
                      [companyProfileFields.PROPERTY_EQUIPMENT_ID]:
                          data?.[contactResponseFields.PROPERTY_EQUIPMENT]?.map((item) => item.id?.toString()) ?? [],
                  }
                : {}),
            ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString() &&
            propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.land.toString()
                ? {
                      [companyProfileFields.PROPERTY_HEATING_ID]:
                          data?.[contactResponseFields.PROPERTY_HEATING]?.map((item) => item.id?.toString()) ?? [],
                  }
                : {}),
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.land.toString()
                ? {
                      [companyProfileFields.MAX_CONTSRUCTION_AREA]: data?.maxConstructionArea ?? '',
                  }
                : {}),
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString()
                ? {
                      [companyProfileFields.PROPERTY_FLOOR_ID]: data?.propertyFloor?.id ?? '',
                  }
                : {}),
        },
        validationSchema: Yup.object({
            [companyProfileFields.PROPERTY_TYPE_ID]: Yup.string().required(i18n.t('validation.message')),
            [companyProfileFields.CONTACT_PROFILE_SERVICE_ID]: Yup.string().required(i18n.t('validation.message')),
            [companyProfileFields.CONTACT_PROFILE_STATUS_ID]: Yup.number(),
            [companyProfileFields.CONTACT_FINANCING_ID]: Yup.array().of(Yup.string()),
            [companyProfileFields.CONTACT_PROFILE_DURATION_ID]: Yup.number(),
            [companyProfileFields.CONTACT_DEFINITION_ID]: Yup.number(),
            [companyProfileFields.COUNTRY_ID]: Yup.number(),
        }),
        prepareModelForSubmit: (values) => {
            return {
                ...values,
                [companyProfileFields.PRICE_FROM]: values?.[companyProfileFields.PRICE_FROM]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [companyProfileFields.PRICE_TO]: values?.[companyProfileFields.PRICE_TO]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [companyProfileFields.PRICE_M2_FROM]: values?.[companyProfileFields.PRICE_M2_FROM]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [companyProfileFields.PRICE_M2_TO]: values?.[companyProfileFields.PRICE_M2_TO]?.toString().replace(/\./g, '').replace(/,/g, '.'),
            }
        },
    }
}

export const companyDetailSearchValidation = (data) => {
    return {
        initialValues: {
            [responseFields.NAME]: data?.[responseFields.NAME] ?? '',
            [responseFields.CONTACT_PHONE]: data?.[responseFields.CONTACT_PHONE] ?? '',
            [responseFields.CONTACT_EMAIL]: data?.[responseFields.CONTACT_EMAIL] ?? '',
            [responseFields.AGENT]: data?.[responseFields.AGENT] ?? '',
            [responseFields.INITIAL_CONTACT_DATE_FROM]: data?.[responseFields.INITIAL_CONTACT_DATE_FROM] ?? '',
            [responseFields.INITIAL_CONTACT_DATE_TO]: data?.[responseFields.INITIAL_CONTACT_DATE_TO] ?? '',
            [responseFields.LAST_ACTIVITY_DATE_FROM]: data?.[responseFields.LAST_ACTIVITY_DATE_FROM] ?? '',
            [responseFields.LAST_ACTIVITY_DATE_TO]: data?.[responseFields.LAST_ACTIVITY_DATE_TO] ?? '',
            [responseFields.CONTACT_COUNTRY]: data?.[responseFields.CONTACT_COUNTRY] ?? '',
            [responseFields.CITY]: data?.[responseFields.CITY] ?? [],
            [responseFields.CONTACT_PROFILE_SERVICE]: data?.[responseFields.CONTACT_PROFILE_SERVICE] ?? '',
            [responseFields.CONTACT_PROFILE_STATUS]: data?.[responseFields.CONTACT_PROFILE_STATUS] ?? '',
            [responseFields.CONTACT_QUERY_CATEGORY]: data?.[responseFields.CONTACT_QUERY_CATEGORY] ?? '',
            [responseFields.DATA_SOURCE]: data?.[responseFields.DATA_SOURCE] ?? '',
            [responseFields.CONTACT_PRIORITY]: data?.[responseFields.CONTACT_PRIORITY] ?? '',
            [responseFields.CONTACT_STATUS]: IS_ERSTE
                ? [
                      CONTACT_STATUS.IN_ENTRY.toString(),
                      CONTACT_STATUS.HOT_LEAD.toString(),
                      CONTACT_STATUS.ON_HOLD.toString(),
                      CONTACT_STATUS.NO_OFFER.toString(),
                      CONTACT_STATUS.NO_OFFER_OUT_OF_ZG.toString(),
                      CONTACT_STATUS.ACCEPTANCE_IN_OFFER.toString(),
                  ]
                : data?.[responseFields.CONTACT_STATUS]?.map((item) => item.id?.toString()),
        },
        prepareModelForSubmit: (values) => {
            const filledValues = Object.fromEntries(Object.entries(values).filter(([_key, value]) => value !== null && value !== ''))
            return {
                ...filledValues,
            }
        },
        formGroup: 'contact',
    }
}

export const companyProfileDetailSearchValidation = (data) => {
    return {
        initialValues: {
            [responseFields.PROPERTY_TYPE]: data?.[responseFields.PROPERTY_TYPE] ?? [],
            [responseFields.PROPERTY_SUB_TYPE]: data?.[responseFields.PROPERTY_SUB_TYPE] ?? [],
            [responseFields.PRICE_FROM]: data?.[responseFields.PRICE_FROM] ?? '',
            [responseFields.PRICE_TO]: data?.[responseFields.PRICE_TO] ?? '',
            [responseFields.ROOMS_FROM]: data?.[responseFields.ROOMS_FROM] ?? '',
            [responseFields.ROOMS_TO]: data?.[responseFields.ROOMS_TO] ?? '',
            [responseFields.BEDROOMS_FROM]: data?.[responseFields.BEDROOMS_FROM] ?? '',
            [responseFields.BEDROOMS_TO]: data?.[responseFields.BEDROOMS_TO] ?? '',
            [responseFields.AREA_FROM]: data?.[responseFields.AREA_FROM] ?? '',
            [responseFields.AREA_TO]: data?.[responseFields.AREA_TO] ?? '',
            [responseFields.FLOOR_FROM]: data?.[responseFields.FLOOR_FROM] ?? '',
            [responseFields.FLOOR_TO]: data?.[responseFields.FLOOR_TO] ?? '',
            [responseFields.CONTACT_PROFILE_DURATION]: data?.[responseFields.CONTACT_PROFILE_DURATION] ?? '',
            [responseFields.COUNTRY]: data?.[responseFields.COUNTRY] ?? '',
            [responseFields.COUNTY]: data?.[responseFields.COUNTY] ?? [],
            [responseFields.CITY]: data?.[responseFields.CITY] ?? [],
            [responseFields.WIDER_AREA]: data?.[responseFields.WIDER_AREA] ?? [],
            [responseFields.QUARTER]: data?.[responseFields.QUARTER] ?? [],
            [responseFields.PROPERTY_FLOOR]: data?.[responseFields.PROPERTY_FLOOR] ?? [],
        },
        prepareModelForSubmit: (values) => {
            const filledValues = Object.fromEntries(Object.entries(values).filter(([_key, value]) => value !== null && value !== ''))
            return {
                ...filledValues,
                ...(filledValues[responseFields.PRICE_FROM]
                    ? {
                          [responseFields.PRICE_FROM]: filledValues[responseFields.PRICE_FROM]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                      }
                    : {}),
                ...(filledValues[responseFields.PRICE_TO]
                    ? {
                          [responseFields.PRICE_TO]: filledValues[responseFields.PRICE_TO]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                      }
                    : {}),
            }
        },
        formGroup: 'contactProfile',
    }
}
