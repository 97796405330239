import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainer from '../../../Components/Table/TableContainer'
import { RowData } from '../globalTypes'
import { AgentTransactionReportProps } from './types'
import { getTransactionOveralls } from './utils'

const AgentTransactionReportTable = (props: AgentTransactionReportProps) => {
    const { reportsData, reportsFetching } = props
    const { t } = useTranslation()

    const reports = useMemo(() => reportsData?.data || [], [reportsData]) 
    const months = useMemo(() => reportsData?.timePeriod || [], [reportsData])

    const columns = useMemo(() => {
        const agentColumn = {
            Header: t('app.common.agent'),
            id: 'agent',
            accessor: (row: RowData) => `${row.agent?.firstName} ${row.agent?.lastName}`,
            filterable: false,
        }

        const monthColumns = months?.map(({ month, monthName, year }) => ({
            Header: monthName,
            id: `${month}/${year}`,
            accessor: (row: RowData) => {
                const transaction = row.transactionTotals?.find((transaction) => transaction?.month === month)
                if (!transaction) return null

                return (
                    <div className="d-flex gap-2 justify-content-center">
                        {transaction.soldCount != null && <span>{transaction?.soldCount}</span>}
                        {transaction.rentedCount != null && <span className="text-danger">{transaction?.rentedCount}</span>}
                    </div>
                )
            },
            filterable: false,
        }))

        const totalColumn = {
            Header: t('app.common.total'),
            id: 'total_by_agent',
            accessor: (row: RowData) => <div className='fw-bolder text-center'>{row.agentGrandTotal}</div>,
            filterable: false,
        }

        return [agentColumn, ...monthColumns, totalColumn]
    }, [months, t])

    const tableFooter = useMemo(() => {
        const { monthOveralls, grandOverall } = getTransactionOveralls(reports, months)

        return (
            <tr>
                <td>{t('app.common.overall')}</td>
                {monthOveralls.map((overall, i) => (
                    <td key={i} className="text-center">
                        {overall.count}
                    </td>
                ))}
                <td className="fw-bolder text-center">{grandOverall}</td>
            </tr>
        )
    }, [reports, months, t])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            tableFooter={tableFooter}
            tableHash={'reports_agent-transactions'}
            hidePagination={true}
            {...({} as any)} // temporary fix
        />
    )
}

export default AgentTransactionReportTable
