import { useQueries, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import { fetchQuickSearchData } from '../../../api'
import { DETAIL_SEARCH_TYPE } from '../../../common/constants'
import { selectPropertyServicesFormOptions } from '../../../store/propertyServices/selectors'
import { selectPropertyValuesConditionFormOptions } from '../../../store/propertyValues'
import {
    clearDetailSearchProjectsCriteria,
    clearDetailSearchPropertiesCriteria,
    fetchQuickSearch,
    fetchQuickSearchClear,
} from '../../../store/search'
import { selectQuickSearchCriteria } from '../../../store/search/selectors'
import { selectDetailSearchPropertyValuesFloorFormOptions } from '../../../store/searchValues'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../../store/settings'
import SearchResult from './SearchResult/SearchResult'
import { cleanObject, getRange, getSearchValue, removeSpecialChar } from './helpers'

const SearchOption = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const { propertyCondition, propetyServiceOptions, propertyFloor, searchCriteria, areaUnit, currency } = useSelector((state) => {
        return {
            propetyServiceOptions: selectPropertyServicesFormOptions(state),
            propertyFloor: selectDetailSearchPropertyValuesFloorFormOptions(state),
            propertyCondition: selectPropertyValuesConditionFormOptions(state),
            searchCriteria: selectQuickSearchCriteria(state),
            areaUnit: selectApplicationAreaUnit(state),
            currency: selectApplicationCurrency(state),
        }
    })
    const [value, setValue] = useState('')
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

    let searchObject = {
        pageType: '',
        searchProps: {
            search: null,
            price: null,
            priceFrom: null,
            priceTo: null,
            areaFrom: null,
            areaTo: null,
            roomsNoFrom: null,
            roomsNoTo: null,
            bedroomsNoFrom: null,
            bedroomsNoTo: null,
            floorNoFrom: null,
            floorNoTo: null,
            propertyCondition: [],
            propertyFloor: [],
            propertyService: [],
        },
    }

    const findSearchValueOrRange = (el, searchArrayClone, linkArray, searchItem, filterBy, propName) => {
        if (!searchItem) return

        const filterByChar = removeSpecialChar(filterBy)
        const searchValue = getSearchValue(searchItem, filterByChar)
        const range = getRange(searchItem, filterByChar)

        if (searchValue) {
            linkArray.push(`&criteria[${propName}]=${searchValue.trim()}`)
            searchObject.searchProps[`${propName}`] = `${searchValue.trim()}`
            searchArrayClone.push(el)
        }

        if (range && !searchValue) {
            if (searchItem.includes(t('quickSearch.rangeFrom'))) {
                linkArray.push(`&criteria[${propName}From]=${range[0]}`)
                searchObject.searchProps[`${propName}From`] = `${range[0]}`
            } else {
                linkArray.push(`&criteria[${propName}To]=${range[0]}`)
                searchObject.searchProps[`${propName}To`] = `${range[0]}`
            }
            if (range[1]) {
                linkArray.push(`&criteria[${propName}To]=${range[1]}`)
                searchObject.searchProps[`${propName}To`] = `${range[1]}`
            }
            searchArrayClone.push(el)
        }

        return linkArray
    }

    const enableSearch = (searchQuery, searchArray) => {
        return queryClient.getQueryCache().find({ queryKey: [searchQuery, ...searchArray] }) === undefined && value !== ''
    }

    const findByResult = (searchValues) => {
        const linkArray = []
        const searchArrayClone = []

        const searchArray = searchValues.split(',').map((item) => item.trim())

        searchArray.forEach((el) => {
            const searchItem = el ? removeSpecialChar(el) : null

            const resultPropetyService = propetyServiceOptions.find((item) => removeSpecialChar(item.label)?.includes(searchItem))
            if (resultPropetyService) {
                linkArray.push(`&criteria[propertyService][0]=${resultPropetyService?.value}`)
                searchObject.searchProps.propertyService = [`${resultPropetyService?.value}`]
                searchArrayClone.push(el)
            }

            const resultPropertyFloor = propertyFloor.find((item) => removeSpecialChar(item.label)?.includes(searchItem))
            if (resultPropertyFloor) {
                linkArray.push(`&criteria[propertyFloor][0]=${resultPropertyFloor?.value}`)
                searchObject.searchProps.propertyFloor = [`${resultPropertyFloor?.value}`]
                searchArrayClone.push(el)
            }

            const resultPropertyCondition = propertyCondition.find((item) =>
                removeSpecialChar(item.label?.replace('-', ' ')).includes(searchItem?.replace('-', ' '))
            )
            if (resultPropertyCondition) {
                linkArray.push(`&criteria[propertyCondition][0]=${resultPropertyCondition?.value}`)
                searchObject.searchProps.propertyCondition = [`${resultPropertyCondition?.value}`]
                searchArrayClone.push(el)
            }

            findSearchValueOrRange(el, searchArrayClone, linkArray, searchItem, currency, 'price')
            findSearchValueOrRange(el, searchArrayClone, linkArray, searchItem, areaUnit, 'area')
            findSearchValueOrRange(el, searchArrayClone, linkArray, searchItem, t('app.common.property.numberOfRooms').toLowerCase(), 'roomsNo')
            findSearchValueOrRange(el, searchArrayClone, linkArray, searchItem, t('quickSearch.bedroomsNo').toLowerCase(), 'bedroomsNo')
            findSearchValueOrRange(el, searchArrayClone, linkArray, searchItem, t('app.common.floorNumber').toLowerCase(), 'floorNo')
        })

        const linkProperty = encodeURI(linkArray.toString()?.replace(',', ''))

        const searchSet = new Set(searchArray)
        const searchCloneSet = new Set(searchArrayClone)
        const difference = new Set([...searchSet].filter((x) => !searchCloneSet.has(x)))
        const baseSearch = difference.size > 0 ? encodeURI(`&criteria[search]=${[...difference].join(',')}`) : ''

        searchObject.searchProps.search = difference.size > 0 ? [...difference].join(',') : null

        return [
            {
                queryKey: ['property', ...searchArray],
                queryFn: () => (baseSearch || linkProperty) && fetchQuickSearchData(`v1/property?limit=5${baseSearch}${linkProperty}`),
                enabled: enableSearch('property', searchArray),
            },
            {
                queryKey: ['project', ...searchArray],
                queryFn: () => baseSearch && fetchQuickSearchData(`v1/project?limit=5${baseSearch}`),
                enabled: enableSearch('project', searchArray),
            },
            {
                queryKey: ['contactCompany', ...searchArray],
                queryFn: () => baseSearch && fetchQuickSearchData(`v1/contact?limit=5${baseSearch}&criteria[contactType]=company`),
                enabled: enableSearch('contactCompany', searchArray),
            },
            {
                queryKey: ['contactPerson', ...searchArray],
                queryFn: () => baseSearch && fetchQuickSearchData(`v1/contact?limit=5${baseSearch}&criteria[contactType]=person`),
                enabled: enableSearch('contactPerson', searchArray),
            },
        ]
    }

    const [property, project, contactCompany, contactPerson] = useQueries({
        queries: [...findByResult(debouncedSearchTerm)],
    })

    const handleShowAll = (pageType) => {
        searchObject.pageType = pageType
        cleanObject(searchObject.searchProps)

        const isProperty = pageType === 'property'
        dispatch(isProperty ? clearDetailSearchPropertiesCriteria() : clearDetailSearchProjectsCriteria())
        dispatch(fetchQuickSearch(searchObject))
    }

    useEffect(() => {
        const delay = 1000
        const timeoutId = setTimeout(() => setDebouncedSearchTerm(value), delay)
        return () => clearTimeout(timeoutId)
    }, [value])

    useEffect(() => {
        if (!searchCriteria) {
            setValue('')
        }
    }, [searchCriteria])

    useEffect(() => {
        var searchOptions = document.getElementById('search-close-options')
        var dropdown = document.getElementById('search-dropdown')
        var searchInput = document.getElementById('search-options')
        const navLinksArray = [...document.getElementsByClassName('nav-link')]
        const detailSearchButton = [...document.getElementsByClassName('detail-search-button')]

        navLinksArray.map((item) =>
            item.addEventListener('click', () => {
                dispatch(fetchQuickSearchClear())
                setValue('')
            })
        )

        detailSearchButton[0]?.addEventListener('click', () => {
            dispatch(fetchQuickSearchClear())
            setValue('')
        })

        searchInput.addEventListener('focus', function () {
            var inputLength = searchInput.value.length
            if (inputLength > 0) {
                dropdown.classList.add('show')
            } else {
                dropdown.classList.remove('show')
            }
        })

        searchInput.addEventListener('keyup', function () {
            var inputLength = searchInput.value.length
            if (inputLength > 0) {
                dropdown.classList.add('show')
            } else {
                dropdown.classList.remove('show')
            }
        })

        searchOptions.addEventListener('click', function () {
            searchInput.value = ''
            dropdown.classList.remove('show')
            dispatch(fetchQuickSearchClear())
            setValue('')
        })

        document.body.addEventListener('click', function (e) {
            if (e.target.getAttribute('id') !== 'search-options') {
                dropdown.classList.remove('show')
            }
        })
    }, [dispatch])

    return (
        <div className="app-search d-none d-md-block">
            <div className="position-relative">
                <Input
                    type="text"
                    className="form-control"
                    placeholder={`${t('quickSearch.placeholder')}...`}
                    id="search-options"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                />
                <span className="mdi mdi-magnify search-widget-icon"></span>
                <span
                    className={`mdi mdi-close-circle search-widget-icon search-widget-icon-close ${!value ? 'd-none' : ''}`}
                    id="search-close-options"
                ></span>
            </div>
            <div className="dropdown-menu dmn-search-dropdown-menu" id="search-dropdown">
                <SimpleBar style={{ height: 'auto', maxHeight: '320px' }}>
                    <SearchResult
                        linkName={'properties'}
                        searchResult={property}
                        handleShowAll={() => handleShowAll(DETAIL_SEARCH_TYPE.PROPERTY)}
                        currency={currency}
                        areaUnit={areaUnit}
                    />
                    <SearchResult linkName={'projects'} searchResult={project} handleShowAll={() => handleShowAll(DETAIL_SEARCH_TYPE.PROJECT)} />
                    <SearchResult
                        linkName={'contacts'}
                        searchResult={contactPerson}
                        handleShowAll={() => handleShowAll(DETAIL_SEARCH_TYPE.CONTACT)}
                    />
                    <SearchResult
                        linkName={'companies'}
                        searchResult={contactCompany}
                        handleShowAll={() => handleShowAll(DETAIL_SEARCH_TYPE.COMPANY)}
                    />
                    {contactCompany?.data?.count === 0 &&
                        contactPerson?.data?.count === 0 &&
                        project?.data?.count === 0 &&
                        property?.data?.count === 0 && (
                            <div className="notification-list">
                                <div className="d-flex">
                                    <div className="flex-1 text-center">{t('app.common.noResult')}</div>
                                </div>
                            </div>
                        )}
                </SimpleBar>
            </div>
        </div>
    )
}

export default SearchOption
