import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUsers, selectUsers, selectUsersFetching } from '../../../../store/administration'
import AdminLoader from '../../Components/AdminLoader/AdminLoader'
import ModalWrapper from '../../../../Components/Modals/ModalWrapper/ModalWrapper'
import style from './UsersList.module.css'

import UserDeleteModal from '../../Components/UserDeleteModal/UserDeleteModal'
import CardTableView from '../../Components/CardTableView/CardTableView'

const UsersList = () => {
    const dispatch = useDispatch()

    const { users, fetchingUsers } = useSelector((state) => ({
        users: selectUsers(state),
        fetchingUsers: selectUsersFetching(state),
    }))

    const [filteredUsers, setFilteredUsers] = useState([])
    const [modalBaseOpen, setModalBaseOpen] = useState(false)

    useEffect(() => {
        dispatch(fetchUsers())
    }, [dispatch])

    const handleModalClose = () => {
        setModalBaseOpen(!modalBaseOpen)
    }

    return fetchingUsers ? (
        <AdminLoader />
    ) : (
        <>
            <ModalWrapper className={style.deleteUser} open={modalBaseOpen} onClose={handleModalClose}>
                <UserDeleteModal handleModalClose={handleModalClose} />
            </ModalWrapper>
            <CardTableView
                data={users.reverse()}
                fetching={fetchingUsers}
                filteredData={filteredUsers}
                editButtonLink={'/admin/user'}
                setFilteredData={setFilteredUsers}
                searchFields={{ firstName: 'firstName', lastName: 'lastName' }}
                isUserView={true}
            />
        </>
    )
}

export default UsersList
