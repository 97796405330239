import { UpdateFileParams } from '../globalTypes'
import { getQueryData, invalidateQuery } from '../helpers'
import { Image, UpdateImageParams } from './types'

export const handleImagesPayload = (module: string, parent: string) => {
    const updateCollection = (images: Image[]): UpdateImageParams[] =>
        images.map((image, index) => {
            const translations = handleTranslations(image)
            const newPriority = index + 1

            return {
                id: image.id,
                collection: image.collection,
                module: image.module,
                parent: image.parent.toString(),
                file: image.file,
                priority: newPriority,
                translations,
                isImageEdit: !!image.file,
                isPriorityUpdate: image.priority !== newPriority,
                isTranslationsUpdate: translations.length > 0,
            }
        })

    const photos = getQueryData(['photo', module, parent]) as Image[]
    const groundPlans = getQueryData(['ground_plan', module, parent]) as Image[]

    const updatedPhotos = updateCollection(photos)
    const updatedGroundPlans = updateCollection(groundPlans)

    return [...updatedPhotos, ...updatedGroundPlans]
}

export const handleUpdatePayload = (image: UpdateImageParams) => {
    let payload = { id: image.id }
    const objects = [{ file: image.file }, { priority: image.priority }, { translations: image.translations }]
    const conditions = [image.isImageEdit, image.isPriorityUpdate, image.isTranslationsUpdate]

    conditions.forEach((condition, index) => {
        if (condition) {
            payload = {
                ...payload,
                ...objects[index],
            }
        }
    })

    const isValidPayload = Object.keys(payload).length > 1
    return isValidPayload ? (payload as UpdateFileParams) : null
}

export const handleShouldImagesUpdate = (images: UpdateImageParams[]) => {
    return images.some((image) => {
        const { isImageEdit, isPriorityUpdate, isTranslationsUpdate } = image
        return isImageEdit || isPriorityUpdate || isTranslationsUpdate
    })
}

export const handleImagesUpdateInvalidation = (params: UpdateImageParams[]) => {
    const collectionsToInvalidate: UpdateImageParams[] = []

    params.forEach((param) => {
        const { isImageEdit, isTranslationsUpdate, isPriorityUpdate, collection } = param
        const isUpdated = isImageEdit || isTranslationsUpdate || isPriorityUpdate
        const isCollectionAdded = collectionsToInvalidate.some((c) => c.collection === collection)

        if (isUpdated && !isCollectionAdded) {
            collectionsToInvalidate.push(param)
        }
    })

    collectionsToInvalidate.forEach((param) => {
        const { collection, module, parent } = param
        invalidateQuery([collection, module, parent])
    })
}

export const handleImagesOrder = (fromIndex: number, toIndex: number, images: Image[]) => {
    const updatedImages = [...images]
    const [removedItem] = updatedImages.splice(fromIndex, 1)
    updatedImages.splice(toIndex, 0, removedItem)

    return updatedImages
}

const handleTranslations = (image: Image) => {
    return Object.entries(image.translations)
        .filter(([_, value]) => value.isTranslationsUpdate)
        .map(([key, value]) => ({ locale: key, fileDescription: value.fileDescription }))
}