import { getIn } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Button, Card, CardBody, Col, Input, Row, TabContent, TabPane } from 'reactstrap'
import { realEstateDescriptionFields } from '../../common/forms/generalRealEstate/fields'
import { selectAvailableTranslationLanguages } from '../../store/applicationSettings'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import FormTabNavigation from '../Common/FormTabNavigation'
import RecursiveForm from '../RecursiveForm'
import RichTextEditor from '../RichTextEditor/RichTextEditor'
import PropertyFormDescriptionContext from './PropertyFormDescriptionContext'

// TODO: ProjectFormDescriptionTab & PropertyFormDescriptionTab could be one reusable component
const PropertyFormDescriptionTab = ({ children, ...props }) => {
    // TODO: uncomment after sagamiddleware refactor, remove useSelector
    // const { availableTranslationLanguages } = useApplicationSettings()

    const currentLang = localStorage.getItem('i18nextLng')

    const availableTranslationLanguages = useSelector((state) => selectAvailableTranslationLanguages(state))

    const [activeTitleLanguage, setActiveTitleLanguage] = useState(currentLang)
    const [activeDescriptionLanguage, setActiveDescriptionLanguage] = useState(currentLang)

    const languageTabs = availableTranslationLanguages.map((lang) => ({
        id: lang.id,
        name: lang.abbreviation,
        label: lang.abbreviation,
        disabled: false,
    }))

    const languageSelectOptions = availableTranslationLanguages.map((lang) => ({
        value: lang.abbreviation,
        label: lang.abbreviation,
    }))

    const languageTranslateValues = availableTranslationLanguages.reduce((acc, curr) => {
        acc[curr.abbreviation] = ''
        return acc
    }, {})

    return (
        <PropertyFormDescriptionContext.Provider
            value={{
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
                formik: props.formik,
                inputs: props.inputs,
                subform: props.subform,
                activeTitleLanguage,
                setActiveTitleLanguage,
                activeDescriptionLanguage,
                setActiveDescriptionLanguage,
                languageTabs,
                languageSelectOptions,
                languageTranslateValues,
                realEstateType: props.realEstateType,
                realEstateService: props.realEstateService,
            }}
        >
            <Row>{children}</Row>
        </PropertyFormDescriptionContext.Provider>
    )
}

export const PropertyFormDescriptionTabSide = () => {
    const {
        inputs,
        formik,
        subform,
        selectOptions,
        inputComponents,
        activeTitleLanguage,
        setActiveTitleLanguage,
        languageTabs,
        languageTranslateValues,
        languageSelectOptions,
        realEstateType,
        realEstateService,
    } = useContext(PropertyFormDescriptionContext)
    const [useGoogleTranslate, setUseGoogleTranslate] = useState(false)
    const [activeGoogleTab, setActiveGoogleTab] = useState(activeTitleLanguage)
    const [googleTabs, setGoogleTabs] = useState(languageTabs)
    const [selectedGoogleLanguage, setSelectedGoogleLanguage] = useState()
    const [propertyTitleTranslateValues, setPropertyTitleTranslateValues] = useState(languageTranslateValues)
    const [googleLanguages, setGoogleLanguages] = useState(languageSelectOptions)

    useEffect(() => {
        if (languageTabs?.length) {
            setGoogleTabs(languageTabs)
        }
    }, [languageTabs])

    useEffect(() => {
        if (languageSelectOptions?.length) {
            setGoogleLanguages(languageSelectOptions)
        }
    }, [languageSelectOptions])

    if (inputs.sideForm) {
        const propertyTitleIndex = inputs.sideForm?.findIndex((obj) => obj.name === realEstateDescriptionFields.PROPERTY_TITLE)
        if (propertyTitleIndex !== -1) {
            inputs.sideForm[propertyTitleIndex].tabs = languageTabs
            inputs.sideForm[propertyTitleIndex].activeTab = activeTitleLanguage
            inputs.sideForm[propertyTitleIndex].handleTabChange = (tab) => setActiveTitleLanguage(tab)
        }
    }

    const handleInputChange = (value, language) => {
        setPropertyTitleTranslateValues((prevState) => {
            return {
                ...prevState,
                [language]: value,
            }
        })
    }

    const handleAcceptTranslations = () => {
        const formvalues = getIn(formik.values?.[subform], realEstateDescriptionFields.PROPERTY_TITLE)
        const newValues = Object.assign({}, formvalues, propertyTitleTranslateValues)

        formik.setFieldValue(`${subform}.${[realEstateDescriptionFields.PROPERTY_TITLE]}`, newValues)
        setUseGoogleTranslate(false)
        setPropertyTitleTranslateValues(languageTranslateValues)
    }

    return (
        <FormSideContainer>
            <Card>
                {realEstateType && realEstateService && (
                    <CardBody>
                        <RecursiveForm
                            inputs={inputs?.sideForm}
                            subform={subform}
                            formik={formik}
                            selectOptions={selectOptions}
                            inputComponents={inputComponents}
                        />

                        {/* TODO: future functionality */}
                        {useGoogleTranslate && (
                            <React.Fragment>
                                <Row>
                                    <Col sm="12">
                                        <Select
                                            onChange={(e) => {
                                                let filteredTabs = languageTabs.filter((x) => x.name !== e.value)
                                                let filteredValues = filteredTabs.reduce(
                                                    (dict, item) => ({
                                                        ...dict,
                                                        [item.name]: '',
                                                    }),
                                                    {}
                                                )
                                                setSelectedGoogleLanguage(e)
                                                setGoogleTabs(filteredTabs)
                                                setActiveGoogleTab(filteredTabs[0].name)
                                                setPropertyTitleTranslateValues(filteredValues)
                                            }}
                                            onBlur={() => {
                                                // formik.setFieldTouched(fieldName)
                                            }}
                                            options={googleLanguages ? googleLanguages : []}
                                            id="propertyTitleGoogleLanguage"
                                            name="propertyTitleGoogleLanguage"
                                            // defaultInputValue={formik.values[inputField.name] || ""}
                                            value={selectedGoogleLanguage ?? ''}
                                            placeholder="Odaberi jezik"
                                        />
                                    </Col>
                                    <Col sm="12">
                                        <Button color="edit" className="btn-label my-3" onClick={(e) => e.preventDefault}>
                                            {' '}
                                            <i className="mdi mdi-arrow-right label-icon align-middle fs-16 me-2"></i> Pošalji
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormTabNavigation
                                            customClass="language-input-tabs"
                                            tabs={googleTabs}
                                            activeTab={activeGoogleTab}
                                            handleTabChange={(tab) => setActiveGoogleTab(tab)}
                                        />
                                        <TabContent activeTab={activeGoogleTab}>
                                            {googleTabs?.map((tab) => (
                                                <TabPane key={`propertyTitleGoogleTranslate-${tab.name}`} tabId={tab.name}>
                                                    <Input
                                                        className="tabbed-input"
                                                        type="textarea"
                                                        style={{ resize: 'none', height: '108px' }}
                                                        id={`propertyTitleTranslate.${tab.name}`}
                                                        disabled={!selectedGoogleLanguage}
                                                        name={`propertyTitleTranslate.${tab.name}`}
                                                        onChange={(e) => handleInputChange(e.target.value, tab.name)}
                                                        value={propertyTitleTranslateValues?.[tab.name] || ''}
                                                    />
                                                </TabPane>
                                            ))}
                                        </TabContent>
                                    </Col>
                                    <Col sm="12">
                                        <Button color="success" className="btn-label mt-3" onClick={() => handleAcceptTranslations()}>
                                            {' '}
                                            <i className="mdi mdi-check label-icon align-middle fs-16 me-2"></i> Prihvati
                                        </Button>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                    </CardBody>
                )}
            </Card>
        </FormSideContainer>
    )
}

export const PropertyFormDescriptionTabMain = () => {
    const {
        tabs,
        activeTab,
        handleTabChange,
        inputs,
        formik,
        subform,
        selectOptions,
        inputComponents,
        activeDescriptionLanguage,
        setActiveDescriptionLanguage,
        languageTabs,
        languageTranslateValues,
        languageSelectOptions,
        realEstateType,
        realEstateService,
    } = useContext(PropertyFormDescriptionContext)

    const { t } = useTranslation()

    const [useGoogleTranslate, setUseGoogleTranslate] = useState(false)
    const [activeGoogleTab, setActiveGoogleTab] = useState(activeDescriptionLanguage)
    const [googleTabs, setGoogleTabs] = useState(languageTabs)
    const [selectedGoogleLanguage, setSelectedGoogleLanguage] = useState()
    const [propertyDescriptionTranslateValues, setPropertyDescriptionTranslateValues] = useState(languageTranslateValues)
    const [googleLanguages, setGoogleLanguages] = useState(languageSelectOptions)

    useEffect(() => {
        if (languageTabs?.length) {
            setGoogleTabs(languageTabs)
        }
    }, [languageTabs])

    useEffect(() => {
        if (languageSelectOptions?.length) {
            setGoogleLanguages(languageSelectOptions)
        }
    }, [languageSelectOptions])

    if (inputs.mainForm) {
        const propertyTitleIndex = inputs.sideForm?.findIndex((obj) => obj.name === realEstateDescriptionFields.PROPERTY_TITLE)
        if (propertyTitleIndex !== -1) {
            inputs.mainForm[propertyTitleIndex].tabs = languageTabs
            inputs.mainForm[propertyTitleIndex].activeTab = activeDescriptionLanguage
            inputs.mainForm[propertyTitleIndex].handleTabChange = (tab) => setActiveDescriptionLanguage(tab)
        }
    }

    const handleAcceptTranslations = () => {
        const formvalues = getIn(formik.values?.[subform], realEstateDescriptionFields.PROPERTY_DESCRIPTION)
        const newValues = Object.assign({}, formvalues, propertyDescriptionTranslateValues)

        formik.setFieldValue(`${subform}.${[realEstateDescriptionFields.PROPERTY_DESCRIPTION]}`, newValues)
        setUseGoogleTranslate(false)
        setPropertyDescriptionTranslateValues(languageTranslateValues)
    }

    return (
        <FormMainContainer ainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
            {realEstateType && realEstateService ? (
                <RecursiveForm
                    inputs={inputs?.mainForm}
                    subform={subform}
                    formik={formik}
                    selectOptions={selectOptions}
                    inputComponents={inputComponents}
                />
            ) : (
                <Row>
                    <Col>
                        <p className="text-center">{t('form.property.description.message')}</p>
                    </Col>
                </Row>
            )}

            {/* TODO: future functionality */}
            {useGoogleTranslate && (
                <React.Fragment>
                    <Row>
                        <Col sm="12">
                            <Select
                                onChange={(e) => {
                                    // formik.setFieldValue(fieldName, e.value)
                                    let filteredTabs = languageTabs.filter((x) => x.name !== e.value)
                                    let filteredValues = filteredTabs.reduce(
                                        (dict, item) => ({
                                            ...dict,
                                            [item.name]: '',
                                        }),
                                        {}
                                    )
                                    setSelectedGoogleLanguage(e)
                                    setGoogleTabs(filteredTabs)
                                    setActiveGoogleTab(filteredTabs[0].name)
                                    setPropertyDescriptionTranslateValues(filteredValues)
                                }}
                                onBlur={() => {
                                    // formik.setFieldTouched(fieldName)
                                }}
                                options={googleLanguages ? googleLanguages : []}
                                id="propertyTitleGoogleLanguage"
                                name="propertyTitleGoogleLanguage"
                                // defaultInputValue={formik.values[inputField.name] || ""}
                                value={selectedGoogleLanguage ?? ''}
                                placeholder="Odaberi jezik"
                            />
                        </Col>
                        <Col sm="12">
                            <Button color="edit" className="btn-label my-3" onClick={(e) => e.preventDefault}>
                                {' '}
                                <i className="mdi mdi-arrow-right label-icon align-middle fs-16 me-2"></i> Pošalji
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <FormTabNavigation
                                customClass="language-input-tabs"
                                tabs={googleTabs}
                                activeTab={activeGoogleTab}
                                handleTabChange={(tab) => setActiveGoogleTab(tab)}
                            />
                            <TabContent activeTab={activeGoogleTab}>
                                {googleTabs?.map((tab) => (
                                    <TabPane key={`propertyTitleGoogleTranslate-${tab.name}`} tabId={tab.name}>
                                        <RichTextEditor
                                            content={propertyDescriptionTranslateValues?.[tab.name] || ''}
                                            disabled={!selectedGoogleLanguage}
                                        />
                                    </TabPane>
                                ))}
                            </TabContent>
                        </Col>
                        <Col sm="12">
                            <Button color="success" className="btn-label mt-3" onClick={() => handleAcceptTranslations()}>
                                {' '}
                                <i className="mdi mdi-check label-icon align-middle fs-16 me-2"></i> Prihvati
                            </Button>
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </FormMainContainer>
    )
}

export default PropertyFormDescriptionTab
