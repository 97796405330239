import { values } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainer from '../../../Components/Table/TableContainer'
import { RowData } from '../globalTypes'
import { AgentActivityReportProps } from './types'
import { getActivityOveralls } from './utils'

export const AgentActivityReportTable = (props: AgentActivityReportProps) => {
    const { reportsData, reportsFetching } = props
    const { t } = useTranslation()

    const reports = useMemo(() => values(reportsData) || [], [reportsData])
    const activityTypes = useMemo(() => reports[0]?.activities || [], [reports])

    const columns = useMemo(() => {
        const agentColumn = {
            Header: t('app.common.agent'),
            id: 'agent',
            accessor: (row: RowData) => `${row.agent?.firstName} ${row.agent?.lastName}`,
            filterable: false,
        }

        const activityColumns = activityTypes?.map(({ id, name }) => ({
            Header: name,
            id: `${id}`,
            accessor: (row: RowData) => {
                return row.activities?.find((activity) => activity?.id === id)?.count
            },
            filterable: false,
        }))

        const totalColumn = {
            Header: t('app.common.total'),
            id: 'total_by_agent',
            accessor: (row: RowData) => <div className="fw-bolder text-center">{row?.agentGrandTotal}</div>,
            filterable: false,
        }

        return [agentColumn, ...activityColumns, totalColumn]
    }, [activityTypes, t])

    const tableFooter = useMemo(() => {
        const { activityOveralls, grandOverall } = getActivityOveralls(reports, activityTypes)

        return (
            <tr>
                <td>{t('app.common.overall')}</td>
                {activityOveralls.map((activity) => (
                    <td key={activity.id}>{activity.count}</td>
                ))}
                <td className="fw-bolder text-center">{grandOverall}</td>
            </tr>
        )
    }, [reports, activityTypes, t])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            tableFooter={tableFooter}
            tableHash={'reports_agent-activities'}
            hidePagination={true}
            {...({} as any)} // temporary fix
        />
    )
}
