import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'
import AppTopSwitcher from '../Components/AppTopSwitcher/AppTopSwitcher'
import { selectIsAuthenticated } from '../store/auth'

const AuthProtected = (props) => {
    const location = useLocation()
    const { isAuthenticated } = useSelector((state) => ({
        isAuthenticated: selectIsAuthenticated(state),
    }))

    if (!isAuthenticated) {
        return <Redirect to={`/login?continue=${encodeURIComponent([location?.pathname, location?.search].join(''))}`} />
    }

    return <React.Fragment>{props.children}</React.Fragment>
}

const AccessRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <AppTopSwitcher link={rest.path} feature="Navigation" permission="Navigation">
                        {(switcherProps) => {
                            const { featureLinkAllowed, permissionLinkAllowed } = switcherProps
                            const isLinkAvailable = featureLinkAllowed && permissionLinkAllowed

                            return isLinkAvailable ? <Component {...props} /> : <Redirect to="/projects" />
                        }}
                    </AppTopSwitcher>
                )
            }}
        />
    )
}

export { AccessRoute, AuthProtected }
