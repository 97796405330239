import { useEffect, useState } from 'react'
import i18next from 'i18next'

const toolbarTranslations = () => {
    const { t } = i18next
    const buttonTranslations = {
        '.tie-btn-crop': t('photoeditor.crop'),
        '.tie-btn-resize': t('photoeditor.resize'),
        '.tie-btn-rotate': t('photoeditor.rotate'),
        '.tie-btn-draw': t('photoeditor.draw'),
        '.tie-btn-shape': t('photoeditor.shape'),
        '.tie-btn-icon': t('photoeditor.icon'),
        '.tie-btn-text': t('photoeditor.text'),
    }
    getEditModeTranslations(buttonTranslations, 'toolbar')
}

const actionButtonsTranslation = (crop) => {
    const cropActionsContainer = document.querySelector('.tie-crop-button')
    const resizeActionsContainer = document.querySelector('.tie-resize-button')
    const container = crop ? cropActionsContainer : resizeActionsContainer

    const { t } = i18next
    const actionTranslations = {
        '.apply': t('button.accept'),
        '.cancel': t('button.cancel'),
    }

    if (container) {
        for (const [buttonClass, translation] of Object.entries(actionTranslations)) {
            const button = container.querySelector(buttonClass)
            const svg = button.querySelector('svg')
            const useElement = svg.firstElementChild

            const label = button.querySelector('label')
            label.textContent = translation

            const isApplyBtn = button.classList.contains('apply')

            if (isApplyBtn) {
                useElement.style.fill = 'green'
                useElement.style.stroke = 'green'
                useElement.style.strokeWidth = '1.5px'
            } else {
                useElement.style.fill = 'red'
                useElement.style.stroke = 'red'
                useElement.style.strokeWidth = '1.5px'
            }
        }
    }
}

const cropTranslations = () => {
    const { t } = i18next
    const customCropBtn = document.querySelector('.preset-none')
    const label = customCropBtn.querySelector('label')
    label.textContent = t('photoeditor.customCrop')
}

const resizeTranslations = () => {
    const { t } = i18next
    const resizeContainer = document.querySelector('.tui-image-editor-range-wrap')
    const ratioCheckbox = document.querySelector('.tie-lock-aspect-ratio')

    const resizeLabelsTranslations = { 0: t('photoeditor.resizeWidth'), 2: t('photoeditor.resizeHeight') }
    const ratioLabel = t('photoeditor.lockRatio')

    if (resizeContainer) {
        const labels = resizeContainer.querySelectorAll('label')
        labels.forEach((label, index) => (label.textContent = resizeLabelsTranslations[index]))
    }

    if (ratioCheckbox) {
        const span = ratioCheckbox.nextElementSibling
        span.textContent = ratioLabel
    }
}

const rotateDrawRangeTranslation = (rotate) => {
    const { t } = i18next
    const rotateRange = document.querySelector('.tie-rotate-range')
    const drawRange = document.querySelector('.tie-draw-range')

    const label = rotate ? rotateRange.previousElementSibling : drawRange.previousElementSibling
    label.textContent = rotate ? t('photoeditor.rotate') : t('photoeditor.strokeWidth')
}

const drawTranslation = () => {
    const { t } = i18next
    const drawTranslations = {
        '.free': t('photoeditor.customCrop'),
        '.line': t('photoeditor.line'),
        '.tie-draw-color': t('photoeditor.color'),
    }
    getEditModeTranslations(drawTranslations, 'draw')
}

const shapeTranslation = () => {
    const { t } = i18next
    const shapeTranslations = {
        '.tui-image-editor-button.rect': t('photoeditor.rectangle'),
        '.tui-image-editor-button.circle': t('photoeditor.circle'),
        '.tui-image-editor-button.triangle': t('photoeditor.triangle'),
        '.tie-color-fill': t('photoeditor.colorFill'),
        '.tie-color-stroke': t('photoeditor.colorStroke'),
    }
    getEditModeTranslations(shapeTranslations, 'shape')
    rangeTranslation('.tie-stroke-range', t('photoeditor.borderThickness'))
}

const iconsTranslation = () => {
    const { t } = i18next
    const iconTranslations = {
        'icon-arrow': `${t('photoeditor.iconArrow')}-1`,
        'icon-arrow-2': `${t('photoeditor.iconArrow')}-2`,
        'icon-arrow-3': `${t('photoeditor.iconArrow')}-3`,
        'icon-star': `${t('photoeditor.iconStar')}-1`,
        'icon-star-2': `${t('photoeditor.iconStar')}-2`,
        'icon-polygon': t('photoeditor.iconPolygon'),
        'icon-location': t('app.common.location'),
        'icon-heart': t('photoeditor.iconHeart'),
        'icon-bubble': t('photoeditor.iconCloud'),
    }

    const iconAddBtn = document.querySelector('.tie-icon-image-file')
    const customIconlabel = iconAddBtn.parentElement.nextElementSibling
    customIconlabel.textContent = t('photoeditor.addIcon')

    getEditModeTranslations(iconTranslations, 'icon')
    colorPickerTranslation('.tie-icon-color')
}

const textTranslation = () => {
    const { t } = i18next
    const textAlignTranslations = { 0: t('photoeditor.textLeft'), 1: t('photoeditor.textCenter'), 2: t('photoeditor.textRight') }
    const textAlign = document.querySelector('.tie-text-align-button')
    const labels = textAlign.querySelectorAll('label')

    labels.forEach((label, index) => (label.textContent = textAlignTranslations[index]))

    rangeTranslation('.tie-text-range', t('photoeditor.textSize'))
    colorPickerTranslation('.tie-text-color')
}

const filterTranslation = () => {
    const { t } = i18next
    const filterTranslations = {
        '.tie-grayscale': t('photoeditor.filterGrayscale'),
        '.tie-invert': t('photoeditor.filterInvert'),
        '.tie-sepia': t('photoeditor.filterSepia'),
        '.tie-vintage': t('photoeditor.filterVintage'),
        '.tie-blur': t('photoeditor.filterBlur'),
        '.tie-sharpen': t('photoeditor.filterSharpen'),
        '.tie-emboss': t('photoeditor.filterEmboss'),
        '.tie-remove-white': t('photoeditor.filterRemoveWhite'),
        '.tie-brightness': t('photoeditor.filterBrightness'),
        '.tie-noise': t('photoeditor.filterNoise'),
        '.tie-pixelate': t('photoeditor.filterPixelate'),
        '.tie-color-filter': t('photoeditor.filterColorFilter'),
    }
    const filterTranslations2 = {
        '.tie-filter-tint-color': t('photoeditor.filterTintColor'),
        '.tie-filter-multiply-color': t('photoeditor.filterMultiplyColor'),
        '.tie-filter-blend-color': t('photoeditor.filterBlendColor'),
    }
    getEditModeTranslations(filterTranslations, 'filter')
    getEditModeTranslations(filterTranslations2, 'filter2')
    rangeTranslation('.tie-removewhite-distance-range', t('photoeditor.filterDistance'))
    rangeTranslation('.tie-colorfilter-threshold-range', t('photoeditor.filterTreshold'))
}

// helper functions

const useActiveButton = (buttonClass1, buttonClass2, callback) => {
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        const btn1 = document.querySelector(buttonClass1)
        const btn2 = document.querySelector(buttonClass2)

        btn1.addEventListener('click', () => setIsActive(true))
        btn2.addEventListener('click', () => setIsActive(false))

        callback(isActive)
    }, [buttonClass1, buttonClass2, isActive, callback])
}

const getEditModeTranslations = (translations, mode) => {
    for (const [elementClass, translation] of Object.entries(translations)) {
        const element = document.querySelector(mode === 'icon' ? `[data-icontype="${elementClass}"]` : elementClass)

        switch (mode) {
            case 'draw':
            case 'shape':
            case 'icon': {
                const label = element.querySelector('label')
                label.textContent = translation
                break
            }
            case 'toolbar': {
                element.setAttribute('tooltip-content', translation)
                break
            }
            case 'filter': {
                const span = element.nextElementSibling
                span.textContent = translation
                break
            }
            case 'filter2': {
                const label = element.lastChild
                label.textContent = translation
                break
            }
            default:
                break
        }
    }
}

const colorPickerTranslation = (buttonClass) => {
    const { t } = i18next
    const colorPicker = document.querySelector(buttonClass)
    const colorLabel = colorPicker.querySelector('label')
    colorLabel.textContent = t('photoeditor.color')
}

const rangeTranslation = (buttonClass, translation) => {
    const range = document.querySelector(buttonClass)
    const label = range.previousElementSibling
    label.textContent = translation
}

export {
    actionButtonsTranslation,
    cropTranslations,
    drawTranslation,
    filterTranslation,
    iconsTranslation,
    resizeTranslations,
    rotateDrawRangeTranslation,
    shapeTranslation,
    textTranslation,
    toolbarTranslations,
    useActiveButton,
}
