import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { FormattedData } from '../globalTypes'
import { formatGroupedData, getTotals, handleChartColors, handleChartHeight } from '../utils'
import { AgentRevenueReportProps, RevenueTotal } from './types'
import { formatNumber, getGroupData, getRevenueOveralls } from './utils'

const AgentRevenueReportChart = (props: AgentRevenueReportProps) => {
    const { t } = useTranslation()
    const overallLabel = t('app.common.overall')
    const totalLabel = t('app.common.total')

    const { reportsData } = props
    const reports = useMemo(() => reportsData?.data || [], [reportsData]) 
    const months = useMemo(() => reportsData?.timePeriod || [], [reportsData])

    const formattedData = formatGroupedData({
        data: reports,
        dataKey: 'agent',
        handleGroupData: (revenueTotal: RevenueTotal, agentName: string) => getGroupData(revenueTotal, agentName),
    }) as FormattedData[]

    const { monthOveralls } = useMemo(() => getRevenueOveralls(reports, months), [reports, months])

    // adds month overalls data ("Sveukupno") to formattedData array
    const dataWithOveralls = useMemo(() => {
        return formattedData.map((data) => {
            const monthOverallObj = monthOveralls.find((month) => month.id === data.id)

            return {
                ...data,
                monthName: monthOverallObj?.monthName,
                [overallLabel]: monthOverallObj?.count,
            }
        })
    }, [formattedData, monthOveralls, overallLabel])

    const totalsObj = useMemo(() => {
        const revenueTotals = getTotals(reports)
        const totalOverall = monthOveralls.reduce((acc, curr) => acc + curr.count, 0)

        return {
            id: 'Ukupno',
            monthName: totalLabel,
            ...revenueTotals,
            [overallLabel]: totalOverall,
        }
    }, [reports, monthOveralls, totalLabel, overallLabel])

    const chartData = [totalsObj, ...dataWithOveralls]

    const numObjects = chartData.length
    const numFields = Object.keys(chartData[0]).length - 2 // exclude id, monthName
    const [totalHeight, barSize, barGap] = handleChartHeight(numObjects, numFields)

    const { overallColor, reportColors } = handleChartColors(reports.length)

    return (
        <div style={{ height: `${totalHeight}px` }} className="w-full">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout="vertical"
                    data={chartData}
                    barSize={barSize}
                    barGap={barGap}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 28,
                        bottom: 50,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="monthName" type="category" tickMargin={10} />
                    <Tooltip formatter={(value: number) => (value > 0 ? formatNumber(value) : 0)} contentStyle={{ fontSize: '11px' }} />
                    <Legend iconSize={16} />

                    <Bar key="overall" dataKey={overallLabel} fill={overallColor}>
                        <LabelList
                            dataKey={overallLabel}
                            position="right"
                            fill="#000"
                            fontSize={12}
                            formatter={(value: number) => (value > 0 ? formatNumber(value) : '')}
                        />
                    </Bar>

                    {reports?.map((item, index) => (
                        <Bar key={item.agent.id} dataKey={`${item.agent.firstName} ${item.agent.lastName}`} fill={reportColors[index]}>
                            <LabelList
                                dataKey={`${item.agent.firstName} ${item.agent.lastName}`}
                                position="right"
                                fill="#000"
                                fontSize={12}
                                formatter={(value: number) => (value > 0 ? formatNumber(value) : '')}
                            />
                        </Bar>
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default AgentRevenueReportChart
