import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { diaryResponseFields } from '../../../../common/response/diary'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../../../store/settings/selectors'
import { formatNumberWithTwoDecimals } from '../../../../utils/general'
import { renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../../../utils/property'

const TableColumns = (onViewDelete) => {
    const { currency, areaUnit } = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        areaUnit: selectApplicationAreaUnit(state),
    }))

    const { t } = useTranslation()

    const insertCellBuyerSeller = (headerName, objectName, propertyName) => {
        return {
            Header: headerName,
            id: `${headerName}_${objectName}`,
            Cell: (item) => {
                return item.row.original?.[objectName].map((element) => <div key={element.id}>{element?.[propertyName]}</div>)
            },
            filterable: true,
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: t('app.common.id'),
                accessor: diaryResponseFields.INTERNAL_APP_ID,
                filterable: true,
            },
            insertCellBuyerSeller(t('table.header.diary.sellerName'), 'intermediaryDiarySeller', diaryResponseFields.BUYER_SELLER.NAME),
            insertCellBuyerSeller(t('app.common.pin'), 'intermediaryDiarySeller', diaryResponseFields.BUYER_SELLER.OIB),
            insertCellBuyerSeller(t('table.header.diary.buyerName'), 'intermediaryDiaryBuyer', diaryResponseFields.BUYER_SELLER.NAME),
            insertCellBuyerSeller(t('app.common.pin'), 'intermediaryDiaryBuyer', diaryResponseFields.BUYER_SELLER.OIB),
            {
                Header: t('table.header.diary.propertyType'),
                accessor: diaryResponseFields.PROPERTY_TYPE,
                filterable: true,
            },
            {
                Header: t('app.common.location'),
                accessor: diaryResponseFields.PROPERTY_LOCATION,
                filterable: true,
                minWidth: 200,
            },
            {
                Header: t('app.common.street'),
                accessor: diaryResponseFields.PROPERTY_STREET,
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.area', { areaUnit: renderAreaUnit(areaUnit) }),
                id: diaryResponseFields.AREA,
                accessor: diaryResponseFields.AREA,
                filterable: true,
            },
            {
                Header: t('table.header.diary.insert'),
                accessor: diaryResponseFields.PROPERTY_REGISTRY_INSERT,
                filterable: true,
            },
            {
                Header: t('table.header.diary.subInsert'),
                accessor: diaryResponseFields.PROPERTY_REGISTRY_SUBINSERT,
                filterable: true,
            },
            {
                Header: t('table.header.diary.cadastralNo'),
                accessor: diaryResponseFields.PROPERTY_CADASTRAL_PARCEL_NO,
                filterable: true,
            },
            {
                Header: t('table.header.diary.cadastralMunicipality'),
                accessor: diaryResponseFields.PROPERTY_CASASTRAL_MUNICIPALITY,
                filterable: true,
            },
            {
                Header: t('table.header.diary.price', { price: renderCurrency(currency) }),
                id: diaryResponseFields.PRICE,
                Cell: (item) => {
                    const formatedPrice =
                        item.row.original?.[diaryResponseFields.PRICE] &&
                        formatNumberWithTwoDecimals(Number(item.row.original?.[diaryResponseFields.PRICE]))
                    return <>{formatedPrice}</>
                },
                filterable: true,
            },
            {
                Header: t('table.header.diary.priceM2', { priceM2: renderCurrencyWithUnit(currency, areaUnit) }),
                id: diaryResponseFields.PRICE_M2,
                Cell: (item) => {
                    const formatedPrice =
                        item.row.original?.[diaryResponseFields.PRICE_M2] &&
                        formatNumberWithTwoDecimals(Number(item.row.original?.[diaryResponseFields.PRICE_M2]))
                    return <>{formatedPrice}</>
                },
                filterable: true,
            },
            {
                Header: t('table.header.diary.contractType'),
                accessor: diaryResponseFields.CONTRACT_TYPE,
                filterable: true,
            },
            {
                Header: t('app.common.conclusionPlace'),
                accessor: diaryResponseFields.CONCLUSION_PLACE,
                filterable: true,
            },
            {
                Header: t('app.common.conclusionDate'),
                accessor: diaryResponseFields.CONCLUSION_DATE,
                filterable: true,
            },
            {
                Header: t('table.header.diary.bussniesStatus'),
                accessor: diaryResponseFields.BUSSNIES_STATUS,
                filterable: true,
            },
            {
                id: 'actions',
                thDivClass: 'text-center',
                Cell: (cellProps) => {
                    const diaryId = cellProps?.row?.original.id

                    return (
                        <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                            <li className="list-inline-item edit">
                                <Link
                                    title={t('button.edit')}
                                    to={`/diary/${cellProps.row.original.id}/edit`}
                                    className="text-info d-inline-block edit-item-btn"
                                >
                                    <i className="mdi mdi-pencil-outline fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link
                                    to="#"
                                    title={t('button.view')}
                                    className="text-warning d-inline-block"
                                    onClick={() => onViewDelete(diaryId, false)}
                                >
                                    <i className="mdi mdi-eye-outline fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link
                                    to="#"
                                    title={t('button.delete')}
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={() => onViewDelete(diaryId, true)}
                                >
                                    <i className="mdi mdi-trash-can-outline fs-16"></i>
                                </Link>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [areaUnit, currency, t, onViewDelete]
    )

    return columns
}

export default TableColumns
