import { getUrlBase } from '../../../utils/general'
import { setPermissions } from './utils'

export const setNavigation = ({ agentPermissions, link }) => {
    if (!link) return { permissionLinkAllowed: false }

    const module = link.split('/')[1]
    const moduleName = getUrlBase(module)

    const tokensToCheck = ['intermediaryDiary', 'report']
    const isPermissionControlled = tokensToCheck.includes(moduleName)

    // diary & reports modules visibility is controlled by permissions, 
    // unlike onther modules which visibility is controlled by features
    if (isPermissionControlled) {
        const permission = `module.${moduleName}.access`
        const permissionLinkAllowed = setPermissions(agentPermissions, permission)

        return { permissionLinkAllowed }
    } else {
        return { permissionLinkAllowed: true }
    }
}
