export const companyResponseFields = {
    ID: 'id',
    INTERNAL_APP_ID: 'internalAppId',
    NAME: 'name',
    FULL_NAME: 'fullName',
    TAX_NUMBER: 'taxNumber',
    INITIAL_CONTACT_DATE: 'initialContactDate',
    CONTACT_COUNTRY: 'contactCountry',
    CITY: 'city',
    STREET: 'street',
    STREET_NUMBER: 'streetNumber',
    ZIP_CODE: 'zipCode',
    CONTACT_PRIORITY: 'contactPriority',
    DATA_SOURCE: 'dataSource',
    PHONE_NUMBER: 'phoneNumber',
    CONTACT_PHONE_TYPE: 'contactPhoneType',
    EMAIL: 'email',
    CONTACT_EMAIL_TYPE: 'contactEmailType',
    NEWSLETTER_APPROVAL: 'newsletterApproval',
    WEBSITE: 'website',
    CONTACT_LEGAL_FORM: 'contactLegalForm',
    CONTACT_QUERY_CATEGORY: 'contactQueryCategory',
    CONTACT_DESCRIPTION: 'contactDescription',
    CONTACT_CORE_BUSINESS: 'contactCoreBusiness',
    CONTACT_COMPANY_SIZE: 'contactCompanySize',
    EMPLOYEES_NO: 'employeesNo',
    BANK_ACCOUNT_NO: 'bankAccountNo',
    REGISTRY_COURT: 'registryCourt',
    SHARE_CAPITAL: 'shareCapital',
    INITIAL_CALL_INFO: 'initialCallInfo',
    NOTES: 'notes',
    CONTACT_STATUS: 'contactStatus',
    UPDATED: 'updated',
    CREATED: 'created',
    CONTACT_COMPANY: 'contactCompany',
    PROPERTY_SUB_TYPE: 'propertySubType',
    COUNTY: 'county',
    WIDER_AREA: 'widerArea',
    QUARTER: 'quarter',
    ISLAND: 'island',
    PRICE_FROM: 'priceFrom',
    PRICE_TO: 'priceTo',
    PRICE_M2_FROM: 'priceM2From',
    PRICE_M2_TO: 'priceM2To',
    ROOMS_FROM: 'roomsFrom',
    ROOMS_TO: 'roomsTo',
    BEDROOMS_FROM: 'bedroomsFrom',
    BEDROOMS_TO: 'bedroomsTo',
    AREA_FROM: 'areaFrom',
    AREA_TO: 'areaTo',
    PROPERTY_FLOOR_POSITION: 'propertyFloorPosition',
    PROPERTY_FLOOR: 'propertyFloor',
    EXCLUDE_BASEMENT: "excludeBasement",
    EXCLUDE_LAST_FLOOR: 'excludeLastFloor',
    PROPERTY_SPACE: 'propertySpace',
    PROPERTY_DESCRIPTION: 'propertyDescription',
    PROPERTY_EQUIPMENT: 'propertyEquipment',
    PROPERTY_UTILITY: 'propertyUtility',
    PROPERTY_HEATING: 'propertyHeating',
    PROFILE_REMARK: 'contactProfileRemark',
    CONTACT_FINANCING: 'contactFinancing',
    CONTACT_PROFILE_DURATION: 'contactProfileDuration',
    FLOOR_FROM: 'floorFrom',
    FLOOR_TO: 'floorTo',
    PROPERTY_DESCRIPTIONS: 'propertyDescriptions',
    CONTACT_SOURCE: 'contactSource',
    CONTACT_ABOUT: 'contactAbout',
    COUNTRY: 'country',
    CONTACT_PHONE: 'contactPhone',
    CONTACT_EMAIL: 'contactEmail',
    AGENT: 'agent',
    SPECIAL_REQUEST: 'specialRequest',
    LAST_CONTACT_PROFILE: 'lastContactProfile',
    PREVIOUS_ACTIVITY: 'previousActivity',
    NEXT_ACTIVITY: 'nextActivity',
    CONTACT_PROFILE_STATUS: 'contactProfileStatus',
    COMPANY_DEFINITION: 'companyDefinition',
}