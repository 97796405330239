import { TimePeriod } from '../globalTypes'
import { groupByFieldValues } from '../utils'
import { RevenueData, RevenueTotal } from './types'

export const formatNumber = (num: number) =>
    new Intl.NumberFormat('hr-HR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(num)

// "Sveukupno" row
export const getRevenueOveralls = (reports: RevenueData[], months: TimePeriod[]) => {
    if (!reports || !months) return { monthOveralls: [], grandOverall: 0 }

    const groupedData = groupByFieldValues(reports, 'revenueTotals', 'month')

    const monthOveralls = months.map((obj) => {
        const countsPerMonth = groupedData[obj.month] as RevenueTotal[]

        const overall =
            countsPerMonth?.reduce((acc, curr) => {
                const totalSale = curr.totalSale || 0
                const totalRent = curr.totalRent || 0

                return acc + totalSale + totalRent
            }, 0) || 0

        return {
            id: obj.month,
            monthName: obj.monthName,
            count: overall,
        }
    })

    const grandOverall = monthOveralls.reduce((acc, curr) => acc + curr.count, 0)

    return { monthOveralls, grandOverall }
}

export const getGroupData = (revenueTotal: RevenueTotal, agentName: string) => {
    const id = revenueTotal.month
    const value = (revenueTotal.totalSale || 0) + (revenueTotal.totalRent || 0)
    const newValueObj = {
        id,
        [agentName]: value,
    }

    return { id, value, newValueObj }
}
