import { TimePeriod } from '../globalTypes'
import { groupByFieldValues } from '../utils'
import { PropertyData, PropertyTotal } from './types'

// "Sveukupno" row
export const getPropertyOveralls = (reports: PropertyData[], months: TimePeriod[]) => {
    if (!reports || !months) return { monthOveralls: [], grandOverall: 0 }

    const groupedData = groupByFieldValues(reports, 'realizedPropertyTotals', 'month')

    const monthOveralls = months.map((obj) => {
        const totalsByMonth = groupedData[obj.month] as PropertyTotal[]

        const overall =
            totalsByMonth?.reduce((acc, curr) => {
                const totalSale = curr.totalSale || 0
                const totalRent = curr.totalRent || 0

                return acc + totalSale + totalRent
            }, 0) || 0

        return {
            id: obj.month,
            monthName: obj.monthName,
            count: overall,
        }
    })

    const grandOverall = monthOveralls.reduce((acc, curr) => acc + curr.count, 0)

    return { monthOveralls, grandOverall }
}
export const getGroupData = (propertyTotal: PropertyTotal, dataSourceName: string) => {
    const id = propertyTotal.month
    const value = (propertyTotal.totalSale || 0) + (propertyTotal.totalRent || 0)
    const newValueObj = {
        id,
        [dataSourceName]: value,
    }

    return { id, value, newValueObj }
}
