export const projectGeneralFields = {
    ID: 'id',
    PROJECT_STATUS_ID: 'project_status_id',
    PROJECT_SIZE_ID: 'project_size_id', // ERSTE ONLY FIELD
    PROJECT_TYPE_ID: 'project_type_id',
    PROJECT_OBJECT_TYPE_ID: 'project_object_type_id', // ERSTE ONLY FIELD
    PROJECT_DEVELOPMENT_STAGE_ID: 'project_development_stage_id', // ERSTE ONLY FIELD
    PROJECT_END_DATE: 'project_end_date',
    PROJECT_RECEIPT_DATE: 'project_receipt_date',
    CONTRACT_NO: 'contract_no',
    CONTRACT_DATE: 'contract_date',
    OWNER_ID: 'owner_id',
    OWNER_COMPANY_ID: 'owner_company_id',
    COUNTRY_ID: 'country_id',
    COUNTY_ID: 'county_id',
    CITY_ID: 'city_id',
    WIDER_AREA_ID: 'wider_area_id',
    QUARTER_ID: 'quarter_id',
    ISLAND_ID: 'island_id',
    STREET: 'street',
    STREET_NUMBER: 'street_number',
    STREET_SYNC: 'street_sync',
    PROJECT_ENERGY_EFFICIENCY_ID: 'project_energy_efficiency_id',
    PROJECT_WEB_SYNC: 'project_web_sync',
    PROJECT_WEB_NAVIGATION_SYNC: 'project_web_navigation_sync', // ERSTE ONLY FIELD
    AGENCY_COMMISSION_ID: 'agency_commission_id', // ERSTE ONLY FIELD
    AGENT_ID: 'agent_id',
    ERSTE_REPRESENTATIVE: 'erste_representative', // ERSTE ONLY FIELD
}

export const projectDescriptionFields = {
    PROJECT_TITLE: 'project_title',
    PROJECT_DESCRIPTION: 'project_description',
    PROJECT_OFFER_DESCRIPTION: 'project_offer_description'
}

export const projectNoteFields = {
    ID: 'id',
    NOTE_TITLE: 'note_title',
    NOTE_DATE: 'note_date',
    NOTE: 'note',
    PROJECT_NOTE_AGENT_ID: 'agent_id',
}

export const projectDetailSearchFields = {
    ID: 'id',
    INTERNAL_APP_ID: 'internalAppId',
    PROJECT_TITLE: 'projectTitle',
    CITY: 'city',
    PROJECT_TYPE: 'projectType',
    PROJECT_STATUS: 'projectStatus',
    AGENT: 'agent',
    PROJECT_RECEIPT_DATE_FROM: 'projectReceiptDateFrom',
    PROJECT_RECEIPT_DATE_TO: 'projectReceiptDateTo',
}