import AppFeaturesSwitcher from '../AppFeaturesSwitcher/AppFeaturesSwitcher'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'

// this component combines features and permissions since they can intersect
const AppTopSwitcher = ({ children, ...props }) => {
    const { feature, permission, permissionName, module, action, link } = props

    return (
        <AppFeaturesSwitcher feature={feature} link={link}>
            {(featureProps) => (
                <AppPermissionsSwitcher permission={permission} permissionName={permissionName} module={module} action={action} link={link}>
                    {(permissionProps) => {
                        const { featureTabs, featureActivitiesAccordion, featureLinkAllowed } = featureProps ?? {}
                        const { hasModulePermission, permissionTabs, permissionLinkAllowed } = permissionProps ?? {}

                        const newProps = {
                            featureTabs,
                            permissionTabs,
                            featureActivitiesAccordion,
                            hasModulePermission,
                            featureLinkAllowed,
                            permissionLinkAllowed,
                        }

                        return children(newProps)
                    }}
                </AppPermissionsSwitcher>
            )}
        </AppFeaturesSwitcher>
    )
}

export default AppTopSwitcher
