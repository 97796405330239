import TableContainer from '../../Table/TableContainer'
import inDepthAnalysis from '../../../api/inDepthAnalysis/hooks'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import TableColumns from './TableColumns/TableColumns'
import { ColumnOrder, PageListParams, RouteParams } from '../../../api/globalTypes'
import { useParams } from 'react-router-dom'
import InDepthAnalysisPdfModal from './InDepthAnalysisPdfModal/InDepthAnalysisPdfModal'
import { useRef, useState } from 'react'
import { InDepthAnalysisListProps } from './InDepthAnalysisList.types'
import ContactsConnectedToCompany from './ContactsConnectedToCompany/ContactsConnectedToCompany'
import dayjs from 'dayjs'
import BadgeRiskAssessment from '../BadgeRiskAssessment/BadgeRiskAssessment'
import Loader from '../../Common/Loader'

const InDepthAnalysisList = (props: InDepthAnalysisListProps) => {
    const { formId, contactType } = props
    const { id } = useParams<RouteParams>()
    const [printModalOpen, setPrintModalOpen] = useState(false)
    const [analysisParams, setAnalysisParams] = useState<PageListParams>({
        page: 1,
        limit: 20,
        order: {} as ColumnOrder,
    })
    const formSubmissionId = useRef('')

    const refreshToken = localStorage.getItem('refresh_token') as string

    const fetchData = analysisParams.page !== 0 && analysisParams.limit !== 0 && Object.keys(analysisParams.order ?? {}).length > 0

    const { isLoading: fetchingForms, data: formData } = inDepthAnalysis.GetFormsSubmission.useQuery(id, analysisParams, fetchData, refreshToken)
    const { isLoading: fetchingPdf, data: pdfData, isError } = inDepthAnalysis.GetAnalysisPdf.useQuery(formSubmissionId.current, refreshToken)

    const tempFormList = !fetchingForms ? formData?.items : []

    const lastFormSubmission = tempFormList && [...tempFormList].sort((a, b) => b.id - a.id)[0]
    const formList = tempFormList && tempFormList.filter((item) => item.id !== lastFormSubmission?.id)

    const handlePrint = (id?: number) => {
        formSubmissionId.current = id?.toString() ?? ''
        setPrintModalOpen(!printModalOpen)
    }

    const handleDataFetch = (page: number, limit: number, order: ColumnOrder) => {
        setAnalysisParams({ page, limit, order })
    }

    const handlePrintModal = () => {
        setPrintModalOpen(!printModalOpen)
    }

    const formUrl = (id: string) => {
        return `/${contactType ? 'companies' : 'contacts'}/${id}/indepthanalysis/createEdit?formId=${formId}`
    }

    const columns = TableColumns(handlePrint, formUrl)

    return fetchingForms ? (
        <div className="page-content">
            <Container fluid>
                <Loader />
            </Container>
        </div>
    ) : (
        <>
            <Row>
                <Col sm={12} xxl={4}>
                    <Card>
                        <CardHeader>
                            <h5 className="mb-0 me-4">Trenutna dubinska analiza</h5>
                        </CardHeader>
                        <CardBody>
                            {lastFormSubmission && (
                                <>
                                    <Row className={`align-items-start mt-3`}>
                                        <Col sm={6}>
                                            <span className="flex-grow-1 fw-medium">Id: </span>
                                        </Col>
                                        <Col sm={6}>
                                            <span className="flex-grow-1 fw-light">{lastFormSubmission?.id}</span>
                                        </Col>
                                    </Row>
                                    <Row className={`align-items-start mt-3`}>
                                        <Col sm={6}>
                                            <span className="flex-grow-1 fw-medium">Agent: </span>
                                        </Col>
                                        <Col sm={6}>
                                            <span className="flex-grow-1 fw-light">
                                                {lastFormSubmission?.agent.firstName} {lastFormSubmission?.agent.lastName}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className={`align-items-start mt-3`}>
                                        <Col sm={6}>
                                            <span className="flex-grow-1 fw-medium">Procjena rizika:</span>
                                        </Col>
                                        <Col sm={6}>
                                            <BadgeRiskAssessment riskAssessment={lastFormSubmission?.riskAssessment} />
                                        </Col>
                                    </Row>
                                    <Row className={`align-items-start mt-3`}>
                                        <Col sm={6}>
                                            <span className="flex-grow-1 fw-medium">Datum kreiranja:</span>
                                        </Col>
                                        <Col sm={6}>
                                            <span className="flex-grow-1 fw-light">
                                                {dayjs(lastFormSubmission?.created).format('DD.MM.YYYY., HH:mm:ss')}
                                            </span>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <div className="d-flex flex-grow-1 flex-wrap mt-4">
                                <Button tag={Link as React.ElementType} to={() => formUrl(id)} color="add" className="btn-label mt-2">
                                    <i className="mdi mdi-plus align-bottom label-icon align-middle fs-16 me-2"></i>
                                    Dodaj
                                </Button>
                                {lastFormSubmission && (
                                    <>
                                        <Button onClick={() => handlePrint(lastFormSubmission?.id)} color="warning" className="btn-label mx-2 mt-2">
                                            <i className="mdi mdi-file-document-multiple-outline align-bottom label-icon align-middle fs-16 me-2"></i>
                                            Ispis
                                        </Button>
                                        <Button
                                            tag={Link as React.ElementType}
                                            to={formUrl(id) + `&edit=true&id=${lastFormSubmission?.id}`}
                                            color="info"
                                            className="btn-label mt-2"
                                        >
                                            <i className="mdi mdi-pencil-outline align-bottom label-icon align-middle fs-16 me-2"></i>
                                            Izmijeni
                                        </Button>
                                    </>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} xxl={8}>
                    <Card>
                        <CardHeader>
                            <h5 className="mb-0 me-4">Povijest izmjena</h5>
                        </CardHeader>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                tableActions={undefined}
                                data={formList || []}
                                isGlobalFilter={false}
                                isAddUserList={false}
                                isLoading={fetchingForms}
                                handleDataFetch={handleDataFetch}
                                totalCount={formData?.pagination?.totalItems}
                                pageCount={formData?.pagination?.totalPages}
                                currentPage={analysisParams.page}
                                totalPages={formData?.pagination?.totalPages}
                                theadClass={'dmn-table-header'}
                                tableHash={'inDepthAnalysis'}
                                hasColumnSelection={false}
                                hasPageSizeSelect={false}
                                permissionName={'none'}
                                searchCriteria={undefined}
                                handleRowClick={undefined}
                            />
                        </CardBody>
                    </Card>
                    {contactType && <ContactsConnectedToCompany />}
                </Col>
            </Row>
            {printModalOpen && (
                <InDepthAnalysisPdfModal
                    toggle={handlePrintModal}
                    iframeSrc={pdfData}
                    isFetching={fetchingPdf}
                    isError={isError}
                    isOpen={printModalOpen}
                />
            )}
        </>
    )
}

export default InDepthAnalysisList
