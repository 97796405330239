import * as Yup from 'yup'
import { documentFields } from './fields'

// data can be documentData (add document) or sideBarData (edit document)
export const documentValidation = (data) => {
    const isEdit = data?.id ? true : false

    let documentProjectId, documentPropertyId, documentContactId

    if (data?.module === 'project') {
        documentProjectId = data.internalAppId ?? data.parentElement?.element?.internalAppId
    }

    if (data?.module === 'property') {
        documentPropertyId = data.internalAppId ?? data.parentElement?.element?.internalAppId
    }

    if (data?.module === 'contact') {
        documentContactId = data.internalAppId ?? data.parentElement?.element?.internalAppId
    }

    return {
        initialValues: {
            ...(isEdit && { id: data?.id ?? '' }),
            ...(isEdit && { file_description: data?.translations['hr']?.fileDescription ?? '' }),
            [documentFields.DOCUMENT_PROJECT_ID]: documentProjectId ?? '',
            [documentFields.DOCUMENT_PROPRETY_ID]: documentPropertyId ?? '',
            [documentFields.DOCUMENT_CONTACT_ID]: documentContactId ?? '',
            [documentFields.DOCUMENT_TYPE]: data?.documentType?.id?.toString() ?? '',
            [documentFields.DOCUMENT_CREATION_DATE]: data?.documentCreationDate ?? '',
            [documentFields.DOCUMENT_VALIDITY_DATE]: data?.documentValidityDate ?? '',
            [documentFields.FILE_SYNC]: data?.fileSync ?? false,
        },
        validationSchema: Yup.object({}),
    }
}
