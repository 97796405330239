import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainer from '../../../Components/Table/TableContainer'
import { RowData } from '../globalTypes'
import { AgentRevenueReportProps } from './types'
import { formatNumber, getRevenueOveralls } from './utils'

export const AgentRevenueReportTable = (props: AgentRevenueReportProps) => {
    const { t } = useTranslation()

    const { reportsData, reportsFetching } = props
    const reports = useMemo(() => reportsData?.data || [], [reportsData])
    const months = useMemo(() => reportsData?.timePeriod || [], [reportsData])

    const columns = useMemo(() => {
        const agentColumn = {
            Header: t('app.common.agent'),
            id: 'agent',
            accessor: (row: RowData) => `${row.agent?.firstName} ${row.agent?.lastName}`,
            filterable: false,
        }

        const monthColumns = months?.map(({ month, monthName, year }) => ({
            Header: monthName,
            id: `${month}/${year}`,
            accessor: (row: RowData) => {
                const revenue = row.revenueTotals?.find((rev) => rev?.month === month)
                if (!revenue) return null

                return (
                    <div className="d-flex gap-2 justify-content-center">
                        {revenue.totalSale != null && <span>{formatNumber(revenue.totalSale)}</span>}
                        {revenue.totalRent != null && <span className="text-danger">{formatNumber(revenue.totalRent)}</span>}
                    </div>
                )
            },
            filterable: false,
        }))

        const totalColumn = {
            Header: t('app.common.total'),
            id: 'total_by_agent',
            accessor: (row: RowData) => <div className="fw-bolder text-center">{formatNumber(row?.agentGrandTotal || 0)}</div>,
            filterable: false,
        }

        return [agentColumn, ...monthColumns, totalColumn]
    }, [months, t])

    const tableFooter = useMemo(() => {
        const { monthOveralls, grandOverall } = getRevenueOveralls(reports, months)

        return (
            <tr>
                <td>{t('app.common.overall')}</td>
                {monthOveralls.map((overall, i) => (
                    <td key={i} className="text-center">
                        {formatNumber(overall.count)}
                    </td>
                ))}
                <td className="fw-bolder text-center">{formatNumber(grandOverall)}</td>
            </tr>
        )
    }, [reports, months, t])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            tableFooter={tableFooter}
            tableHash={'reports_agent-revenues'}
            hidePagination={true}
            {...({} as any)} // temporary fix
        />
    )
}
