import { groupByFieldValues } from '../utils'
import { AgentContactStatusData, AgentContactStatusTotal } from './types'

// "Sveukupno" row
export const getContactStatusOveralls = (reports: AgentContactStatusData[], contactStatuses: AgentContactStatusTotal[]) => {
    if (!reports || !contactStatuses) return { contactStatusOveralls: [], grandOverall: 0 }

    const groupedData = groupByFieldValues(reports, 'contactStatusTotals', 'statusId')

    const contactStatusOveralls = contactStatuses.map((obj) => {
        const totalsByStatus = groupedData[obj.statusId] as AgentContactStatusTotal[]

        const overall = totalsByStatus?.reduce((acc, curr) => acc + curr.total, 0) || 0

        return {
            id: obj.statusId,
            name: obj.name,
            count: overall,
        }
    })

    const grandOverall = contactStatusOveralls.reduce((acc, curr) => acc + curr.count, 0)

    return { contactStatusOveralls, grandOverall }
}
export const getGroupData = (contactStatusTotal: AgentContactStatusTotal, agentName: string) => {
    const id = contactStatusTotal.statusId
    const value = contactStatusTotal.total
    const newValueObj = {
        id,
        name: contactStatusTotal.name,
        [agentName]: value,
    }

    return { id, value, newValueObj }
}
