import QueryString from 'qs'
import { apiReq } from '..'
import { ReportCriteria, ReportType } from './types/globalTypes'
const formatCriteria = (criteria: ReportCriteria) => {
    const { dateFrom = '', dateTo = '', agents = [], activityTypes = [], contactStatuses = [], propertyStatuses = [], dataSources = [] } = criteria

    return {
        dateFrom,
        dateTo,
        agent: agents.map((item) => item.value),
        activityType: activityTypes.map((item) => item.value),
        dataSource: dataSources.map((item) => item.value),
        contactStatus: contactStatuses.map((item) => item.value),
        propertyStatus: propertyStatuses.map((item) => (item.value === '3' ? 'sale' : 'rent')),
    }
}
const formatUrlParam = (type: ReportType) => {
    const urlMapping: Record<string, string> = {
        'agent-activities': 'activities',
        'agent-revenues': 'revenues',
        'agent-transactions': 'transactions',
        'agent-contact-status': 'contact-status',
        'data-source-contacts': 'contacts',
        'data-source-properties': 'realized-properties',
    }

    return urlMapping[type]
}

export const getReportByType = async (type: ReportType, criteria: ReportCriteria) => {
    if (!type) return

    const typeParam = formatUrlParam(type)
    const formattedCriteria = formatCriteria(criteria)
    const url = `v1/report/${typeParam}`

    const response = await apiReq(url, {
        params: { criteria: formattedCriteria },
        paramsSerializer: (params: ReportCriteria) => {
            return QueryString.stringify(params)
        },
    })

    return response.data
}
