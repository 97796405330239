import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { FormattedData } from '../globalTypes'
import { formatGroupedData, getTotals, handleChartColors, handleChartHeight } from '../utils'
import { PropertyReportProps, PropertyTotal } from './types'
import { getGroupData, getPropertyOveralls } from './utils'

const PropertyReportChart = (props: PropertyReportProps) => {
    const { t } = useTranslation()
    const overallLabel = t('app.common.overall')
    const totalLabel = t('app.common.total')

    const { reportsData } = props
    const reports = useMemo(() => reportsData?.data || [], [reportsData]) 
    const months = useMemo(() => reportsData?.timePeriod || [], [reportsData])

    const formattedData = formatGroupedData({
        data: reports,
        dataKey: 'dataSource',
        handleGroupData: (contactTotal: PropertyTotal, dataSourceName: string) => getGroupData(contactTotal, dataSourceName),
    }) as FormattedData[]

    const { monthOveralls } = useMemo(() => getPropertyOveralls(reports, months), [reports, months])

    // adds month overalls data ("Sveukupno") to formattedData array
    const dataWithOveralls = useMemo(() => {
        return formattedData.map((data) => {
            const monthOverallObj = monthOveralls.find((month) => month.id === data.id)

            return {
                ...data,
                monthName: monthOverallObj?.monthName,
                [overallLabel]: monthOverallObj?.count,
            }
        })
    }, [formattedData, monthOveralls, overallLabel])

    const totalsObj = useMemo(() => {
        const propertyTotals = getTotals(reports)
        const totalOverall = monthOveralls.reduce((acc, curr) => acc + curr.count, 0)

        return {
            id: 'Ukupno',
            monthName: totalLabel,
            ...propertyTotals,
            [overallLabel]: totalOverall,
        }
    }, [reports, monthOveralls, totalLabel, overallLabel])

    const chartData = [totalsObj, ...dataWithOveralls]

    const numObjects = chartData.length
    const numFields = Object.keys(chartData[0]).length - 2 // exclude id, monthName
    const [totalHeight, barSize, barGap] = handleChartHeight(numObjects, numFields)

    const { overallColor, reportColors } = handleChartColors(reports.length)

    return (
        <div style={{ height: `${totalHeight}px` }} className="w-full">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout="vertical"
                    data={chartData}
                    barSize={barSize}
                    barGap={barGap}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 28,
                        bottom: 50,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="monthName" type="category" tickMargin={10} />
                    <Tooltip formatter={(value: number) => (value > 0 ? value : 0)} contentStyle={{ fontSize: '11px' }} />
                    <Legend iconSize={16} />

                    <Bar key="overall" dataKey={overallLabel} fill={overallColor}>
                        <LabelList
                            dataKey={overallLabel}
                            position="right"
                            fill="#000"
                            fontSize={12}
                            formatter={(value: number) => (value > 0 ? value : '')}
                        />
                    </Bar>

                    {reports?.map(({ dataSource }, index) => {
                        if (!dataSource) return null

                        return (
                            <Bar key={dataSource.id} dataKey={dataSource.name} fill={reportColors[index]}>
                                <LabelList
                                    dataKey={dataSource.name}
                                    position="right"
                                    fill="#000"
                                    fontSize={12}
                                    formatter={(value: number) => (value > 0 ? value : '')}
                                />
                            </Bar>
                        )
                    })}
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default PropertyReportChart
