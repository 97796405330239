import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { CellProps } from './TableColumns.types'

const TableColumns = () => {
    const Link = RouterLink as unknown as React.FC<{
        children: React.ReactNode
        title: string
        to: string
        className: string
        onClick?: () => void | string
    }>

    const { t } = useTranslation()

    const columns = [
        {
            Header: t('app.common.id'),
            id: `id`,
            Cell: (cellProps: CellProps) => <span>{cellProps.row.original?.contactId}</span>,
            filterable: true,
        },
        {
            Header: 'Ime i prezime',
            id: `name`,
            Cell: (cellProps: CellProps) => (
                <span>
                    {cellProps.row.original?.firstName} {cellProps.row.original?.lastName}
                </span>
            ),
            filterable: true,
        },
        {
            Header: 'Popunjena dubinska analiza',
            id: `cddFormSubmissionId`,
            Cell: (cellProps: CellProps) => (cellProps.row.original?.cddFormSubmissionId ? 'DA' : 'NE'),
        },
        {
            Header: 'Titula',
            id: `jobTitle`,
            Cell: (cellProps: CellProps) => cellProps.row.original?.jobTitle,
            filterable: true,
        },
        {
            id: 'actions',
            thDivClass: 'text-center',
            Cell: (cellProps: CellProps) => {
                return (
                    <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                        <li className="list-inline-item edit">
                            <Link
                                title={t('button.edit')}
                                to={`/contacts/${cellProps.row.original?.contactId}`}
                                className="text-info d-inline-block edit-item-btn"
                            >
                                <i className="mdi mdi-pencil-outline fs-16"></i>
                            </Link>
                        </li>
                    </ul>
                )
            },
        },
    ]

    return columns
}

export default TableColumns
