import { useTranslation } from 'react-i18next'
import { Badge } from 'reactstrap'
import AppPermissionsSwitcher from '../../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import BackButton from '../../../../Components/BackButton/BackButton'
import { propertyResponseFields } from '../../../../common/response/property'
import { formatNumberWithTwoDecimals } from '../../../../utils/general'
import { renderAreaUnit } from '../../../../utils/property'
import { useSelector } from 'react-redux'
import { selectApplicationMarket } from '../../../../store/applicationSettings'
import { HeaderPropertyViewTypes, PropertyValue } from './HeaderPropertyView.types'
import { ViewDataValue } from '../PropertyView.types'

const HeaderPropertyView = (props: HeaderPropertyViewTypes) => {
    const { t } = useTranslation()
    const { property, currency, areaUnit, locationData, module } = props
    const {
        internalAppId,
        internalId,
        propertyCondition,
        propertyDescriptions,
        propertyType,
        propertyService,
        propertyLabel,
        propertyStatus,
        propertyTransaction,
    } = props.property

    // TODO: uncomment after sagamiddleware refactor, remove useSelector
    // const isSRBVersion = useCheckAppMarket(3)

    const applicationMarket = useSelector((state) => selectApplicationMarket(state))
    const SRB_MARKET_ID = 3
    const isSRBVersion = applicationMarket?.id === SRB_MARKET_ID

    const infoWithIcons = [
        {
            id: propertyResponseFields.PRICE,
            icon: 'mdi mdi-currency-eur',
            value: property?.price ? `${formatNumberWithTwoDecimals(property?.price)}${currency}` : '',
        },
        {
            id: propertyResponseFields.AREA,
            icon: 'mdi mdi-floor-plan',
            value: property?.area ? (
                <>
                    {property?.area}
                    {renderAreaUnit(areaUnit)}
                </>
            ) : (
                ''
            ),
        },
        {
            id: propertyResponseFields.BEDROOMS_NO,
            icon: 'mdi mdi-bed-double-outline',
            value: property?.bedroomsNo,
        },
        {
            id: `${propertyResponseFields.FLOOR_NO}_${propertyResponseFields.FLOORS_TOTAL_NO}`,
            icon: 'mdi mdi-domain',
            value:
                property?.floorNo && property?.floorsTotalNo ? (
                    <>
                        {property?.floorNo}/{property?.floorsTotalNo}
                    </>
                ) : (
                    ''
                ),
        },
    ]

    const renderBadge = (propertyValue: PropertyValue, id: number) => {
        const filter = propertyValue?.find((x) => x.id === id)

        return (
            filter && (
                <Badge color="edit" className="fs-12 fw-medium me-3">
                    {filter.name?.toUpperCase()}
                </Badge>
            )
        )
    }

    return (
        <div className="page-title-box ps-0">
            <div className="d-sm-flex align-items-center mt-3">
                <BackButton className="me-3" />
                <div>
                    <h4 className="mb-sm-0">{propertyType?.name}</h4>
                    <div className="d-flex align-items-center">
                        <p className="fw-medium mb-0">{propertyService?.name}</p>
                        <p className="fw-light mb-0 ms-4">
                            <Badge color="favorite" className="fs-12 fw-medium">
                                {propertyStatus?.name}
                            </Badge>
                        </p>
                        {internalAppId && (
                            <p className="fw-light mb-0 ms-4">
                                <span className="fw-medium">{t('app.common.id')}:</span> {internalAppId}
                            </p>
                        )}
                        {internalId && (
                            <p className="fw-light mb-0 ms-4">
                                <span className="fw-medium">{t('app.common.agencyId')}:</span> {internalId}
                            </p>
                        )}
                        {propertyLabel && (
                            <AppPermissionsSwitcher permission="ViewData" data={locationData} module={module}>
                                {({ newData }: { newData: ViewDataValue[] }) => {
                                    const hasPermission = newData?.some((obj) => obj.id === 'propertyLabel')
                                    return (
                                        hasPermission && (
                                            <p className="fw-light mb-0 ms-4">
                                                <span className="fw-medium">{t('view.property.header.propertyLabel')}</span> {propertyLabel}
                                            </p>
                                        )
                                    )
                                }}
                            </AppPermissionsSwitcher>
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center mt-2">
                {infoWithIcons.map((item, index) => {
                    if (item.value) {
                        return (
                            <div className="d-flex align-items-center me-3" key={index}>
                                <div className="me-2">
                                    <i className={`${item.icon} text-edit fs-22`} />
                                </div>
                                <div className="fs-15 text-black">{item.value}</div>
                            </div>
                        )
                    }
                    return null
                })}
                {renderBadge(propertyCondition, 1)}
                {renderBadge(propertyDescriptions, 40)}
                {isSRBVersion && propertyTransaction?.propertyLandRegistryStatus?.id === 6 && (
                    <Badge color="warning" className="fs-12 fw-medium me-3">
                        {propertyTransaction?.propertyLandRegistryStatus?.name?.toUpperCase()}
                    </Badge>
                )}
            </div>
        </div>
    )
}

export default HeaderPropertyView
