
import { useParams, useLocation } from "react-router-dom"
import { getQueryData } from "../../api/helpers"
import { useSelector } from "react-redux"
import { selectContact } from "../../store/contacts"
import { selectCompany } from "../../store/companies"

// returns internalAppId for DocumentForm component
// TODO: refactor when RQ is added fot Contacts & Companies
export const useInternalAppId = () => {
    const { id } = useParams()
    const { pathname } = useLocation()
    const module = pathname.split('/')[1]

    const contactCompanyArr = ['contacts', 'companies']
    const isContactCompany = contactCompanyArr.includes(module)

    const config = {
        projects: getQueryData(['projects', id]),
        properties: getQueryData(['properties', id]),
        contacts: useSelector(state => selectContact(state)),
        companies: useSelector(state => selectCompany(state))
    }

    return isContactCompany ? config[module].internalAppId : config[module]?.item?.internalAppId
}