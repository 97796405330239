import i18next from 'i18next'
import { fetchProjectsAsyncInput } from '../../../store/projects'
import { renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../../utils/property'
import requestFields from '../../constants/requestFields'
import {
    baseAsyncSelectInput,
    baseCheckboxInput,
    baseDateInput,
    baseMultiLanguageEditorInput,
    baseMultiLanguageTextInput,
    baseMultiSelectInput,
    baseNumberInput,
    baseNumeralInput,
    basePriceInput,
    baseSelectInput,
    baseTextInput,
    emptyLabel,
    getOwnerFieldType,
} from '../fieldTypes'
import {
    propertyDetailSearchFields,
    propertyOfferPrintFields,
    realEstateDescriptionFields,
    realEstateGeneralFields,
    realEstateMaterials3DVideoFields,
} from './fields'
import { HRtransactionFieldTypes } from './transactionFieldsTypes/hr'
import { SLOtransactionFieldTypes } from './transactionFieldsTypes/slo'

const realEstateGeneralFieldTypes = (isEdit, appSettings, isOffice = false) => {
    const { t } = i18next

    return {
        [realEstateGeneralFields.EMPTY]: {
            ...emptyLabel,
            name: realEstateGeneralFields.EMPTY,
            label: '',
            colProps: {
                sm: 0,
                xxl: 8,
            },
        },
        [realEstateGeneralFields.EMPTY_COLUMN]: {
            ...emptyLabel,
            name: realEstateGeneralFields.EMPTY_COLUMN,
            label: '',
            colProps: {
                sm: 0,
                xxl: 12,
            },
        },
        [realEstateGeneralFields.ID]: {
            ...baseTextInput,
            name: realEstateGeneralFields.ID,
            label: t('app.common.id'),
            disabled: true,
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [realEstateGeneralFields.INTERNAL_ID]: {
            ...baseTextInput,
            name: realEstateGeneralFields.INTERNAL_ID,
            label: t('app.common.agencyId'),
            // disabled: isEdit,
            tooltip: t('form.property.general.agencyId.tooltip'),
            colProps: {
                sm: 12,
            },
        },
        [realEstateGeneralFields.PROPERTY_SERVICE_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.PROPERTY_SERVICE_ID,
            label: t('app.common.service'),
            disabled: isEdit,
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [realEstateGeneralFields.PROPERTY_TYPE_ID]: {
            ...baseSelectInput,
            colProps: {
                sm: 12,
            },
            name: realEstateGeneralFields.PROPERTY_TYPE_ID,
            label: t('app.common.property.type'),
            disabled: isEdit,
            required: true,
        },
        [realEstateGeneralFields.PROPERTY_SUB_TYPE_ID]: {
            ...baseSelectInput,
            colProps: {
                sm: 12,
            },
            name: realEstateGeneralFields.PROPERTY_SUB_TYPE_ID,
            label: t('app.common.property.subtype'),
            // disabled: isEdit,
            required: true,
        },
        [realEstateGeneralFields.PROPERTY_LAND_USAGE_ID]: {
            ...baseSelectInput,
            colProps: {
                sm: 12,
            },
            name: realEstateGeneralFields.PROPERTY_LAND_USAGE_ID,
            label: t('app.common.property.landUsage'),
        },
        [realEstateGeneralFields.PROPERTY_PRIMARY_USAGE_ID]: {
            ...baseSelectInput,
            colProps: {
                sm: 12,
            },
            name: realEstateGeneralFields.PROPERTY_PRIMARY_USAGE_ID,
            label: t('app.common.property.primaryUsage'),
        },
        ...getOwnerFieldType(realEstateGeneralFields.OWNER_ID),
        [realEstateGeneralFields.PROJECT_ID]: {
            ...baseAsyncSelectInput,
            colProps: {
                sm: 12,
            },
            action: fetchProjectsAsyncInput,
            noLimit: true,
            criteria: ['search'],
            name: realEstateGeneralFields.PROJECT_ID,
            label: t('app.common.project.label'),
        },
        [requestFields.PROPERTY_SUPPLY_SOURCE_ID]: {
            ...baseSelectInput,
            colProps: {
                sm: 12,
            },
            name: requestFields.PROPERTY_SUPPLY_SOURCE_ID,
            label: t('app.common.offerSource'),
        },
        [realEstateGeneralFields.CONTRACT_NO]: {
            ...baseTextInput,
            colProps: {
                sm: 12,
            },
            name: realEstateGeneralFields.CONTRACT_NO,
            label: t('app.common.contractNumber'),
        },
        [realEstateGeneralFields.CONTRACT_DATE]: {
            ...baseDateInput,
            colProps: {
                sm: 12,
            },
            name: realEstateGeneralFields.CONTRACT_DATE,
            label: t('app.common.contractDate'),
        },
        [realEstateGeneralFields.PROPERTY_RECEIPT_DATE]: {
            ...baseDateInput,
            colProps: {
                sm: 12,
            },
            name: realEstateGeneralFields.PROPERTY_RECEIPT_DATE,
            label: t('table.header.properties.receiptDate'),
        },
        [realEstateGeneralFields.AVAILABLE_FROM_DATE]: {
            ...baseDateInput,
            colProps: {
                sm: 12,
            },
            name: realEstateGeneralFields.AVAILABLE_FROM_DATE,
            label: t('app.common.property.availableFrom'),
        },
        [realEstateGeneralFields.COUNTRY_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.COUNTRY_ID,
            label: t('app.common.country'),
            required: true,
        },
        [realEstateGeneralFields.COUNTY_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.COUNTY_ID,
            label: t('app.common.county'),
            required: true,
            disabled: !isEdit,
        },
        [realEstateGeneralFields.CITY_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.CITY_ID,
            label: t('app.common.cityMunicipality'),
            required: true,
            disabled: !isEdit,
        },
        [realEstateGeneralFields.WIDER_AREA_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.WIDER_AREA_ID,
            label: t('app.common.widerArea'),
            required: true,
            disabled: !isEdit,
        },
        [realEstateGeneralFields.QUARTER_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.QUARTER_ID,
            label: t('app.common.quarter'),
            disabled: !isEdit,
        },
        [realEstateGeneralFields.ISLAND_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.ISLAND_ID,
            label: t('app.common.island'),
            disabled: !isEdit,
        },
        [realEstateGeneralFields.STREET]: {
            ...baseTextInput,
            name: realEstateGeneralFields.STREET,
            label: t('app.common.street'),
        },
        [realEstateGeneralFields.STREET_NUMBER]: {
            ...baseTextInput,
            name: realEstateGeneralFields.STREET_NUMBER,
            label: t('app.common.streetNumber'),
        },
        [realEstateGeneralFields.STREET_SYNC]: {
            ...baseCheckboxInput,
            colProps: {
                sm: 12,
                xxl: 2,
                className: 'd-flex align-items-center',
            },
            name: realEstateGeneralFields.STREET_SYNC,
            label: t('form.common.streetSync.label'),
            tooltip: t('form.common.streetSync.tooltip'),
        },
        [realEstateGeneralFields.PROPERTY_LABEL]: {
            ...baseTextInput,
            name: realEstateGeneralFields.PROPERTY_LABEL,
            label: t('app.common.property.designation'),
            tooltip: t('form.property.general.designation.tooltip'),
        },
        [realEstateGeneralFields.PROPERTY_POSITION_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.PROPERTY_POSITION_ID,
            label: t('app.common.property.position'),
        },
        [realEstateGeneralFields.PRICE]: {
            ...basePriceInput,
            name: realEstateGeneralFields.PRICE,
            label: t('app.common.price', { currency: renderCurrency(appSettings?.currency) }),
            tooltip: t('app.common.price.tooltip'),
            required: true,
        },
        [realEstateGeneralFields.PRICE_INITIAL]: {
            ...basePriceInput,
            name: realEstateGeneralFields.PRICE_INITIAL,
            label: t('app.common.property.initialPrice', { currency: renderCurrency(appSettings?.currency) }),
            required: false,
        },
        [realEstateGeneralFields.PRICE_CLIENT]: {
            ...basePriceInput,
            name: realEstateGeneralFields.PRICE_CLIENT,
            label: t('app.common.property.priceClient', { currency: renderCurrency(appSettings?.currency) }),
        },
        [realEstateGeneralFields.PRICE_M2]: {
            ...basePriceInput,
            name: realEstateGeneralFields.PRICE_M2,
            label: t('app.common.pricePerUnit', {
                currencyWithUnit: renderCurrencyWithUnit(appSettings?.currency, appSettings?.areaUnit),
            }),
        },
        [realEstateGeneralFields.PRICE_OLD]: {
            ...basePriceInput,
            name: realEstateGeneralFields.PRICE_OLD,
            label: t('app.common.property.oldPrice', { currency: renderCurrency(appSettings?.currency) }),
            tooltip: t('app.common.property.oldPrice.tooltip'),
        },
        [realEstateGeneralFields.VAT_INCLUDED]: {
            ...baseCheckboxInput,
            colProps: {
                sm: 12,
                xxl: 4,
                className: 'd-flex align-items-center',
            },
            name: realEstateGeneralFields.VAT_INCLUDED,
            label: t('app.common.property.vatIncluded'),
        },
        [realEstateGeneralFields.VAT_NOT_INCLUDED]: {
            ...baseCheckboxInput,
            colProps: {
                sm: 12,
                xxl: 4,
                className: 'd-flex align-items-center',
            },
            name: realEstateGeneralFields.VAT_NOT_INCLUDED,
            label: t('app.common.property.vatNotIncluded'),
        },
        [realEstateGeneralFields.UTILITIES_INCLUDED]: {
            ...baseCheckboxInput,
            colProps: {
                sm: 12,
                xxl: 4,
                className: 'd-flex align-items-center',
            },
            name: realEstateGeneralFields.UTILITIES_INCLUDED,
            label: t('form.property.general.utilitiesIncluded'),
        },
        [realEstateGeneralFields.EXCHANGE_POSSIBLE]: {
            ...baseCheckboxInput,
            colProps: {
                sm: 12,
                xxl: 4,
                className: 'd-flex align-items-center',
            },
            name: realEstateGeneralFields.EXCHANGE_POSSIBLE,
            label: t('form.property.general.exchangePossible'),
        },
        [realEstateGeneralFields.PRICE_ON_REQUEST]: {
            ...baseCheckboxInput,
            colProps: {
                sm: 12,
                xxl: 4,
                className: 'd-flex align-items-center',
            },
            name: realEstateGeneralFields.PRICE_ON_REQUEST,
            label: t('app.common.property.priceOnRequest'),
            tooltip: t('form.property.general.priceOnRequest.tooltip'),
        },
        [realEstateGeneralFields.MAINTENCE_COSTS]: {
            ...basePriceInput,
            name: realEstateGeneralFields.MAINTENCE_COSTS,
            colProps: {
                sm: 7,
                className: 'pe-0',
            },
            // label: <>{t('Pričuva')} {renderCurrency(appSettings.currency)} ili {renderCurrencyWithUnit(appSettings?.currency, appSettings?.areaUnit)}</>,
            // tooltip: 'Zajednički troškovi održavanja zgrade'
        },
        [realEstateGeneralFields.MAINTENCE_COSTS_TYPE]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.MAINTENCE_COSTS_TYPE,
            isClearable: false,
            colProps: {
                sm: 5,
            },
        },
        [realEstateGeneralFields.COMMON_MAINTENCE_COSTS]: {
            ...basePriceInput,
            name: realEstateGeneralFields.COMMON_MAINTENCE_COSTS,
            // label: `Zajednički troškovi održavanja ${renderCurrency(appSettings.currency)}`,
            colProps: {
                sm: 7,
                className: 'pe-0',
            },
        },
        [realEstateGeneralFields.COMMON_MAINTENCE_COSTS_TYPE]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.COMMON_MAINTENCE_COSTS_TYPE,
            isClearable: false,
            colProps: {
                sm: 5,
            },
        },
        [realEstateGeneralFields.COMMON_UTILITY_COSTS]: {
            ...basePriceInput,
            name: realEstateGeneralFields.COMMON_UTILITY_COSTS,
            // label: <>Zajednički troškovi/Režije {renderCurrency(appSettings.currency)} ili {renderCurrencyWithUnit(appSettings?.currency, appSettings?.areaUnit)}</>,
            colProps: {
                sm: 7,
                className: 'pe-0',
            },
        },
        [realEstateGeneralFields.COMMON_UTILITY_COSTS_TYPE]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.COMMON_UTILITY_COSTS_TYPE,
            isClearable: false,
            colProps: {
                sm: 5,
            },
        },
        [realEstateGeneralFields.PARKING_SPACE_PRICE]: {
            ...basePriceInput,
            name: realEstateGeneralFields.PARKING_SPACE_PRICE,
            label: t('form.property.general.parkingSpacePrice', { currency: renderCurrency(appSettings?.currency) }),
        },
        [realEstateGeneralFields.MORTGAGE_INFO]: {
            ...basePriceInput,
            name: realEstateGeneralFields.MORTGAGE_INFO,
            label: t('app.common.property.mortgage'),
        },
        [realEstateGeneralFields.PROPERTY_HOUSE_TYPE_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.PROPERTY_HOUSE_TYPE_ID,
            label: t('app.common.property.houseType'),
        },
        [realEstateGeneralFields.AREA]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.AREA,
            label: t('app.common.area', { areaUnit: renderAreaUnit(appSettings?.areaUnit) }),
            required: true,
        },
        [realEstateGeneralFields.AREA_NETT]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.AREA_NETT,
            label: t('form.property.general.areaNett', { areaUnit: renderAreaUnit(appSettings?.areaUnit) }),
            tooltip: t('form.property.general.areaNett.tooltip'),
        },
        [realEstateGeneralFields.AREA_GARDEN]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.AREA_GARDEN,
            label: t('app.common.property.yard', { areaUnit: renderAreaUnit(appSettings?.areaUnit) }),
        },
        [realEstateGeneralFields.AREA_STORAGE]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.AREA_STORAGE,
            label: t('form.property.general.areaStorage', { areaUnit: renderAreaUnit(appSettings?.areaUnit) }),
        },
        [realEstateGeneralFields.PLOT_AREA]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.PLOT_AREA,
            label: t('app.common.property.areaPlot', { areaUnit: renderAreaUnit(appSettings?.areaUnit) }),
        },
        [realEstateGeneralFields.PLOT_WIDTH]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.PLOT_WIDTH,
            label: t('app.common.property.plotWidth'),
        },
        [realEstateGeneralFields.PLOT_LENGTH]: {
            ...baseNumberInput,
            name: realEstateGeneralFields.PLOT_LENGTH,
            label: t('app.common.property.plotLength'),
        },
        [realEstateGeneralFields.CONSTRUCTION_COEFFICIENT]: {
            ...baseTextInput,
            name: realEstateGeneralFields.CONSTRUCTION_COEFFICIENT,
            label: t('app.common.property.constructionCoefficient'),
        },
        [realEstateGeneralFields.CONSTRUCTION_FLOORS_NO]: {
            ...baseTextInput,
            name: realEstateGeneralFields.CONSTRUCTION_FLOORS_NO,
            label: t('app.common.property.constructionFloorNo'),
        },
        [realEstateGeneralFields.MAX_CONTRUCTION_AREA]: {
            ...baseTextInput,
            name: realEstateGeneralFields.MAX_CONTRUCTION_AREA,
            label: t('form.property.general.maxConstructionArea'),
        },
        [realEstateGeneralFields.PROPERTY_ROOMS_NO_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.PROPERTY_ROOMS_NO_ID,
            label: t('app.common.property.numberOfRooms'),
            required: true,
        },
        [realEstateGeneralFields.PROPERTY_ROOMS_TYPE_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.PROPERTY_ROOMS_TYPE_ID,
            label: t('app.common.property.roomsType'),
        },
        [realEstateGeneralFields.BEDROOMS_NO]: {
            ...baseTextInput,
            name: realEstateGeneralFields.BEDROOMS_NO,
            label: t('app.common.bedrooms'),
            tooltip: t('app.common.bedrooms.tooltip'),
            required: false,
        },
        [realEstateGeneralFields.HOUSING_UNITS_NUMBER]: {
            ...baseTextInput,
            name: realEstateGeneralFields.HOUSING_UNITS_NUMBER,
            tooltip: t('app.common.property.housingUnitsNumber.tooltip'),
            label: t('app.common.property.housingUnitsNumber'),
        },
        [realEstateGeneralFields.ROOMS_NO]: {
            ...baseTextInput,
            name: realEstateGeneralFields.ROOMS_NO,
            required: true,
            tooltip: t(`form.property.general.${isOffice ? 'roomsNoOffice' : 'roomsNo'}.tooltip`),
            label: t('form.property.general.roomsNo'),
        },
        [realEstateGeneralFields.BED_NO]: {
            ...baseTextInput,
            name: realEstateGeneralFields.BED_NO,
            tooltip: t('app.common.property.bedsNumber.tooltip'),
            label: t('app.common.property.bedsNumber'),
        },
        [realEstateGeneralFields.PARKING_SPACE_NUMBER]: {
            ...baseTextInput,
            name: realEstateGeneralFields.PARKING_SPACE_NUMBER,
            label: t('app.common.property.parkingSpaceNumber'),
        },
        [realEstateGeneralFields.BATHROOM_TOILET_NO]: {
            ...baseTextInput,
            name: realEstateGeneralFields.BATHROOM_TOILET_NO,
            tooltip: t('app.common.property.bathroomToiletNumber.tooltip'),
            label: t('app.common.property.bathroomToiletNumber'),
        },
        [realEstateGeneralFields.BATHROOM_NO]: {
            ...baseTextInput,
            name: realEstateGeneralFields.BATHROOM_NO,
            tooltip: t('app.common.property.bathroomNoToiletNumber.tooltip'),
            label: t('app.common.property.bathroomNoToiletNumber'),
        },
        [realEstateGeneralFields.COMMON_BATHROOM_NO]: {
            ...baseTextInput,
            name: realEstateGeneralFields.COMMON_BATHROOM_NO,
            label: t('app.common.property.bathroomNumber'),
            tooltip: t('app.common.property.bathroomNumber.tooltip'),
        },
        [realEstateGeneralFields.TOILET_NO]: {
            ...baseTextInput,
            name: realEstateGeneralFields.TOILET_NO,
            tooltip: t('app.common.property.toiletNumber.tooltip'),
            label: t('app.common.property.toiletNumber'),
        },
        [realEstateGeneralFields.CEILING_HEIGHT]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.CEILING_HEIGHT,
            label: t('app.common.property.ceilingHeight'),
        },
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: {
            ...baseTextInput,
            name: realEstateGeneralFields.CONSTRUCTION_YEAR,
            label: t('app.common.property.constructionYear'),
        },
        [realEstateGeneralFields.ADAPTATION_YEAR]: {
            ...baseTextInput,
            name: realEstateGeneralFields.ADAPTATION_YEAR,
            label: t('app.common.property.adaptationYear'),
        },
        [realEstateGeneralFields.PROPERTY_FLOOR_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.PROPERTY_FLOOR_ID,
            required: true,
            label: t('app.common.numberOfFloors'),
        },
        [realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID,
            label: t('app.common.floorPosition'),
        },
        [realEstateGeneralFields.FLOOR_NO]: {
            ...baseTextInput,
            name: realEstateGeneralFields.FLOOR_NO,
            label: t('app.common.floor'),
            tooltip: t('form.property.general.floor.tooltip'),
        },
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: {
            ...baseTextInput,
            name: realEstateGeneralFields.FLOORS_TOTAL_NO,
            label: t('app.common.property.floorsTotal'),
        },
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_ORIENTATION_ID,
            label: t('app.common.property.orientation'),
        },
        [realEstateGeneralFields.PROPERTY_HEATING_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_HEATING_ID,
            label: t('app.common.heating'),
        },
        [realEstateGeneralFields.PROPERTY_CONDITION_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_CONDITION_ID,
            label: t('app.common.property.condition'),
        },
        [realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID,
            label: t('app.common.property.carpentry'),
        },
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID,
            label: t('app.common.property.publicTransportation'),
        },
        [realEstateGeneralFields.DISTANCE_SEA]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.DISTANCE_SEA,
            label: t('app.common.property.seaDistance'),
        },
        [realEstateGeneralFields.DISTANCE_RIVER]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.DISTANCE_RIVER,
            label: t('app.common.property.riverDistance'),
        },
        [realEstateGeneralFields.DISTANCE_BEACH]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.DISTANCE_BEACH,
            label: t('app.common.property.beachDistance'),
        },
        [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.DISTANCE_CITY_CENTRE,
            label: t('app.common.property.cityCentreDistance'),
        },
        [realEstateGeneralFields.DISTANCE_SCHOOL]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.DISTANCE_SCHOOL,
            label: t('app.common.property.schoolDistance'),
        },
        [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION,
            label: t('app.common.property.publicTransportationDistance'),
        },
        [realEstateGeneralFields.DISTANCE_PATHWAY]: {
            ...baseNumeralInput,
            name: realEstateGeneralFields.DISTANCE_PATHWAY,
            label: t('app.common.property.pathwayDistance'),
        },
        [realEstateGeneralFields.PROPERTY_SPECIAL_FEATURE_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_SPECIAL_FEATURE_ID,
            label: t('app.common.property.specialFeatures'),
        },
        [realEstateGeneralFields.PROPERTY_DESCRIPTION]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_DESCRIPTION,
            label: t('app.common.property.description'),
        },
        [realEstateGeneralFields.PROPERTY_UTILITY_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_UTILITY_ID,
            label: t('app.common.utility'),
        },
        [realEstateGeneralFields.PROPERTY_EQUIPMENT_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_EQUIPMENT_ID,
            label: t('app.common.property.equipment'),
        },
        [realEstateGeneralFields.PROPERTY_SPACE_ID]: {
            ...baseCheckboxInput,
            name: realEstateGeneralFields.PROPERTY_SPACE_ID,
            label: t('app.common.property.space'),
            tooltip: t('form.property.general.propertySpace.tooltip'),
        },
        [realEstateGeneralFields.SPACE_COUNT]: {
            ...baseTextInput,
            name: realEstateGeneralFields.SPACE_COUNT,
            label: t('form.contact.profile.spaceCount'),
        },
        [realEstateGeneralFields.SPACE_AREA]: {
            ...baseTextInput,
            name: realEstateGeneralFields.SPACE_AREA,
            label: t('app.common.spaceArea'),
        },
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID,
            label: t('app.common.energyEfficiency'),
        },
        [realEstateGeneralFields.PROPERTY_PERMIT_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_PERMIT_ID,
            label: t('app.common.property.permits'),
        },
        [realEstateGeneralFields.PROPERTY_ACCESS_ID]: {
            ...baseMultiSelectInput,
            name: realEstateGeneralFields.PROPERTY_ACCESS_ID,
            label: t('app.common.property.access'),
        },
        [realEstateGeneralFields.AGENCY_COMMISSION_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.AGENCY_COMMISSION_ID,
            label: t('app.common.agencyComission'),
            tooltip: t('form.common.agencyComission.tooltip'),
        },
        [realEstateGeneralFields.AGENT_ID]: {
            ...baseSelectInput,
            name: realEstateGeneralFields.AGENT_ID,
            label: t('app.common.agent'),
            required: true,
        },
    }
}

const realEstateDescriptionFieldTypes = (appSettings) => {
    const { t } = i18next

    return {
        [realEstateDescriptionFields.PROPERTY_TITLE]: {
            ...baseMultiLanguageTextInput(appSettings.languages),
            name: realEstateDescriptionFields.PROPERTY_TITLE,
            label: t('app.common.title'),
            description: t('form.property.description.title.description'),
            languageStyle: 'tabs',
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '108px' },
            },
            maxLenFeedback: 70,
            hint: t('form.property.description.title.hint'),
        },
        [realEstateDescriptionFields.PROPERTY_DESCRIPTION]: {
            ...baseMultiLanguageEditorInput(appSettings.languages),
            name: realEstateDescriptionFields.PROPERTY_DESCRIPTION,
            label: t('app.common.description'),
            languageStyle: 'tabs',
            colProps: {
                sm: 12,
            },
            maxLenFeedback: 2000,
            hint: t('form.property.description.description.hint'),
        },
    }
}

const propertyTransactionsFieldTypes = (appSettings, isRequiredAccordingToStatus) => {
    const isSloMarket = appSettings?.applicationMarket.name === 'Slovenija'

    const hrFields = HRtransactionFieldTypes(appSettings, isRequiredAccordingToStatus)
    const sloFields = SLOtransactionFieldTypes(appSettings, isRequiredAccordingToStatus)

    return isSloMarket ? sloFields : hrFields
}

const realEstaterealEstateMaterials3DVideoFieldTypes = () => {
    const { t } = i18next

    return {
        [realEstateMaterials3DVideoFields.VIRTUAL_WALK_URL]: {
            ...baseTextInput,
            name: realEstateMaterials3DVideoFields.VIRTUAL_WALK_URL,
            label: t('app.common.property.3DVideo.virtualWalk'),
            tooltip: t('form.property.3DVideo.virtualWalk.tooltip'),
            colProps: {
                sm: 12,
            },
        },
        [realEstateMaterials3DVideoFields.PROPERTY_VIDEO_URL]: {
            ...baseTextInput,
            name: realEstateMaterials3DVideoFields.PROPERTY_VIDEO_URL,
            label: t('app.common.video'),
            tooltip: t('form.property.3DVideo.property.tooltip'),
            colProps: {
                sm: 12,
            },
        },
    }
}

const propertyOfferPrintFieldTypes = () => {
    const { t } = i18next

    return {
        [propertyOfferPrintFields.OFFER_LOCALE]: {
            ...baseSelectInput,
            name: propertyOfferPrintFields.OFFER_LOCALE,
            label: t('modal.offerPrintMain.language'),
            colProps: {
                sm: 3,
            },
        },
        [propertyOfferPrintFields.AGENT_ID]: {
            ...baseSelectInput,
            name: propertyOfferPrintFields.AGENT_ID,
            label: t('app.common.agent'),
            required: true,
            colProps: {
                sm: 9,
            },
        },
        [propertyOfferPrintFields.CONTACT_ID]: {
            ...baseSelectInput,
            name: propertyOfferPrintFields.CONTACT_ID,
            label: t('app.common.contactNameSurname'),
            colProps: {
                sm: 12,
            },
        },
        [propertyOfferPrintFields.COMPANY_ID]: {
            ...baseSelectInput,
            name: propertyOfferPrintFields.COMPANY_ID,
            label: t('app.common.company.name'),
            colProps: {
                sm: 12,
            },
        },
        [propertyOfferPrintFields.OFFER_TEMPLATE]: {
            ...baseSelectInput,
            name: propertyOfferPrintFields.OFFER_TEMPLATE,
            label: t('modal.offerPrintMain.template'),
            colProps: {
                sm: 12,
            },
        },
        [propertyOfferPrintFields.EMAIL]: {
            ...baseTextInput,
            name: propertyOfferPrintFields.EMAIL,
            label: t('app.common.email.label'),
            colProps: {
                sm: 12,
            },
        },
    }
}

const propertyDetailSearchFieldTypes = () => {
    const { t } = i18next

    return {
        [propertyDetailSearchFields.INTERNAL_APP_ID]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.INTERNAL_APP_ID,
            label: t('app.common.propertyId'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.INTERNAL_ID]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.INTERNAL_ID,
            label: t('app.common.agencyId'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_SERVICE]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_SERVICE,
            label: t('app.common.service'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_STATUS]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_STATUS,
            label: t('app.common.status'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_TYPE]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_TYPE,
            label: t('app.common.property.type'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_SUB_TYPE]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_SUB_TYPE,
            label: t('app.common.property.subtype'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },

        [propertyDetailSearchFields.PRICE_FROM]: {
            ...basePriceInput,
            name: propertyDetailSearchFields.PRICE_FROM,
            // label: '',
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [propertyDetailSearchFields.PRICE_TO]: {
            ...basePriceInput,
            name: propertyDetailSearchFields.PRICE_TO,
            // label: '',
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [propertyDetailSearchFields.AREA_FROM]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.AREA_FROM,
            // label: '',
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [propertyDetailSearchFields.AREA_TO]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.AREA_TO,
            // label: '',
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [propertyDetailSearchFields.ROOMS_NO_FROM]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.ROOMS_NO_FROM,
            // label: '',
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [propertyDetailSearchFields.ROOMS_NO_TO]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.ROOMS_NO_TO,
            // label: '',
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [propertyDetailSearchFields.BEDROOMS_NO_FROM]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.BEDROOMS_NO_FROM,
            // label: '',
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [propertyDetailSearchFields.BEDROOMS_NO_TO]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.BEDROOMS_NO_TO,
            // label: '',
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [propertyDetailSearchFields.FLOOR_NO_FROM]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.FLOOR_NO_FROM,
            // label: '',
            placeholder: t('app.common.from'),
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
        },
        [propertyDetailSearchFields.FLOOR_NO_TO]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.FLOOR_NO_TO,
            // label: '',
            placeholder: t('app.common.to'),
            colProps: {
                sm: 6,
            },
        },
        [propertyDetailSearchFields.PROPERTY_DESCRIPTIONS]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_DESCRIPTIONS,
            label: t('app.common.property.description'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_SPACE]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_SPACE,
            label: t('app.common.property.space'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.COUNTRY]: {
            ...baseSelectInput,
            name: propertyDetailSearchFields.COUNTRY,
            label: t('app.common.country'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.COUNTY]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.COUNTY,
            disabled: true,
            label: t('app.common.county'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.CITY]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.CITY,
            label: t('app.common.cityMunicipality'),
            disabled: true,
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.WIDER_AREA]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.WIDER_AREA,
            label: t('app.common.widerArea'),
            disabled: true,
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.QUARTER]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.QUARTER,
            label: t('app.common.quarter'),
            disabled: true,
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.ISLAND]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.ISLAND,
            label: t('app.common.island'),
            disabled: true,
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.STREET]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.STREET,
            label: t('app.common.street'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.DISTANCE_SEA]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.DISTANCE_SEA,
            label: t('app.common.property.seaDistance'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_UTILITY]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_UTILITY,
            label: t('app.common.utility'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_EQUIPMENT]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_EQUIPMENT,
            label: t('app.common.equipment'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.OWNER]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.OWNER,
            label: t('form.property.detailSearch.owner'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PHONE_NUMBER]: {
            ...baseTextInput,
            name: propertyDetailSearchFields.PHONE_NUMBER,
            label: t('app.common.phoneNumber'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.AGENT]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.AGENT,
            label: t('app.common.agent'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_RECEIPT_DATE_FROM]: {
            ...baseDateInput,
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
            name: propertyDetailSearchFields.PROPERTY_RECEIPT_DATE_FROM,
            placeholder: t('app.common.from'),
        },
        [propertyDetailSearchFields.PROPERTY_RECEIPT_DATE_TO]: {
            ...baseDateInput,
            colProps: {
                sm: 6,
            },
            name: propertyDetailSearchFields.PROPERTY_RECEIPT_DATE_TO,
            placeholder: t('app.common.to'),
        },
        [propertyDetailSearchFields.AVAILABLE_FROM_DATE_FROM]: {
            ...baseDateInput,
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
            name: propertyDetailSearchFields.AVAILABLE_FROM_DATE_FROM,
            placeholder: t('app.common.from'),
        },
        [propertyDetailSearchFields.AVAILABLE_FROM_DATE_TO]: {
            ...baseDateInput,
            colProps: {
                sm: 6,
            },
            name: propertyDetailSearchFields.AVAILABLE_FROM_DATE_TO,
            placeholder: t('app.common.to'),
        },
        [propertyDetailSearchFields.WEB]: {
            ...baseSelectInput,
            name: propertyDetailSearchFields.WEB,
            label: t('form.property.detailSearch.web'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.ATTRIBUTE]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.ATTRIBUTE,
            label: t('form.property.detailSearch.attribute'),
            disabled: true,
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PORTAL]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PORTAL,
            label: t('form.property.detailSearch.portals'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_CONDITION]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_CONDITION,
            label: t('form.property.detailSearch.condition'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_ENERGY_EFFICIENCY]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_ENERGY_EFFICIENCY,
            label: t('app.common.energyEfficiency'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        [propertyDetailSearchFields.PROPERTY_FLOOR]: {
            ...baseMultiSelectInput,
            name: propertyDetailSearchFields.PROPERTY_FLOOR,
            label: t('app.common.numberOfFloors'),
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
    }
}

export {
    propertyDetailSearchFieldTypes as propertyDetailSearhFieldTypes,
    propertyOfferPrintFieldTypes,
    propertyTransactionsFieldTypes,
    realEstateDescriptionFieldTypes,
    realEstateGeneralFieldTypes,
    realEstaterealEstateMaterials3DVideoFieldTypes,
}
