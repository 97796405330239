import i18n from '../../../i18n'
import { activityFieldTypes } from './fieldTypes'
import { activityFields } from './fields'

export const activityEventInputs = (isEdit, appSettings) => {
    const inputs = activityFieldTypes(isEdit, appSettings)

    return {
        mainForm: [
            inputs[activityFields.TITLE],
            inputs[activityFields.ACTIVITY_TYPE_ID],
            {
                type: 'grouped',
                name: 'durationFromGrouped',
                label: i18n.t('app.common.dateFrom'),
                colProps: {
                    sm: 12,
                    xl: 6,
                },
                items: [inputs[activityFields.DATE_FROM], inputs[activityFields.TIME_FROM]],
            },
            {
                type: 'grouped',
                name: 'durationToGrouped',
                label: i18n.t('app.common.dateTo'),
                colProps: {
                    sm: 12,
                    xl: 6,
                },
                items: [inputs[activityFields.DATE_TO], inputs[activityFields.TIME_TO]],
            },
            inputs[activityFields.DURATION_DAY],
            inputs[activityFields.COMPLETED],
            inputs[activityFields.ACTIVITY_LOCATION],
            // inputs[activityFields.CONTACT_ID],
            inputs[activityFields.CONTACT_COMPANY],
            inputs[activityFields.PROPERTY_ID],
            inputs[activityFields.PROJECT_ID],
            {
                type: 'fieldArray',
                name: activityFields.ACTIVITY_AGENT_GROUP,
                label: i18n.t('app.common.agent'),
                labelProps: {
                    className: 'fw-medium',
                },
                children: [inputs[activityFields.AGENT_ID], inputs[activityFields.NOTIFICATION_SEND]],
            },
            {
                type: 'fieldArray',
                name: activityFields.ACTIVITY_REMINDER_GROUP,
                label: i18n.t('modal.activity.field.reminder'),
                tooltip: i18n.t('modal.activity.field.reminder.tooltip'),
                labelProps: {
                    className: 'fw-medium',
                },
                children: [inputs[activityFields.REMINDER_TYPE], inputs[activityFields.REMINDER_SEND]],
            },
            {
                type: 'accordion',
                name: 'notesAccordion',
                children: [inputs[activityFields.NOTES]],
            },
        ],
    }
}

export const activityTaskInputs = (isEdit, appSettings) => {
    const inputs = activityFieldTypes(isEdit, appSettings)

    return {
        mainForm: [
            inputs[activityFields.TITLE],
            inputs[activityFields.ACTIVITY_TYPE_ID],
            {
                type: 'grouped',
                name: 'durationFromGrouped',
                label: i18n.t('app.common.dateFrom'),
                colProps: {
                    sm: 12,
                    xl: 6,
                },
                items: [inputs[activityFields.DATE_FROM], inputs[activityFields.TIME_FROM]],
            },
            {
                type: 'grouped',
                name: 'durationToGrouped',
                label: i18n.t('app.common.dateTo'),
                colProps: {
                    sm: 12,
                    xl: 6,
                },
                items: [inputs[activityFields.DATE_TO], inputs[activityFields.TIME_TO]],
            },
            inputs[activityFields.DURATION_DAY],
            inputs[activityFields.COMPLETED],
            inputs[activityFields.CONTACT_COMPANY],
            inputs[activityFields.CONTACT_ID],
            inputs[activityFields.PROPERTY_ID],
            inputs[activityFields.PROJECT_ID],
            {
                type: 'fieldArray',
                name: activityFields.ACTIVITY_AGENT_GROUP,
                label: i18n.t('app.common.agent'),
                labelProps: {
                    className: 'fw-medium',
                },
                children: [inputs[activityFields.AGENT_ID], inputs[activityFields.NOTIFICATION_SEND]],
            },
            {
                type: 'fieldArray',
                name: activityFields.ACTIVITY_REMINDER_GROUP,
                label: i18n.t('modal.activity.field.reminder'),
                labelProps: {
                    className: 'fw-medium',
                },
                children: [inputs[activityFields.REMINDER_TYPE], inputs[activityFields.REMINDER_SEND]],
            },
            {
                type: 'accordion',
                name: 'notesAccordion',
                children: [inputs[activityFields.NOTES]],
            },
        ],
    }
}

export const activityReminderInputs = (isEdit, appSettings) => {
    const inputs = activityFieldTypes(isEdit, appSettings)

    return {
        mainForm: [
            inputs[activityFields.TITLE],
            inputs[activityFields.ACTIVITY_TYPE_ID],
            {
                type: 'grouped',
                name: 'durationFromGrouped',
                label: i18n.t('app.common.dateFrom'),
                colProps: {
                    sm: 12,
                    xl: 6,
                },
                items: [inputs[activityFields.DATE_FROM], inputs[activityFields.TIME_FROM]],
            },
            {
                type: 'grouped',
                name: 'durationToGrouped',
                label: i18n.t('app.common.dateTo'),
                colProps: {
                    sm: 12,
                    xl: 6,
                },
                items: [inputs[activityFields.DATE_TO], inputs[activityFields.TIME_TO]],
            },
            inputs[activityFields.DURATION_DAY],
            {
                type: 'fieldArray',
                name: activityFields.ACTIVITY_AGENT_GROUP,
                label: i18n.t('app.common.agent'),
                labelProps: {
                    className: 'fw-medium',
                },
                children: [inputs[activityFields.AGENT_ID], inputs[activityFields.NOTIFICATION_SEND]],
            },
            {
                type: 'fieldArray',
                name: activityFields.ACTIVITY_REMINDER_GROUP,
                label: i18n.t('modal.activity.field.reminder'),
                labelProps: {
                    className: 'fw-medium',
                },
                children: [inputs[activityFields.REMINDER_TYPE], inputs[activityFields.REMINDER_SEND]],
            },
            {
                type: 'accordion',
                name: 'notesAccordion',
                children: [inputs[activityFields.NOTES]],
            },
        ],
    }
}
