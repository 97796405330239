import { riskConstants } from '../constants'
import { BadgeRiskAssessmentProps } from './BadgeRiskAssessment.types'

const BadgeRiskAssessment = (props: BadgeRiskAssessmentProps) => {
    const { riskAssessment } = props
    const risk = riskConstants[riskAssessment as keyof typeof riskConstants] || riskConstants.DEFAULT

    return (
        <span className="fs-12 fw-medium badge" style={{ backgroundColor: risk.color }}>
            {risk.text}
        </span>
    )
}

export default BadgeRiskAssessment
