import { Col, FormFeedback, Label, UncontrolledTooltip } from 'reactstrap'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { useEffect, useCallback, useState } from 'react'

export const SelectInput = ({
    inputField,
    isFieldArray,
    formik,
    fieldName,
    error,
    touched,
    inputComponents,
    inputValue,
    index,
    fieldArrayDeleteComponent,
    selectOptions,
    inputsLength,
    inputMargin,
}) => {
    const { t } = useTranslation()
    const [isDisabled, setIsDisabled] = useState(inputField.disabled)

    const getValue = useCallback(() => {
        if (selectOptions && selectOptions[!isFieldArray ? inputField.name : inputField.oldName]) {
            if (inputField.isMulti) {
                return selectOptions[!isFieldArray ? inputField.name : inputField.oldName]?.filter((option) => inputValue?.indexOf(option.value) >= 0)
            }

            if (
                selectOptions[!isFieldArray ? inputField.name : inputField.oldName]?.length &&
                selectOptions[!isFieldArray ? inputField.name : inputField.oldName]?.[0].options
            ) {
                let optGroupVal = ''
                let group = selectOptions[!isFieldArray ? inputField.name : inputField.oldName]?.find((option) =>
                    option.options.find((o) => o.value === inputValue)
                )
                if (group) {
                    optGroupVal = group.options.find((option) => option.value === inputValue)
                }
                return optGroupVal
            }

            const value = selectOptions[!isFieldArray ? inputField.name : inputField.oldName]?.find(
                (option) => option.value === inputValue?.toString()
            )

            if (value) {
                return selectOptions[!isFieldArray ? inputField.name : inputField.oldName]?.find((option) => option.value === inputValue?.toString())
            }
        }
        return ''
    }, [inputField.isMulti, inputField.name, inputField.oldName, inputValue, isFieldArray, selectOptions])

    useEffect(() => {
        if (inputField?.name?.includes('contact_job_title_id')) {
            const disableInputName = inputField.name.replace('contact_job_title_id', 'ownership_percentage')
            const input = document.querySelector(`[name="otherInfo.${disableInputName}"]`)
            const companyIdField = inputField.name.replace('contact_job_title_id', 'company_id')
            const isCompanySelected = document.querySelector(`[id="${companyIdField}"]`)

            if (!input) return

            const contact_job_title_id = String(getValue()?.value ?? '')

            if (contact_job_title_id === '5' || contact_job_title_id === '10') {
                input.disabled = false
            } else {
                input.disabled = true
                input.value = ''
            }
            if (
                !isCompanySelected ||
                isCompanySelected.textContent.trim() === '' ||
                isCompanySelected.textContent === 'All selected options have been cleared.'
            ) {
                setIsDisabled(true)
            } else {
                setIsDisabled(false)
            }
        }
    }, [getValue, inputField.name])

    useEffect(() => {
        setIsDisabled(inputField.disabled)
    }, [inputField.disabled])

    return (
        <>
            <Col {...inputField.colProps} className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}>
                {inputField.label && (
                    <div className="d-flex justify-content-between">
                        <Label for={fieldName} id={`${inputField.name}Lbl`}>
                            {inputField.label}
                            <span className={`required-mark ${inputField.required ? 'd-inline' : inputField.showRequired ? 'd-inline' : 'd-none'}`}>
                                *
                            </span>
                        </Label>
                        {inputField.tooltip && (
                            <span id={`${inputField.name.toString().replace(/\./g, '')}Tooltip`}>
                                <i className="mdi mdi-help-circle-outline"></i>{' '}
                            </span>
                        )}
                    </div>
                )}

                <Select
                    onChange={(e) => {
                        if (inputField.isMulti) {
                            formik?.setFieldValue(fieldName, e ? e.map((item) => item.value) : [])
                            // console.log(fieldName, e ? e.map((item) => item.value) : [])
                        } else {
                            formik?.setFieldValue(fieldName, e ? e.value : '')
                        }
                        if (inputField.handleChange) {
                            if (inputField.isMulti) {
                                inputField.handleChange(e ? e.map((item) => item.value) : [])
                            } else {
                                inputField.handleChange(e?.value)
                            }
                        }
                        if (inputField?.name?.includes('contact_job_title_id')) {
                            const disableInputName = inputField.name.replace('contact_job_title_id', 'ownership_percentage')
                            const fieldNameOwnership = disableInputName.replace(/\.(\d+)\./, '[$1].')

                            const input = document.querySelector(`[name="otherInfo.${disableInputName}"]`)

                            if (input && input.value !== '') {
                                formik?.setFieldValue(`otherInfo.${fieldNameOwnership}`, '')
                            }
                        }
                    }}
                    onBlur={() => {
                        formik?.setFieldTouched(fieldName)
                    }}
                    options={selectOptions ? selectOptions[!isFieldArray ? inputField.name : inputField.oldName] : []}
                    id={inputField.name}
                    name={fieldName}
                    // defaultInputValue={formik?.values[inputField.name] || ""}
                    value={getValue()}
                    isDisabled={isDisabled}
                    isMulti={inputField.isMulti ?? false}
                    placeholder={inputField.placeholder ?? ''}
                    noOptionsMessage={() => t('form.common.select.noData')}
                    loadingMessage={() => t('app.common.loading')}
                    className={error && touched ? 'is-invalid' : ''}
                    {...inputField?.inputProps}
                    isClearable={inputField.isClearable}
                />

                {error && touched ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
                {inputField.warning && (
                    <FormFeedback type="invalid" className="d-block mb-2">
                        {inputField.warning}
                    </FormFeedback>
                )}
                {inputComponents && inputComponents[inputField.name] && inputComponents[inputField.name]()}
                {inputField.tooltip && (
                    <UncontrolledTooltip pement="right" target={`${inputField.name.toString().replace(/\./g, '')}Tooltip`}>
                        {inputField.tooltip}
                    </UncontrolledTooltip>
                )}
            </Col>
            {isFieldArray && index === inputsLength && fieldArrayDeleteComponent}
        </>
    )
}
