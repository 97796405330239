import { Card, CardBody, CardHeader } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { ColumnOrder, PageListParams, RouteParams } from '../../../../api/globalTypes'
import inDepthAnalysis from '../../../../api/inDepthAnalysis/hooks'
import TableContainer from '../../../Table/TableContainer'
import TableColumns from './TableColumns/TableColumns'

const ContactsConnectedToCompany = () => {
    const { id } = useParams<RouteParams>()
    const [associateParams, setAnalysisParams] = useState<PageListParams>({
        page: 1,
        limit: 20,
        order: {} as ColumnOrder,
    })

    const refreshToken = localStorage.getItem('refresh_token') as string
    const fetchData = associateParams.page !== 0 && associateParams.limit !== 0 && Object.keys(associateParams.order ?? {}).length > 0

    const { isLoading: fetchingAssociates, data: associateData } = inDepthAnalysis.GetCompanyAssociateById.useQuery(
        id,
        associateParams,
        fetchData,
        refreshToken
    )

    const associateList = !fetchingAssociates ? associateData?.items : []

    const handleDataFetch = (page: number, limit: number, order: ColumnOrder) => {
        setAnalysisParams({ page, limit, order })
    }

    const columns = TableColumns()

    return (
        <Card>
            <CardHeader>
                <h5 className="mb-0 me-4">Kontakti povezani s poduzećem</h5>
            </CardHeader>
            <CardBody>
                <TableContainer
                    columns={columns || []}
                    data={associateList || []}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    isLoading={fetchingAssociates}
                    handleDataFetch={handleDataFetch}
                    totalCount={associateData?.pagination?.totalItems}
                    pageCount={associateData?.pagination?.totalPages}
                    currentPage={associateParams.page}
                    totalPages={associateData?.pagination?.totalPages}
                    theadClass={'dmn-table-header'}
                    tableHash={'inDepthAnalysisContactsConnectedToCompany'}
                    hasColumnSelection={false}
                    hasPageSizeSelect={false}
                    permissionName={'none'}
                    searchCriteria={undefined}
                    handleRowClick={undefined}
                    tableActions={undefined}
                />
            </CardBody>
        </Card>
    )
}

export default ContactsConnectedToCompany
