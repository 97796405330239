import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useCompanyViewTabs = () => {
    const [tabs, setTabs] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        setTabs([
            { id: 1, name: 'general', label: t('view.contact.tab.general') },
            { id: 2, name: 'contactProfile', label: t('app.common.contact.demandProfile') },
            { id: 6, name: 'documents', label: t('app.common.documents') },
            { id: 3, name: 'inDepthAnalysis', label: 'Dubinska analiza' },
        ])
    }, [t])
    return tabs
}
