import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import documents from '../../api/documents/hooks'
import { removeDuplicates } from '../../api/helpers'
import { clearDocuments } from '../../store/documents'
import { resetTableCheckbox } from '../../store/table'
import { selectCheckedTableRows } from '../../store/table/selectors'
import DeleteModal from '../Common/DeleteModal'
import DocumentFormSidebar from '../DocumentFormTab/DocumentFormSidebar'
import TableContainer from '../Table/TableContainer'
import { useFormatTableData } from '../Table/utils'
import useDocumentsHeaders from './hooks/useDocumentsHeaders'

const DocumentViewTab = ({ contactType, addButtonAction, documentModule, openDocumentFormAdd }) => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { t } = useTranslation()

    const parentId = id ? id.toString() : 'null'
    const parentModul = documentModule ? documentModule.toString() : 'null'

    const refreshToken = localStorage.getItem('refresh_token')

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [sidebarEditMode, setSidebarEditMode] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [rowId, setRowId] = useState(null)
    const [sidebarData, setSidebarData] = useState('')
    const totalPages = useRef()
    const invalidateData = useRef()

    const {
        isLoading: fetchingDocuments,
        data: documentsData,
        isFetched,
    } = documents.GetDocuments.useQuery(parentId, page, limit, parentModul, 'document', refreshToken)

    const { mutate: deleteMutate } = documents.DeleteDocument.useMutation(invalidateData, limit, 'document')
    const { mutate: deleteMultipleMutate } = documents.DeleteMultipleDocuments.useMutation(invalidateData, limit, 'document')
    const { mutate: syncMutate } = documents.SyncDocument.useMutation(invalidateData, limit, 'document')

    if (isFetched) totalPages.current = documentsData?.pagination?.totalPages

    const { selectedTableRows } = useSelector((state) => ({
        selectedTableRows: selectCheckedTableRows(state),
    }))

    // SideBar Close
    const sidebarClose = (value) => {
        const element = document.getElementsByTagName('body')[0]
        element.classList.remove(value)
        setSidebarEditMode(false)
        setSidebarData('')
    }

    useEffect(() => {
        return () => {
            sidebarClose('file-detail-show')
        }
    }, [])

    useEffect(() => {
        dispatch(clearDocuments())
    }, [dispatch, id])

    const handleDataFetch = (page, limit) => {
        setPage(page)
        setLimit(limit)
    }

    const onClickDelete = (id) => {
        setRowId(id)
        setDeleteModal(true)
    }

    const handleDeleteDocument = () => {
        deleteMutate(rowId)
        dispatch(resetTableCheckbox(true))
        setDeleteModal(false)
    }

    const deleteMultipleDocuments = () => {
        const selectedRowsIds = selectedTableRows.map((row) => row.id)
        deleteMultipleMutate(selectedRowsIds)
        dispatch(resetTableCheckbox(true))
        setDeleteModalMulti(false)
    }

    const tableData = useFormatTableData(documentsData?.items, 'documents')
    const columns = useDocumentsHeaders(documentModule, syncMutate, setSidebarData, setSidebarEditMode, onClickDelete, 'documents', invalidateData)

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            {!openDocumentFormAdd && (
                <Button
                    onClick={(e) => {
                        e.preventDefault()
                        if (addButtonAction) {
                            addButtonAction()
                        }
                    }}
                    color="add"
                    className="btn-label"
                >
                    <i className="mdi mdi-plus align-bottom label-icon align-middle fs-16 me-2"></i>
                    {t('button.add')}
                </Button>
            )}

            <UncontrolledDropdown className="ms-2">
                <DropdownToggle title={t('table.showMore')} href="#" className="btn btn-primary dropdown" tag="button">
                    <i className="mdi mdi-dots-horizontal align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                        disabled={selectedTableRows.length > 0 ? false : true}
                        href="#"
                        onClick={() => {
                            setDeleteModalMulti(true)
                            const selectedAllItems = selectedTableRows.map((item) => {
                                return { id: item.parent, module: item.module }
                            })
                            invalidateData.current = removeDuplicates(selectedAllItems, 'id')
                        }}
                    >
                        <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i> {t('table.action.deleteSelected')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

    return (
        <>
            <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteDocument()} onCloseClick={() => setDeleteModal(false)} />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultipleDocuments()
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
            <div className="chat-wrapper d-lg-flex gap-1">
                <div className={classNames('file-manager-content w-100 px-0 py-0', { 'pe-2': sidebarData })}>
                    <SimpleBar className="overflow-hidden">
                        <TableContainer
                            columns={columns}
                            tableActions={tableActions}
                            data={tableData || []}
                            isGlobalFilter={false}
                            isAddUserList={false}
                            isLoading={fetchingDocuments}
                            customPageSize={20}
                            tableClass="dmn-table--property-documents"
                            theadClass={'dmn-table-header'}
                            totalCount={documentsData?.pagination?.totalItems}
                            pageCount={totalPages.current}
                            responsive={false}
                            handleDataFetch={handleDataFetch}
                            tableHash={'documents'}
                        />
                    </SimpleBar>
                </div>
                <DocumentFormSidebar
                    sidebarData={sidebarData}
                    sidebarClose={sidebarClose}
                    onClickDelete={onClickDelete}
                    isEdit={sidebarEditMode}
                    contactType={contactType}
                    parentId={parentId}
                    limit={limit}
                    invalidateData={invalidateData}
                />
            </div>
        </>
    )
}

export default DocumentViewTab
