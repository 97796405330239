import { useEffect, useState } from 'react'
import ReactDragListView from 'react-drag-listview/lib/index.js'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'
import { setQueryData } from '../../api/helpers'
import { handleImagesOrder } from '../../api/images/helpers'
import images from '../../api/images/hooks'
import { API_URL } from '../../common/constants'
import { deleteGroundPlan } from '../../store/groundplans'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import DeleteModal from '../Common/DeleteModal'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import FormTabNavigation from '../Common/FormTabNavigation'
import Loader from '../Common/Loader'
import Dropzone from '../Dropzone/Dropzone'
import useLocaleOptions from '../Hooks/useLocaleOptions'
import PhotoCard from '../PhotoCard'
import ToastImageEditor from '../ToastImageEditor/ToastImageEditor'

const GroundPlansFormTab = ({
    module,
    tabs = [],
    activeTab,
    handleTabChange,
    childTabs = [],
    activeChildTab,
    handleChildTabChange,
    isFetching,
    isSubmitting,
    files,
    entityData,
    collection,
    url,
}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const localeOptions = useLocaleOptions()
    const queryPayload = { collection, module, parent: entityData?.id }

    const { mutate: uploadImages } = images.UploadImages.useMutation()
    const { mutate: deleteImages } = images.DeleteImages.useMutation()
    const { mutate: syncImage } = images.SyncImage.useMutation()

    const [fullscreenImg, setFullscreenImg] = useState(null)
    const [selectedGroundPlans, setSelectedGroundPlans] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [invoice, setInvoice] = useState(null)

    useEffect(() => {
        if (activeTab === 'materials') {
            window.scrollTo(0, 0)
        }
    }, [activeTab])

    useEffect(() => {
        if (activeTab !== 'materials' || activeChildTab !== 'groundPlans') {
            setFullscreenImg(null)
        }
    }, [activeTab, activeChildTab, dispatch])

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const { collection, module, parent } = queryPayload
            const updatedImages = handleImagesOrder(fromIndex, toIndex, files)

            setQueryData([collection, module, parent], updatedImages)
        },
        nodeSelector: '.drag-node',
        handleSelector: '.mdi-menu',
        lineClassName: 'dmn-photo-drag',
    }

    const onClickDelete = () => {
        if (selectedGroundPlans.length) {
            setDeleteModalMulti(true)
            return
        }

        setInvoice(fullscreenImg)
        setDeleteModal(true)
    }

    const handleDeleteInvoice = () => {
        if (invoice) {
            dispatch(deleteGroundPlan(invoice.id))
            setDeleteModal(false)
            setFullscreenImg(null)
            setSelectedGroundPlans([])
        }
    }

    const deleteMultiple = () => {
        const payload = { ids: selectedGroundPlans, ...queryPayload }

        deleteImages(payload)
        setSelectedGroundPlans([])
    }

    const handleGroundplansUpload = (files) => {
        uploadImages({ files, entityData, url, collection, module })
    }

    const handleFullscreenImg = (img) => {
        setFullscreenImg(img)
        deselectPhotos()
    }

    const deselectPhotos = () => {
        const inputs = document.querySelectorAll('.materialsPhotoCheckbox:checked')
        inputs.forEach((input) => (input.checked = false))
    }

    const handlePhotosCheckboxes = () => {
        let inputs
        let values = []

        switch (activeChildTab) {
            case 'groundPlans':
                inputs = document.querySelectorAll('.materialsGroundPlanCheckbox:checked')

                inputs.forEach((element) => {
                    values.push(element.value)
                })
                setSelectedGroundPlans(values)
                break
            default:
                break
        }
    }

    const renderDownloadUrl = () => {
        let dlUrl = `${API_URL}v1/file/download/`
        let selectedIds = selectedGroundPlans.map((x) => parseInt(x))

        if (fullscreenImg && fullscreenImg.id) {
            selectedIds.push(fullscreenImg.id)
        }
        let uniqueIds = [...new Set(selectedIds)]

        dlUrl = dlUrl + uniqueIds.join(',')
        return dlUrl
    }

    const checkedAll = () => {
        let ele = document.querySelectorAll('.materialsGroundPlanCheckbox')

        if (!selectedGroundPlans.length) {
            let values = []
            ele.forEach((ele) => {
                ele.checked = true
                values.push(ele.value)
            })
            setSelectedGroundPlans(values)
        } else {
            setSelectedGroundPlans([])
            ele.forEach((ele) => {
                ele.checked = false
            })
        }
    }

    return (
        <Row>
            <FormSideContainer>
                {activeTab === 'materials' && (
                    <Dropzone callback={handleGroundplansUpload} tab={activeChildTab} module={module} parent={entityData?.id} />
                )}
            </FormSideContainer>

            <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
                <div className="d-flex justify-content-between align-items-center">
                    {childTabs.length ? (
                        <FormTabNavigation
                            handleTabChange={handleChildTabChange}
                            tabs={childTabs}
                            activeTab={activeChildTab}
                            customClass="real-estate-form-subtabs mb-4"
                        />
                    ) : (
                        ''
                    )}

                    <div className="hstack gap-2 d-print-none">
                        {fullscreenImg && (
                            <Button
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleFullscreenImg(null)
                                }}
                                color="dark"
                                className="btn-icon"
                                style={{ zIndex: 2 }}
                            >
                                <i className="mdi mdi-close" />{' '}
                            </Button>
                        )}
                        <Button
                            color="warning"
                            className="btn-label"
                            disabled={!!fullscreenImg}
                            onClick={(e) => {
                                e.preventDefault()
                                checkedAll()
                            }}
                        >
                            <i className="mdi mdi-checkbox-multiple-outline label-icon align-middle fs-16 me-2"></i>{' '}
                            {selectedGroundPlans.length ? t('button.deselect') : t('button.select')}
                        </Button>
                        {selectedGroundPlans.length ? (
                            <>
                                <Button
                                    color="danger"
                                    className="btn-label"
                                    disabled={fullscreenImg === null && selectedGroundPlans.length === 0}
                                    onClick={() => {
                                        onClickDelete()
                                    }}
                                >
                                    <i className="mdi mdi-trash-can-outline label-icon align-middle fs-16 me-2"></i> {t('button.delete')}
                                </Button>
                                <Button
                                    tag="a"
                                    href={renderDownloadUrl()}
                                    color="edit"
                                    className="btn-label"
                                    download={true}
                                    disabled={fullscreenImg === null && !selectedGroundPlans.length}
                                >
                                    <i className="mdi mdi-download label-icon align-middle fs-16 me-2"></i>
                                    {t('button.download')}
                                </Button>
                            </>
                        ) : null}
                    </div>
                </div>

                <Row className="mb-5 position-relative">
                    {fullscreenImg && activeChildTab === 'groundPlans' ? (
                        <ToastImageEditor image={fullscreenImg} isSubmitting={isSubmitting} />
                    ) : null}
                </Row>

                {!isFetching ? (
                    <Row className="dmn-drag-row">
                        <ReactDragListView {...dragProps} className="row" tag={Row}>
                            {files?.map((item) => (
                                <AppPermissionsSwitcher
                                    permission="AllowedPhotos"
                                    photo={item}
                                    module={module === 'project' ? 'projects' : 'properties'}
                                    key={item.id}
                                >
                                    {({ photo }) =>
                                        photo && (
                                            <Col sm={12} xxl={3} className="drag-node">
                                                <PhotoCard
                                                    checkboxClassName="materialsGroundPlanCheckbox"
                                                    handleFullSizeClick={handleFullscreenImg}
                                                    handleCheckboxes={handlePhotosCheckboxes}
                                                    photo={photo}
                                                    localeOptions={localeOptions}
                                                    handlePhotoSync={(id) => syncImage({ id, ...queryPayload })}
                                                />
                                            </Col>
                                        )
                                    }
                                </AppPermissionsSwitcher>
                            ))}
                        </ReactDragListView>
                    </Row>
                ) : (
                    <Loader />
                )}
            </FormMainContainer>
            <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteInvoice()} onCloseClick={() => setDeleteModal(false)} />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultiple()
                    setDeleteModalMulti(false)
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
        </Row>
    )
}
export default GroundPlansFormTab