import React, { useEffect } from 'react'
import SimpleBar from 'simplebar-react'

//Import Components
import { Container } from 'reactstrap'
import AppPermissionsSwitcher from '../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import ExportSyncWidget from '../Components/ExportSyncWidget'
import HorizontalLayout from './HorizontalLayout'
import TwoColumnLayout from './TwoColumnLayout'
import VerticalLayout from './VerticalLayouts'

const Sidebar = ({ layoutType }) => {
    useEffect(() => {
        var verticalOverlay = document.getElementsByClassName('vertical-overlay')
        if (verticalOverlay) {
            verticalOverlay[0].addEventListener('click', function () {
                document.body.classList.remove('vertical-sidebar-enable')
            })
        }
    })

    const addEventListenerOnSmHoverMenu = () => {
        // add listener Sidebar Hover icon on change layout from setting
        if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active')
        } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover')
        } else {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover')
        }
    }

    return (
        <React.Fragment>
            <div className="app-menu navbar-menu">
                <div className="navbar-brand-box">
                    <button
                        onClick={addEventListenerOnSmHoverMenu}
                        type="button"
                        className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover"
                    >
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>
                {layoutType === 'horizontal' ? (
                    <div id="scrollbar">
                        <Container fluid className="d-flex justify-space-between flex-column flex-lg-row">
                            <div id="two-column-menu"></div>
                            <ul className="navbar-nav" id="navbar-nav">
                                <HorizontalLayout />
                            </ul>
                            <AppPermissionsSwitcher permission="ExportSyncWidget">
                                {({ hasPermission }) => hasPermission && <ExportSyncWidget className="ms-lg-auto" />}
                            </AppPermissionsSwitcher>
                        </Container>
                    </div>
                ) : layoutType === 'twocolumn' ? (
                    <React.Fragment>
                        <TwoColumnLayout layoutType={layoutType} />
                        <div className="sidebar-background"></div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <SimpleBar id="scrollbar" className="h-100">
                            <Container fluid>
                                <div id="two-column-menu"></div>
                                <ul className="navbar-nav" id="navbar-nav">
                                    <VerticalLayout layoutType={layoutType} />
                                </ul>
                            </Container>
                        </SimpleBar>
                        <div className="sidebar-background"></div>
                    </React.Fragment>
                )}
            </div>
            <div className="vertical-overlay"></div>
        </React.Fragment>
    )
}

export default Sidebar
