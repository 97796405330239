import values from 'lodash/values'
import { CONTACT_TYPE } from '../../common/constants'

import { generateLocationData } from '../../utils/general'
import { stateKey } from './actions'

export const selectActivities = (state) => values(state[stateKey].items)
export const selectActivitiesFetching = (state) => state[stateKey].fetching
export const selectActivitiesCount = (state) => state[stateKey].count
export const selectActivityFetchingDetails = (state) => state[stateKey].fetchingDetails

export const selectActivity = (state, id) => {
    if (id) {
        return state[stateKey].items?.[id]
    }
    return null
}

export const selectActivityContactAsyncSelectedFormOptions = (state, id) => {
    const data = state[stateKey].items?.[id]?.contact

    if (data?.id) {
        if (data.contactType === CONTACT_TYPE.PERSON) {
            return [
                {
                    label: data?.contactPerson?.firstName + ' ' + data?.contactPerson?.lastName,
                    value: data?.id?.toString(),
                },
            ]
        }
        return [
            {
                label: data?.contactCompany?.name,
                value: data?.id?.toString(),
            },
        ]
    }

    return []
}

export const selectActivityContactCompanyCollectionAsyncSelectedFormOptions = (state, id) => {
    const data = state[stateKey].items?.[id]?.contactCompany

    if (data?.length) {
        return data.map((item) => ({
            label: item?.contactCompany?.name ?? '',
            value: item?.id ?? '',
        }))
    }

    return []
}

export const selectActivityProjectAsyncSelectedFormOptions = (state, id) => {
    const data = state[stateKey].items?.[id]?.project
    const currentLang = localStorage.getItem('i18nextLng')

    const createOption = (item, currentLang) => {
        return {
            value: item?.id?.toString(),
            label: `ID: ${item.internalAppId} - ${item?.translations?.[currentLang]?.projectTitle}`,
            location: generateLocationData(item),
        }
    }

    if (!data) return []

    if (Array.isArray(data)) {
        return data.map((item) => createOption(item, currentLang))
    } else {
        const option = createOption(data, currentLang)
        return [option]
    }
}

export const selectActivityPropertyAsyncSelectedFormOptions = (state, id) => {
    const data = state[stateKey].items?.[id]?.property
    const currentLang = localStorage.getItem('i18nextLng')

    const createOption = (item, currentLang) => {
        return {
            value: item?.id?.toString(),
            label: `ID: ${item.internalAppId} - ${item.translations[currentLang]?.propertyTitle ?? generateLocationData(item)}`,
            location: generateLocationData(item),
        }
    }

    if (!data) return []

    if (Array.isArray(data)) {
        return data.map((item) => createOption(item, currentLang))
    } else {
        const option = createOption(data, currentLang)
        return [option]
    }
}

export const selectActivityReminderTypeSelectedFormOptions = () => {
    return [
        {
            label: 'PopUp prozor',
            options: [
                { label: '15 min', value: 'popup|15' },
                { label: '30 min', value: 'popup|30' },
                { label: '1 sat', value: 'popup|60' },
                { label: '2 sata', value: 'popup|120' },
                { label: '1 dan', value: 'popup|1440' },
                { label: '1 tjedan', value: 'popup|10080' },
            ],
        },
        {
            label: 'E-mail',
            options: [
                { label: '15 min', value: 'email|15' },
                { label: '30 min', value: 'email|30' },
                { label: '1 sat', value: 'email|60' },
                { label: '2 sata', value: 'email|120' },
                { label: '1 dan', value: 'email|1440' },
                { label: '1 tjedan', value: 'email|10080' },
            ],
        },
    ]
}
