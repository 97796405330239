import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainer from '../../../Components/Table/TableContainer'
import { RowData } from '../globalTypes'
import { AgentContactStatusProps } from './types'
import { getContactStatusOveralls } from './utils'
const AgentContactStatusReportTable = (props: AgentContactStatusProps) => {
    const { reportsData, reportsFetching } = props
    const { t } = useTranslation()

    const reports = useMemo(() => reportsData?.data || [], [reportsData])
    const contactStatuses = useMemo(() => reports[0]?.contactStatusTotals || [], [reports])

    const columns = useMemo(() => {
        const contactStatusColumn = {
            Header: t('app.common.agent'),
            id: 'agent',
            accessor: (row: RowData) => `${row.agent?.firstName} ${row.agent?.lastName}`,
            filterable: false,
        }

        const statusColumns = contactStatuses?.map(({ name, statusId }) => ({
            Header: name,
            id: statusId,
            accessor: (row: RowData) => {
                const contactStatusTotal = row.contactStatusTotals?.find((ct) => ct?.statusId === statusId)
                if (!contactStatusTotal) return null

                return <div className="text-center">{contactStatusTotal.total}</div>
            },
            filterable: false,
        }))

        const totalColumn = {
            Header: t('app.common.total'),
            id: 'total_by_agent',
            accessor: (row: RowData) => <div className="text-center fw-bolder">{row.agentGrandTotal}</div>,
            filterable: false,
        }

        return [contactStatusColumn, ...statusColumns, totalColumn]
    }, [contactStatuses, t])

    const tableFooter = useMemo(() => {
        const { contactStatusOveralls, grandOverall } = getContactStatusOveralls(reports, contactStatuses)

        return (
            <tr>
                <td>{t('app.common.overall')}</td>
                {contactStatusOveralls.map((overall, i) => (
                    <td key={i} className="text-center">
                        {overall.count}
                    </td>
                ))}
                <td className="text-center fw-bolder">{grandOverall}</td>
            </tr>
        )
    }, [reports, contactStatuses, t])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            tableFooter={tableFooter}
            tableHash={'reports_agent-contact-status'}
            hidePagination={true}
            {...({} as any)} // temporary fix
        />
    )
}

export default AgentContactStatusReportTable
