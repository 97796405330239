import { useFormik } from 'formik'
import i18n from 'i18next'
import parsePhoneNumber from 'libphonenumber-js'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row } from 'reactstrap'
import * as Yup from 'yup'
import BreadCrumb from '../../../../Components/Common/BreadCrumb'
import { fetchAgencyOffice, selectAgencyOffice, selectAgencyOfficeFetching, updateAgencyOffice } from '../../../../store/administration'
import AdminLoader from '../../Components/AdminLoader/AdminLoader'
import AgencyOfficeForm from '../../Components/AgencyOfficeForm/AgencyOfficeForm'
import { officeAgencyFieldDescription } from '../../Components/AgencyOfficeForm/officeAgencyFieldDescription'
import UploadPhoto from '../../Components/UploadPhoto/UploadPhoto'
import { officeValidation } from '../validateRules'
import { DEFAULT_TRANSPARENCY } from '../../constants'

const OfficeEdit = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const description = officeAgencyFieldDescription()

    const { office, fetching } = useSelector((state) => ({
        office: selectAgencyOffice(state),
        fetching: selectAgencyOfficeFetching(state),
    }))

    useEffect(() => {
        dispatch(fetchAgencyOffice(id))
    }, [dispatch, id])

    const formatPhoneNumber = (number) => {
        if (!number) return null

        return parsePhoneNumber(number).formatInternational()
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: !fetching && office,
        validationSchema: Yup.object(officeValidation()),
        onSubmit: (values) => {
            const phoneNumber = formatPhoneNumber(values?.phoneNumber)
            const mobileNumber = formatPhoneNumber(values?.mobileNumber)

            const office = {
                ...values,
                mobileNumber: mobileNumber,
                phoneNumber: phoneNumber,
                watermarkTransparency: values.watermarkTransparency || DEFAULT_TRANSPARENCY,
            }

            dispatch(updateAgencyOffice(office))
        },
    })

    return fetching ? (
        <AdminLoader />
    ) : (
        <Row>
            <BreadCrumb title={`${i18n.t('button.edit')} - ${formik?.values?.name}`} />
            <UploadPhoto
                formik={formik}
                photoField={'logo'}
                imageUrl={formik?.values?.logo}
                firstName={formik?.values?.name}
                headline={formik?.values?.name}
                permission={description.officeLogo}
            />
            <AgencyOfficeForm formik={formik} isOffice={true} cancelButtonUrl={'/admin/office'} />
        </Row>
    )
}

export default OfficeEdit
