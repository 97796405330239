import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DETAIL_SEARCH_TYPE, WEBSITE_ATTRIBUTES } from '../common/constants'
import responseFields from '../common/constants/responseFields'
import { companyDetailSearchInputs, companyProfileDetailSearchInputs } from '../common/forms/company/inputs'
import { companyDetailSearchValidation, companyProfileDetailSearchValidation } from '../common/forms/company/validations'
import { contactDetailSearchFields } from '../common/forms/contacts/fields'
import { contactDetailSearchInputs, contactProfileDetailSearchInputs } from '../common/forms/contacts/inputs'
import { contactDetailSearchValidation, contactProfileDetailSearchValidation } from '../common/forms/contacts/validations'
import { diaryDetailSearchInputs } from '../common/forms/diary/inputs'
import { diaryDetailSearchValidation } from '../common/forms/diary/validation'
import { propertyDetailSearchFields } from '../common/forms/generalRealEstate/fields'
import { propertyDetailSearchInputs } from '../common/forms/generalRealEstate/inputs'
import { propertyDetailSearchValidation } from '../common/forms/generalRealEstate/validations'
import { projectDetailSearchFields } from '../common/forms/projects/fields'
import { projectDetailSearchInputs } from '../common/forms/projects/inputs'
import { projectDetailSearchValidation } from '../common/forms/projects/validation'
import { selectAgentsFormOption } from '../store/agents/selectors'
import { fetchCompaniesNoStore, resetPageCompanies } from '../store/companies/actions'
import {
    selectContactValuesCountryFormOptions,
    selectContactValuesPriorityFormOptions,
    selectContactValuesProfileDurationFormOptions,
    selectContactValuesProfileServiceFormOptions,
    selectContactValuesProfileStatusFormOptions,
    selectContactValuesQueryCategoryFormOptions,
    selectContactValuesStatusFormOptions,
} from '../store/contactValues'
import { fetchContactsNoStore, resetPageContact } from '../store/contacts'
import { fetchDiariesNoStore } from '../store/diaries'
import { selectExportPortalsFormOptions, selectExportWebsitesFormOptions } from '../store/export'
import { selectProjectValuesStatusFormOptions, selectProjectValuesTypeFormOptions } from '../store/projectValues/selectors'
import { fetchProjectsNoStore, resetPageProjects } from '../store/projects'
import { fetchPropertiesNoStore, resetPageProperties } from '../store/properties/actions'
import { selectPropertyServicesFormOptions } from '../store/propertyServices'
import { selectPropertyTypesFormOptions } from '../store/propertyTypes'
import {
    selectPropertyValuesConditionFormOptions,
    selectPropertyValuesDataSourceFormOptions,
    selectPropertyValuesEnergyEfficiencyFormOptions,
    selectPropertyValuesStatusFormOptions,
} from '../store/propertyValues'
import {
    clearDetailSearchCompaniesCriteria,
    clearDetailSearchContactsCriteria,
    clearDetailSearchDiaryCriteria,
    clearDetailSearchProjectsCriteria,
    clearDetailSearchPropertiesCriteria,
    setDetailSearchCompaniesCriteria,
    setDetailSearchConctactsCriteria,
    setDetailSearchDiaryCriteria,
    setDetailSearchProjectsCriteria,
    setDetailSearchPropertiesCriteria,
} from '../store/search'
import { fetchDetailSearchLocationsCity, selectDetailSearchLocationsCountryFormOptions } from '../store/searchValues'
import {
    selectDetailSearchAdditionalLocationsCityFormOptions,
    selectDetailSearchAdditionalLocationsCountyFormOptions,
    selectDetailSearchAdditionalLocationsQuarterFormOptions,
    selectDetailSearchAdditionalLocationsWiderAreaFormOptions,
    selectDetailSearchLocationsCityFormOptions,
    selectDetailSearchLocationsCountyFormOptions,
    selectDetailSearchLocationsQIslandFormOptions,
    selectDetailSearchLocationsQuarterFormOptions,
    selectDetailSearchLocationsWiderAreaFormOptions,
    selectDetailSearchPropertySubTypesFormOptions,
    selectDetailSearchPropertyValuesDescriptionsrFormOptions,
    selectDetailSearchPropertyValuesEquipmentFormOptions,
    selectDetailSearchPropertyValuesFloorFormOptions,
    selectDetailSearchPropertyValuesSpaceFormOptions,
    selectDetailSearchPropertyValuesUtilityFormOptions,
} from '../store/searchValues/selectors'

// TODO: this function never accepts data? 
export const useDetailSearch = (type, data) => {
    const dispatch = useDispatch()
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [inputs, setInputs] = useState({})
    const [additionalInputs, setAdditionalInputs] = useState(null)
    const [validationConfig, setValidationConfig] = useState({})
    const validationConfigRef = useRef()
    const [additionalValidationConfig, setAdditionalValidationConfig] = useState(null)
    const [fetchCountAction, setFetchCountAction] = useState(() => null)
    const [detailSearchAction, setDetailSearchAction] = useState(() => null)
    const clearDetailSearchActionRef = useRef()
    const resetPagePaginationRef = useRef()
    const [redirectPage, setRedirectPage] = useState('')
    const { t } = useTranslation()
    const selectOptions = useSelector((state) => {
        switch (type) {
            case DETAIL_SEARCH_TYPE.PROPERTY:
                return {
                    [propertyDetailSearchFields.COUNTRY]: selectDetailSearchLocationsCountryFormOptions(state),
                    [propertyDetailSearchFields.COUNTY]: selectDetailSearchLocationsCountyFormOptions(state),
                    [propertyDetailSearchFields.CITY]: selectDetailSearchLocationsCityFormOptions(state),
                    [propertyDetailSearchFields.ISLAND]: selectDetailSearchLocationsQIslandFormOptions(state),
                    [propertyDetailSearchFields.WIDER_AREA]: selectDetailSearchLocationsWiderAreaFormOptions(state),
                    [propertyDetailSearchFields.QUARTER]: selectDetailSearchLocationsQuarterFormOptions(state),
                    [propertyDetailSearchFields.PROPERTY_TYPE]: selectPropertyTypesFormOptions(state),
                    [propertyDetailSearchFields.PROPERTY_SERVICE]: selectPropertyServicesFormOptions(state),
                    [propertyDetailSearchFields.PROPERTY_STATUS]: selectPropertyValuesStatusFormOptions(state),
                    [propertyDetailSearchFields.PROPERTY_ENERGY_EFFICIENCY]: selectPropertyValuesEnergyEfficiencyFormOptions(state),
                    [propertyDetailSearchFields.PROPERTY_CONDITION]: selectPropertyValuesConditionFormOptions(state),
                    [propertyDetailSearchFields.AGENT]: selectAgentsFormOption(state),
                    [propertyDetailSearchFields.WEB]: selectExportWebsitesFormOptions(state),
                    [propertyDetailSearchFields.PORTAL]: selectExportPortalsFormOptions(state),
                    [propertyDetailSearchFields.PROPERTY_SUB_TYPE]: selectDetailSearchPropertySubTypesFormOptions(state),
                    [propertyDetailSearchFields.PROPERTY_DESCRIPTIONS]: selectDetailSearchPropertyValuesDescriptionsrFormOptions(state),
                    [propertyDetailSearchFields.PROPERTY_UTILITY]: selectDetailSearchPropertyValuesUtilityFormOptions(state),
                    [propertyDetailSearchFields.PROPERTY_EQUIPMENT]: selectDetailSearchPropertyValuesEquipmentFormOptions(state),
                    [propertyDetailSearchFields.PROPERTY_SPACE]: selectDetailSearchPropertyValuesSpaceFormOptions(state),
                    [propertyDetailSearchFields.ATTRIBUTE]: WEBSITE_ATTRIBUTES?.map((item) => ({
                        value: item.attribute,
                        label: item.name,
                    })),
                    [propertyDetailSearchFields.PROPERTY_FLOOR]: selectDetailSearchPropertyValuesFloorFormOptions(state),
                }
            case DETAIL_SEARCH_TYPE.CONTACT:
                return {
                    [contactDetailSearchFields.AGENT]: selectAgentsFormOption(state),
                    [contactDetailSearchFields.CONTACT_COUNTRY]: selectContactValuesCountryFormOptions(state),
                    [contactDetailSearchFields.CONTACT_QUERY_CATEGORY]: selectContactValuesQueryCategoryFormOptions(state),
                    [contactDetailSearchFields.CONTACT_PROFILE_STATUS]: selectContactValuesProfileStatusFormOptions(state),
                    [contactDetailSearchFields.CONTACT_PROFILE_SERVICE]: selectContactValuesProfileServiceFormOptions(state),
                    [contactDetailSearchFields.DATA_SOURCE]: selectPropertyValuesDataSourceFormOptions(state),
                    [contactDetailSearchFields.CONTACT_PRIORITY]: selectContactValuesPriorityFormOptions(state),
                    [contactDetailSearchFields.CONTACT_STATUS]: selectContactValuesStatusFormOptions(state),
                }
            case DETAIL_SEARCH_TYPE.COMPANY:
                return {
                    [responseFields.AGENT]: selectAgentsFormOption(state),
                    [responseFields.CONTACT_COUNTRY]: selectContactValuesCountryFormOptions(state),
                    [responseFields.CONTACT_QUERY_CATEGORY]: selectContactValuesQueryCategoryFormOptions(state),
                    [responseFields.CONTACT_PROFILE_STATUS]: selectContactValuesProfileStatusFormOptions(state),
                    [responseFields.CONTACT_PROFILE_SERVICE]: selectContactValuesProfileServiceFormOptions(state),
                    [responseFields.DATA_SOURCE]: selectPropertyValuesDataSourceFormOptions(state),
                    [responseFields.CONTACT_PRIORITY]: selectContactValuesPriorityFormOptions(state),
                    [responseFields.CONTACT_STATUS]: selectContactValuesStatusFormOptions(state),
                }
            case DETAIL_SEARCH_TYPE.PROJECT:
                return {
                    [projectDetailSearchFields.PROJECT_TYPE]: selectProjectValuesTypeFormOptions(state),
                    [projectDetailSearchFields.PROJECT_STATUS]: selectProjectValuesStatusFormOptions(state),
                    [projectDetailSearchFields.AGENT]: selectAgentsFormOption(state),
                    [projectDetailSearchFields.CITY]: selectDetailSearchLocationsCityFormOptions(state),
                }
            default:
                return {}
        }
    })

    const additionalSelectOptions = useSelector((state) => {
        switch (type) {
            case DETAIL_SEARCH_TYPE.CONTACT:
                return {
                    [responseFields.CONTACT_PROFILE_DURATION]: selectContactValuesProfileDurationFormOptions(state),
                    [responseFields.PROPERTY_TYPE]: selectPropertyTypesFormOptions(state),
                    [responseFields.PROPERTY_SUB_TYPE]: selectDetailSearchPropertySubTypesFormOptions(state),
                    [responseFields.COUNTRY]: selectDetailSearchLocationsCountryFormOptions(state),
                    [responseFields.COUNTY]: selectDetailSearchAdditionalLocationsCountyFormOptions(state),
                    [responseFields.CITY]: selectDetailSearchAdditionalLocationsCityFormOptions(state),
                    [responseFields.WIDER_AREA]: selectDetailSearchAdditionalLocationsWiderAreaFormOptions(state),
                    [responseFields.QUARTER]: selectDetailSearchAdditionalLocationsQuarterFormOptions(state),
                    [responseFields.PROPERTY_FLOOR]: selectDetailSearchPropertyValuesFloorFormOptions(state),
                }
            case DETAIL_SEARCH_TYPE.COMPANY:
                return {
                    [responseFields.CONTACT_PROFILE_DURATION]: selectContactValuesProfileDurationFormOptions(state),
                    [responseFields.PROPERTY_TYPE]: selectPropertyTypesFormOptions(state),
                    [responseFields.PROPERTY_SUB_TYPE]: selectDetailSearchPropertySubTypesFormOptions(state),
                    [responseFields.COUNTRY]: selectDetailSearchLocationsCountryFormOptions(state),
                    [responseFields.COUNTY]: selectDetailSearchAdditionalLocationsCountyFormOptions(state),
                    [responseFields.CITY]: selectDetailSearchAdditionalLocationsCityFormOptions(state),
                    [responseFields.WIDER_AREA]: selectDetailSearchAdditionalLocationsWiderAreaFormOptions(state),
                    [responseFields.QUARTER]: selectDetailSearchAdditionalLocationsQuarterFormOptions(state),
                    [responseFields.PROPERTY_FLOOR]: selectDetailSearchPropertyValuesFloorFormOptions(state),
                }
            default:
                return {}
        }
    })

    useEffect(() => {
        switch (type) {
            case DETAIL_SEARCH_TYPE.PROPERTY:
                setTitle(t('form.detailSearch.title.properties'))
                setInputs(propertyDetailSearchInputs())
                setValidationConfig(propertyDetailSearchValidation(data))
                validationConfigRef.current = propertyDetailSearchValidation(data)
                setDetailSearchAction(() => setDetailSearchPropertiesCriteria)
                clearDetailSearchActionRef.current = clearDetailSearchPropertiesCriteria
                resetPagePaginationRef.current = resetPageProperties
                setFetchCountAction(() => fetchPropertiesNoStore)
                setRedirectPage('properties')
                setAdditionalInputs(null)
                setAdditionalValidationConfig(null)
                dispatch(
                    clearDetailSearchCompaniesCriteria(),
                    clearDetailSearchContactsCriteria(),
                    clearDetailSearchDiaryCriteria(),
                    clearDetailSearchProjectsCriteria(),
                    resetPageProperties()
                )
                break
            case DETAIL_SEARCH_TYPE.CONTACT:
                setTitle(t('form.detailSearch.title.contact'))
                setSubtitle(t('form.detailSearch.subTitle.contact'))
                setInputs(contactDetailSearchInputs())
                setAdditionalInputs(contactProfileDetailSearchInputs())
                setValidationConfig(contactDetailSearchValidation(data))
                validationConfigRef.current = contactDetailSearchValidation(data)
                setAdditionalValidationConfig(contactProfileDetailSearchValidation(data))
                setFetchCountAction(() => fetchContactsNoStore)
                setDetailSearchAction(() => setDetailSearchConctactsCriteria)
                clearDetailSearchActionRef.current = clearDetailSearchContactsCriteria
                resetPagePaginationRef.current = resetPageContact
                setRedirectPage('contacts')
                dispatch(
                    clearDetailSearchPropertiesCriteria(),
                    clearDetailSearchCompaniesCriteria(),
                    clearDetailSearchDiaryCriteria(),
                    clearDetailSearchProjectsCriteria(),
                    resetPageContact()
                )
                break
            case DETAIL_SEARCH_TYPE.COMPANY:
                setTitle(t('form.detailSearch.title.company'))
                setSubtitle(t('form.detailSearch.subTitle.company'))
                setInputs(companyDetailSearchInputs())
                setAdditionalInputs(companyProfileDetailSearchInputs())
                setValidationConfig(companyDetailSearchValidation(data))
                validationConfigRef.current = companyDetailSearchValidation(data)
                setAdditionalValidationConfig(companyProfileDetailSearchValidation(data))
                setFetchCountAction(() => fetchCompaniesNoStore)
                setDetailSearchAction(() => setDetailSearchCompaniesCriteria)
                clearDetailSearchActionRef.current = clearDetailSearchCompaniesCriteria
                resetPagePaginationRef.current = resetPageCompanies
                setRedirectPage('companies')
                dispatch(
                    clearDetailSearchPropertiesCriteria(),
                    clearDetailSearchContactsCriteria(),
                    clearDetailSearchDiaryCriteria(),
                    clearDetailSearchProjectsCriteria(),
                    resetPageCompanies()
                )
                break
            case DETAIL_SEARCH_TYPE.PROJECT:
                setTitle(t('form.detailSearch.title.projects'))
                setInputs(projectDetailSearchInputs())
                setValidationConfig(projectDetailSearchValidation(data))
                validationConfigRef.current = projectDetailSearchValidation(data)
                setFetchCountAction(() => fetchProjectsNoStore)
                setDetailSearchAction(() => setDetailSearchProjectsCriteria)
                clearDetailSearchActionRef.current = clearDetailSearchProjectsCriteria
                resetPagePaginationRef.current = resetPageProjects
                setRedirectPage('projects')
                setAdditionalInputs(null)
                setAdditionalValidationConfig(null)
                dispatch(fetchDetailSearchLocationsCity())
                dispatch(
                    clearDetailSearchPropertiesCriteria(),
                    clearDetailSearchContactsCriteria(),
                    clearDetailSearchCompaniesCriteria(),
                    clearDetailSearchDiaryCriteria(),
                    resetPageProjects()
                )
                break
            case DETAIL_SEARCH_TYPE.DIARY:
                setTitle(t('form.detailSearch.title.diary'))
                setInputs(diaryDetailSearchInputs())
                validationConfigRef.current = diaryDetailSearchValidation(data)
                setFetchCountAction(() => fetchDiariesNoStore)
                setDetailSearchAction(() => setDetailSearchDiaryCriteria)
                clearDetailSearchActionRef.current = clearDetailSearchDiaryCriteria
                setRedirectPage('diary')
                dispatch(fetchDetailSearchLocationsCity())
                dispatch(
                    clearDetailSearchPropertiesCriteria(),
                    clearDetailSearchContactsCriteria(),
                    clearDetailSearchCompaniesCriteria(),
                    clearDetailSearchProjectsCriteria()
                )
                break
            default:
                break
        }
    }, [type])

    return {
        title,
        inputs,
        subtitle,
        additionalInputs,
        validationConfig,
        validationConfigRef,
        additionalValidationConfig,
        selectOptions,
        additionalSelectOptions,
        fetchCountAction,
        detailSearchAction,
        clearDetailSearchActionRef,
        redirectPage,
        resetPagePaginationRef,
    }
}
