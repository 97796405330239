import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ValidationObject } from '../../types'
import { createValidationSchema } from '../validationSchema'
import { convertEndpointDataInFormikData, findConnectedQuestionsIdsToDelete, parseNumber } from '../../helpers'
import DynamicUI from '../../DynamicUI/DynamicUI'
import { useCalculateRisk } from '../../hooks'
import { useEffect, useRef, useState } from 'react'
import { Card, CardBody, Col, Container, Form } from 'reactstrap'
import Select, { SingleValue } from 'react-select'
import { selectAgentsFormOption } from '../../../../store/agents'
import PageTitle from '../../../Common/PageTitle'
import FormActionButtons from '../../../Common/FormActionButtons'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../store/auth/selectors'
import inDepthAnalysis from '../../../../api/inDepthAnalysis/hooks'
import { FormSubmissionAnswers, FormSubmission, DepthAnalysisSections, DepthAnalysisQuestions } from '../../../../api/inDepthAnalysis/types'
import { InDepthAnalysisCreateEditRenderFormProps } from './InDepthAnalysisCreateEditRenderForm.types'
import BadgeRiskAssessment from '../../BadgeRiskAssessment/BadgeRiskAssessment'

const InDepthAnalysisCreateEditRenderForm = (props: InDepthAnalysisCreateEditRenderFormProps) => {
    const { sections, isEdit, formSubmisson, contact, id, setSections, formSubmissionid, form } = props
    const history = useHistory()
    const sectionRefs = useRef<HTMLDivElement[]>([])
    const selectRefs = useRef<HTMLDivElement[]>([])
    const queryParams = new URLSearchParams(location.search)
    const formId = queryParams.get('formId') as string

    const { mutate: createUpdateMutate } = inDepthAnalysis.CreateUpdateFormsSubmission.useMutation(id, formSubmissionid, isEdit ? true : false)

    const [connectedQuestionsIdsToDelete] = useState(findConnectedQuestionsIdsToDelete(form))

    const { agents, currentAgent } = useSelector((state) => ({
        agents: selectAgentsFormOption(state),
        currentAgent: selectCurrentUser(state),
    }))

    let defaultAgent
    let initialValues

    if (isEdit) {
        defaultAgent = agents.find((option) => option.agentId === formSubmisson?.agent_id)
        initialValues = convertEndpointDataInFormikData(formSubmisson?.answers) ?? {}
    } else {
        defaultAgent = agents.find((option) => option.agentId === currentAgent?.id)
        initialValues = {}
    }

    const [agentId, setAgentId] = useState(defaultAgent?.agentId)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object(createValidationSchema(sections)),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: ValidationObject) => {
            const output: FormSubmissionAnswers[] = Object.entries(values).map(([key, value]) => {
                const type = key.split('_')[2]
                switch (type) {
                    case 'boolean':
                        return {
                            question_id: Number(key.split('_')[1]),
                            selectedOption: value,
                        }
                    case 'string':
                        return {
                            question_id: Number(key.split('_')[1]),
                            stringValue: value,
                        }
                    case 'text':
                        return {
                            question_id: Number(key.split('_')[1]),
                            textValue: value,
                        }
                    case 'select':
                        return {
                            question_id: Number(key.split('_')[1]),
                            selectedOption: value,
                        }
                    case 'date':
                        return {
                            question_id: Number(key.split('_')[1]),
                            dateValue: value,
                        }
                    case 'float':
                        return {
                            question_id: Number(key.split('_')[1]),
                            floatValue: parseNumber(value.toString()),
                        }
                    default:
                        throw new Error(`Unexpected type: ${type}`)
                }
            })

            const submitValues: FormSubmission = {
                form_id: formId,
                contact_id: id,
                riskAssessment: riskAssessment.risk,
                agent_id: agentId,
                answers: [...output],
            }
            createUpdateMutate(submitValues)
        },
    })

    useEffect(() => {
        for (const key in formik.values) {
            if (Object.hasOwn(formik.errors, key)) {
                delete formik.errors[key]
            }
            if (formik.values[key] === '') {
                delete formik.values[key]
            }
        }
    }, [formik.errors, formik.values])

    const { riskAssessment } = useCalculateRisk(formik.values ?? {}, sections)

    const updateRequiredBySection = (sectionId: number, newValue: boolean) => {
        setSections((prevSections: DepthAnalysisSections[]) =>
            prevSections?.map((section: { id: number; questions: DepthAnalysisQuestions[] }) =>
                section.id === sectionId
                    ? {
                          ...section,
                          questions: section.questions.map((question: DepthAnalysisQuestions) => ({
                              ...question,
                              required: newValue,
                          })),
                      }
                    : section
            )
        )
    }

    const updateRequiredByQuestion = (sectionId: number, questionId?: number, newValue?: boolean) => {
        setSections((prevSections: DepthAnalysisSections[]) =>
            prevSections?.map((section: { id: number; questions: DepthAnalysisQuestions[] }) =>
                section.id === sectionId
                    ? {
                          ...section,
                          questions: section.questions.map((question: DepthAnalysisQuestions) => {
                              const condition =
                                  question.id === questionId
                                      ? {
                                            ...question,
                                            required: newValue ?? false,
                                        }
                                      : {
                                            ...question,
                                        }
                              return condition
                          }),
                      }
                    : section
            )
        )
    }

    const renderSectionsAndQuestions = sections
        ?.sort((a, b) => (a?.priority ?? 0) - (b?.priority ?? 0))
        ?.flatMap((section: DepthAnalysisSections, sectionIndex: number) => {
            return [
                <div
                    key={`section_${section.id}`}
                    ref={(el) => {
                        if (el) return (sectionRefs.current[section.id] = el)
                    }}
                    style={{
                        display: `${section.hidden ? 'none' : 'block'}`,
                    }}
                    id={`section_${section.id}`}
                >
                    <h3 className="pt-4" key={`category-${sectionIndex}`}>
                        {section.title}
                    </h3>
                    <div className="d-flex" key={`list-${sectionIndex}`} style={{ flexWrap: 'wrap' }}>
                        {section.questions.map((question: DepthAnalysisQuestions, questionIndex: number) => {
                            return (
                                <DynamicUI
                                    sectionRefs={sectionRefs}
                                    selectRefs={selectRefs}
                                    key={`item-${sectionIndex}-${questionIndex}`}
                                    question={question}
                                    questionIndex={questionIndex}
                                    errors={formik.errors}
                                    formik={formik}
                                    questions={section.questions}
                                    connectedQuestionsIdsToDelete={connectedQuestionsIdsToDelete}
                                    updateRequiredBySection={updateRequiredBySection}
                                    updateRequiredByQuestion={updateRequiredByQuestion}
                                />
                            )
                        })}
                    </div>
                </div>,
            ]
        })
    const renderFormButtons = () => (
        <FormActionButtons
            disabled={false}
            hasCancel={true}
            cancelAction={() =>
                history.push({
                    pathname: contact?.contactType === 'person' ? `/contacts/${id}` : `/companies/${id}`,
                    state: { activeTab: 'inDepthAnalysis' },
                })
            }
        />
    )

    return (
        <div className="page-content">
            <Container fluid>
                <Col>
                    <div className="page-title-box ps-0 d-sm-flex align-items-start mt-3">
                        <div>
                            <PageTitle className="px-2" title={isEdit ? `Uredi dubinsku analizu` : `Nova dubinska analiza`} />
                            <Col sm="12" className="d-flex align-items-center">
                                {contact?.contactType === 'person' ? (
                                    <div className="px-2 d-flex align-items-center">
                                        <span className="fw-medium me-2">Kontakt: </span>
                                        {contact?.contactPerson?.firstName} {contact?.contactPerson?.lastName}
                                    </div>
                                ) : (
                                    <div className="px-2 d-flex align-items-center">
                                        <span className="fw-medium me-2">Poduzeće: </span>
                                        {contact?.contactCompany?.name}
                                    </div>
                                )}

                                <div className="px-2 d-flex align-items-center">
                                    <span className="fw-medium me-2">Ocjena rizičnosti: </span>
                                    <BadgeRiskAssessment riskAssessment={riskAssessment.risk} />
                                </div>
                            </Col>
                        </div>
                    </div>
                    <Form className="my-3" id="inDepthAnalysis" onSubmit={(e) => formik.handleSubmit(e)}>
                        {renderFormButtons()}
                        <Card className="my-3">
                            <CardBody className="p-4">
                                <div>
                                    <h3 className="pt-4">AGENT</h3>
                                    <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                                        <div className="d-flex align-items-end justify-content-between p-3" style={{ flexBasis: '100%' }}>
                                            <div className="d-flex" style={{ flexBasis: '50%' }}>
                                                <Select
                                                    className="w-100"
                                                    onChange={(
                                                        e: SingleValue<{
                                                            value: unknown
                                                            label: string
                                                            agentId: unknown
                                                            agencyOfficeId: unknown
                                                        }>
                                                    ) => {
                                                        setAgentId(e?.agentId)
                                                    }}
                                                    options={agents}
                                                    defaultValue={defaultAgent}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {renderSectionsAndQuestions}
                            </CardBody>
                        </Card>
                        {renderFormButtons()}
                    </Form>
                </Col>
            </Container>
        </div>
    )
}

export default InDepthAnalysisCreateEditRenderForm
