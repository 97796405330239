import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getReportByType } from './functions'
import { ReportCriteria, ReportType, ReportTypeResponseMap } from './types/globalTypes'

const reportTypes = {
    getReport: {
        useQuery: <T extends ReportType>(
            type: T,
            criteria: ReportCriteria,
            refreshToken: string,
            options?: UseQueryOptions<ReportTypeResponseMap[T], AxiosError>
        ) => {
            return useQuery({
                queryKey: [type, criteria, refreshToken],
                queryFn: () => getReportByType(type, criteria),
                // staleTime: 60 * 1000,
                ...options,
            })
        },
    },
}

export default reportTypes
