import { cloneDeep } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { MODAL_TYPES } from '../../../../common/constants'
import { hideModal, showModal } from '../../../../store/modal'
import RichTextEditor from '../../../RichTextEditor/RichTextEditor'
import ImageCarousel from '../ImageCarousel/ImageCarousel'
import { htmlTemplate1, htmlTemplate2, htmlTemplate3, htmlTemplate4, htmlTemplate5, htmlTemplate6, htmlTemplate7, htmlTemplate8 } from '../templates'
import styles from './ShopWindowPrintModal.module.css'
import { findEntityTitle } from '../../../../utils/general'
import { propertyResponseFields } from '../../../../common/response/property'

const ShopWindowPrint = ({ isOpen, ...modalProps }) => {
    const [clonedProperties, setClonedProperties] = useState([])
    const [templateData, setTemplateData] = useState('')
    const [templatePhotos, setTemplatePhotos] = useState([])
    const [templateProps, setTemplateProps] = useState(null)
    const [carouselOpen, setCarouselOpen] = useState(false)
    const [carouselPhotos, setCarouselPhotos] = useState([])
    const [editedContent, setEditedContent] = useState(null)
    const [clickedPhotoData, setClickedPhotoData] = useState({ targetPhotoIndex: '', selectedPropertyId: '' })

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const MODAL_VERTICAL_HEIGHT = 1150
    const MODAL_HORIZONTAL_HEIGHT = 850

    const { SHOPWINDOW_PRINT_MODAL, TEMPLATES_MODAL } = MODAL_TYPES
    const { properties, selectedTemplate } = modalProps[SHOPWINDOW_PRINT_MODAL] ?? {}

    const verticalTemplates = ['template-1', 'template-2', 'template-3', 'template-4']
    const isModalVertical = selectedTemplate && verticalTemplates.includes(selectedTemplate)

    let print

    setTimeout(() => {
        print = document.querySelector('[aria-label="Print"]')
    }, 1000)

    useEffect(() => {
        const clonedProperties = cloneDeep(properties)
        setClonedProperties(clonedProperties)
    }, [properties])

    const selectedId = useMemo(() => clickedPhotoData.selectedPropertyId, [clickedPhotoData.selectedPropertyId])

    // handle template & carousel photos
    useEffect(() => {
        const selectedProperties = properties?.length
        const photos = properties?.flatMap((property) => property.photos)
        const clonedPhotos = clonedProperties?.flatMap((property) => property.photos)
        const values = [2, 4, 6]

        switch (true) {
            case selectedProperties === 1: {
                setTemplatePhotos(photos)
                setCarouselPhotos(clonedPhotos)
                break
            }
            // 2, 4, or 6 selected properties
            case values.includes(selectedProperties): {
                const propertyPhotoBatch = photos.filter((photo) => photo.propertyId === +selectedId)
                setTemplatePhotos(propertyPhotoBatch)

                const carouselPhotoBatch = clonedPhotos?.filter((photo) => photo.propertyId === +selectedId)
                setCarouselPhotos(carouselPhotoBatch)
                break
            }
            default:
                break
        }
    }, [properties, clonedProperties, selectedId])

    const slicePhotos = useCallback(
        (endIndex) => {
            const props = properties.map((property) => {
                const { photos, title, phoneNumber, website, ...rest } = property
                const slicedPhotos = photos.slice(0, endIndex)
                const backupTitle = findEntityTitle(property, propertyResponseFields.PROPERTY_TITLE)

                return {
                    ...rest,
                    title: title ? title : backupTitle,
                    website: website ?? "",
                    phoneNumber: phoneNumber ?? "",
                    photos: slicedPhotos,
                }
            })

            return props
        },
        [properties]
    )

    // handle template props
    useEffect(() => {
        const selectedProperties = properties?.length
        const backupTitle = findEntityTitle(properties?.[0], propertyResponseFields.PROPERTY_TITLE)
        
        if (!templatePhotos) return
        
        switch (true) {
            case selectedProperties === 1: {
                const { id, internalAppId, title, description, type, service, area, price, floorNo, bedrooms, logo, website, phoneNumber, qrCode } = properties[0]
                const props = {
                    templatePhotos,
                    id,
                    internalAppId,
                    title: title ? title : backupTitle,
                    description,
                    type,
                    service,
                    area,
                    bedrooms,
                    price,
                    floorNo,
                    logo,
                    qrCode,
                    website: website ?? "",
                    phoneNumber: phoneNumber ?? "",
                }
                setTemplateProps(props)
                break
            }
            case selectedProperties === 2: {
                const props = slicePhotos(3)
                setTemplateProps(props)
                break
            }
            case selectedProperties === 4:
            case selectedProperties === 6: {
                const props = slicePhotos(1)
                setTemplateProps(props)
                break
            }
            default:
                break
        }
    }, [properties, templatePhotos, editedContent, slicePhotos])

    const icons = useMemo(
        () => ({
            id: {
                src: '../images/templates/icon-id.png',
            },
            area: {
                src: '../images/templates/icon-area.png',
            },
            bedrooms: {
                src: '../images/templates/icon-bedrooms.png',
            },
            floor: {
                src: '../images/templates/icon-floor.png',
            },
        }),
        []
    )

    // handle template display
    useEffect(() => {
        if (!templateProps) return

        let template

        switch (selectedTemplate) {
            case 'template-1':
                template = htmlTemplate1(templateProps)
                setTemplateData(template)
                break
            case 'template-2':
                template = htmlTemplate2(templateProps, editedContent)
                setTemplateData(template)
                break
            case 'template-3':
                template = htmlTemplate3(templateProps, editedContent)
                setTemplateData(template)
                break
            case 'template-4':
                template = htmlTemplate4(templateProps, editedContent)
                setTemplateData(template)
                break
            case 'template-5':
                template = htmlTemplate5(templateProps, editedContent, icons)
                setTemplateData(template)
                break
            case 'template-6':
                template = htmlTemplate6(templateProps, editedContent, icons)
                setTemplateData(template)
                break
            case 'template-7':
                template = htmlTemplate7(templateProps, editedContent, icons)
                setTemplateData(template)
                break
            case 'template-8':
                template = htmlTemplate8(templateProps, editedContent, icons)
                setTemplateData(template)
                break
            default:
                console.log(`Template ${selectedTemplate} is not available`)
        }
    }, [templateProps, selectedTemplate, editedContent, icons])

    const handleClose = () => {
        const showModalPayload = {
            modalType: TEMPLATES_MODAL,
        }

        const hideModalPayload = {
            modalType: SHOPWINDOW_PRINT_MODAL,
        }

        dispatch(hideModal(hideModalPayload))
        dispatch(showModal(showModalPayload))
    }

    const handleCarousel = (photoIndex, propertyId) => {
        if (carouselOpen) {
            setCarouselOpen(false)
            setClickedPhotoData({})
        } else {
            setCarouselOpen(true)
            setClickedPhotoData({ targetPhotoIndex: photoIndex, selectedPropertyId: propertyId })
        }
    }

    // tinymce returns html string of edited content
    // when photo changes in template, previously edited content is gone, so this will handle preserve
    const preserveEditedContent = (content) => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(content, 'text/html')

        const matchData = content.match(/template-(\d+)-/)
        const templateNumber = +matchData[1]

        switch (templateNumber) {
            case 1:
            case 2:
            case 3: {
                const contentClassname = templateNumber === 3 ? '.template-3-content' : '.template-content'
                const templateContent = doc.querySelector(contentClassname)
                const editedContent = templateContent?.outerHTML
                setEditedContent(editedContent)
                break
            }
            case 4: {
                const editedTitles = []
                const titleElements = doc.querySelectorAll('.template-4-title')
                titleElements.forEach((el) => {
                    const title = el.textContent
                    editedTitles.push(title)
                })

                const footerElement = doc.querySelector('.template-footer')
                const editedFooter = footerElement?.outerHTML

                setEditedContent({ editedTitles, editedFooter })
                break
            }
            case 5:
            case 6: {
                const templateContent = doc.querySelector(`.template-${templateNumber}-content-container`)
                const editedContent = templateContent?.outerHTML
                setEditedContent(editedContent)
                break
            }
            case 7:
            case 8: {
                const editedData = []
                const contentClassname = `.template-${templateNumber}-content`
                const contentElements = doc.querySelectorAll(contentClassname)
                contentElements.forEach((el) => {
                    const editedContent = el.outerHTML
                    editedData.push(editedContent)
                })

                // 7 & 8 share some css
                const footerElement = doc.querySelector('.template-multiple-footer')
                const editedFooter = footerElement?.outerHTML

                setEditedContent({ editedData, editedFooter })
                break
            }

            default:
                console.log(`Template ${templateNumber} is not available`)
        }
    }

    const changePhoto = (e) => {
        const selectedPhotoId = e.target.id
        const { targetPhotoIndex } = clickedPhotoData

        const changedPhotos = templatePhotos.reduce((acc, curr, index) => {
            if (index === +targetPhotoIndex) {
                curr.id = +selectedPhotoId
            }
            acc.push(curr)
            return acc
        }, [])

        setTemplatePhotos(changedPhotos)
    }

    return (
        <Modal isOpen={isOpen} className={isModalVertical ? styles.modalVertical : styles.modalHorizontal} centered>
            <div className={styles.modalHeaderContainer}>
                <ModalHeader>{t('modal.shopWindowPrint.title')}</ModalHeader>
                <div>
                    <Button type="submit" color="send" className="btn-label me-2" onClick={() => print.click()}>
                        <i className="mdi mdi-printer-outline align-bottom label-icon align-middle fs-16 me-2"></i>
                        {t('app.common.print')}
                    </Button>
                    <Button type="button" color="primary" className="btn-label" data-bs-dismiss="modal" onClick={handleClose}>
                        <i className="mdi mdi-close label-icon align-middle fs-16"></i>
                        {t('button.cancel')}
                    </Button>
                </div>
            </div>
            <ModalBody>
                {carouselOpen && (
                    <>
                        <div className={styles.btnCloseContainer}>
                            <Button type="button" className={styles.btnClose} onClick={handleCarousel}>
                                <i className="mdi mdi-close fs-16"></i>
                            </Button>
                        </div>
                        <ImageCarousel photos={carouselPhotos} changePhoto={changePhoto} />
                    </>
                )}
                <RichTextEditor
                    content={templateData}
                    height={isModalVertical ? MODAL_VERTICAL_HEIGHT : MODAL_HORIZONTAL_HEIGHT}
                    handleCarousel={handleCarousel}
                    preserveEditedContent={preserveEditedContent}
                />
            </ModalBody>
        </Modal>
    )
}

export default ShopWindowPrint