import { formatNumberWithTwoDecimals } from '../../../utils/general'
import { API_URL } from '../../../common/constants'

const photoUrl = `${API_URL}v1/file/show/`

export const htmlTemplate1 = (props) => {
    const { templatePhotos, logo, internalAppId, title, type, service, area, bedrooms, price, description, phoneNumber, website, qrCode } = props
    const primaryPhoto = templatePhotos?.[0]
    const showBedrooms = type === 'Stan' || type === 'Kuća'

    return `     
            <div class="template">
                ${logo ? `<img class="template-logo" src="${logo}">` : ''}
                <div class="template-1-img-container img-container">
                    <img src="${photoUrl}${primaryPhoto?.id}"> 
                </div>  
                <div class="template-content">
                    <h1>ID-${internalAppId} - ${title}</h1>
                    <div class="template-details">
                        <div>
                            <div>${type}, ${service}</div> 
                            <div>Površina: ${area}m²</div> 
                            ${showBedrooms ? `<div>Spavaće sobe: ${bedrooms ? bedrooms : '-'}</div>` : ''} 
                        </div>
                        ${qrCode ? `<img src="${qrCode}" class="template-vertical-qrCode" />` : ''}
                    </div>
                    <h2>${formatNumberWithTwoDecimals(price)}€</h2>
                    ${description ? `<div class="template-description">${description}</div>` : ''}
                    <div class="template-footer">
                        ${phoneNumber ? `<p class="template-phoneNumber">${phoneNumber}</p>` : ''}
                        ${website ? `<p class="template-website">${website}</p>` : ''}
                    </div>
                </div>
            </div> 
            `
}

export const htmlTemplate2 = (props, editedContent) => {
    const { templatePhotos, logo, internalAppId, title, type, service, area, bedrooms, price, description, phoneNumber, website, qrCode } = props
    const photos = templatePhotos?.slice(0, 3)
    const showBedrooms = type === 'Stan' || type === 'Kuća'

    return `     
            <div class="template">
                ${logo ? `<img class="template-logo" src="${logo}">` : ''}
                <div class="template-2-img-container img-container">
                    <div class="template-2-item-big"><img id="${photos[0]?.id}" src="${photoUrl}${photos[0]?.id}" data-photo-index="0"></div>
                    <div class="template-2-item-small">
                        <div><img id="${photos[1]?.id}" src="${photoUrl}${photos[1]?.id}" data-photo-index="1"></div>
                        <div><img id="${photos[2]?.id}" src="${photoUrl}${photos[2]?.id}" data-photo-index="2"></div>
                    </div>      
                </div>
                ${
                    editedContent
                        ? editedContent
                        : `<div class="template-content">
                            <h1>ID-${internalAppId} - ${title}</h1>
                            <div class="template-details">
                                <div>
                                    <div>${type}, ${service}</div> 
                                    <div>Površina: ${area}m²</div> 
                                    ${showBedrooms ? `<div>Spavaće sobe: ${bedrooms ? bedrooms : '-'}</div>` : ''} 
                                </div>
                                ${qrCode ? `<img src="${qrCode}" class="template-vertical-qrCode" />` : ''}
                            </div>
                            <h2>${formatNumberWithTwoDecimals(price)}€</h2>
                            <div class="template-description">${description}</div>
                            <div class="template-footer">
                                ${phoneNumber ? `<p class="template-phoneNumber">${phoneNumber}</p>` : ''}
                                ${website ? `<p class="template-website">${website}</p>` : ''}
                            </div>
                       </div>`
                }
            </div> 
            `
}

export const htmlTemplate3 = (props, editedContent) => {
    const { templatePhotos, logo, internalAppId, title, phoneNumber, website } = props
    const primaryPhoto = templatePhotos?.[0]

    return `<div class="template-3">
                ${logo ? `<img class="template-logo" src="${logo}">` : ''}
                <div class="template-3-img-container img-container">
                    <img src="${photoUrl}${primaryPhoto?.id}"> 
                </div>  
                ${
                    editedContent
                        ? editedContent
                        : `<div class="template-3-content">
                            <h1>ID-${internalAppId} - ${title}</h1>
                            <div class="template-footer">
                                ${phoneNumber ? `<p class="template-phoneNumber">${phoneNumber}</p>` : ''}
                                ${website ? `<p class="template-website">${website}</p>` : ''}
                            </div>
                       </div>`
                }
            </div>`
}

export const htmlTemplate4 = (props, editedContent) => {
    const { logo, phoneNumber, website } = props[0]
    const { editedTitles, editedFooter } = editedContent ?? {}

    const photoTags = props.map(
        (prop, index) => `
            <div class="template-4-img-container img-container">
                <div class="template-4-item-big">
                    <img id="${prop.photos?.[0].id}" src="${photoUrl}${prop.photos?.[0].id}" data-property-id="${prop.id}" data-photo-index="0">
                </div>
                <div class="template-4-item-small">
                    <div><img id="${prop.photos[1]?.id}" src="${photoUrl}${prop.photos[1]?.id}" data-property-id="${
            prop.id
        }" data-photo-index="1"></div>
                    <div><img id="${prop.photos[2]?.id}" src="${photoUrl}${prop.photos[2]?.id}" data-property-id="${
            prop.id
        }" data-photo-index="2"></div>
                </div>
            </div>
            <h1 class="template-4-title">${editedTitles ? editedTitles[index] : `ID-${prop.internalAppId} - ${prop.title}`}</h1>`
    )

    return `     
            <div class="template-4">
                ${logo ? `<img class="template-logo" src="${logo}">` : ''}
                ${photoTags} 
                ${
                    editedFooter
                        ? editedFooter
                        : `<div class="template-footer">
                        ${phoneNumber ? `<p class="template-phoneNumber">${phoneNumber}</p>` : ''}
                        ${website ? `<p class="template-website">${website}</p>` : ''}
                    </div>`
                }
            </div> 
            `
}

export const htmlTemplate5 = (props, editedContent, icons) => {
    const { templatePhotos, logo, internalAppId, title, type, phoneNumber, website, price, area, bedrooms, floorNo, qrCode } = props
    const primaryPhoto = templatePhotos?.[0]
    const showBedrooms = type === 'Stan' || type === 'Kuća'

    return `     
            <div class="template">
                <div class="template-5-img-container img-container">
                    <img src="${photoUrl}${primaryPhoto?.id}"> 
                </div>  
                ${
                    editedContent
                        ? editedContent
                        : ` <div class="template-5-content-container">
                           ${logo ? `<div class="template-5-logo"><img src="${logo}"></div>` : ''} 
                           ${
                               phoneNumber || website
                                   ? ` <div class="template-5-contact">
                                            ${qrCode ? `<img src="${qrCode}" class="template-horizontal-qrCode"/>` : ''}
                                            <div>${phoneNumber}</div>
                                            <div>${website}</div>
                                        </div>`
                                   : ''
                           }
                            <div class="template-5-content">
                                <h2>ID-${internalAppId} - ${title}</h2>
                                <div class="template-5-details">
                                    <h2>${formatNumberWithTwoDecimals(price)}€</h2>
                                    <div>
                                        <img src="${icons.area.src}" class="template-icon"/>
                                        <span>${area}㎡</span>
                                    </div>
                                    ${
                                        showBedrooms
                                            ? `<div>
                                                    <img src="${icons.bedrooms.src}" class="template-icon"/>
                                                    <span>${bedrooms ? bedrooms : '-'}</span>
                                                </div>`
                                            : ''
                                    }
                                    <div>
                                        <img src="${icons.floor.src}" class="template-icon"/>
                                        <span>${floorNo ? floorNo : '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>`
                }
            </div> 
            `
}

export const htmlTemplate6 = (props, editedContent, icons) => {
    const { templatePhotos, logo, internalAppId, title, area, bedrooms, price, floorNo, description, phoneNumber, website, qrCode } = props
    const photos = templatePhotos?.slice(0, 3)

    return `     
            <div class="template">
                <div class="template-6-img-container img-container">
                    <div class="template-6-item-big"><img id="${photos[0]?.id}" src="${photoUrl}${photos[0]?.id}" data-photo-index="0"></div>
                    <div class="template-6-item-small">
                        <div><img id="${photos[1]?.id}" src="${photoUrl}${photos[1]?.id}" data-photo-index="1"></div>
                        <div><img id="${photos[2]?.id}" src="${photoUrl}${photos[2]?.id}" data-photo-index="2"></div>
                    </div>      
                </div>
                ${
                    editedContent
                        ? editedContent
                        : `<div class="template-6-content-container">
                        <div class="template-6-top">
                           ${
                               logo || qrCode
                                   ? `<div class="template-6-graphics">
                                            ${logo ? `<img src="${logo}" class="template-6-logo">` : ''}
                                            ${qrCode ? `<img src="${qrCode}" class="template-horizontal-qrCode"/>` : ''}   
                                        </div>`
                                   : ''
                           }
                            <div class="template-6-content">
                                <h2>ID-${internalAppId} - ${title}</h2>
                                <div class="template-6-details">
                                    <h2>${formatNumberWithTwoDecimals(price)}€</h2>
                                    <div>
                                        <img src="${icons.area.src}" class="template-icon"/>
                                        <span>${area}㎡</span>
                                    </div>
                                    <div>
                                        <img src="${icons.bedrooms.src}" class="template-icon"/>
                                        <span>${bedrooms ? bedrooms : '-'}</span>
                                    </div>
                                    <div>
                                        <img src="${icons.floor.src}" class="template-icon"/>
                                        <span>${floorNo ? floorNo : '-'}</span>
                                    </div>
                                </div>
                            </div>       
                        </div>
                        <div class="template-6-bottom">
                            ${
                                phoneNumber || website
                                    ? `<div class="template-6-contact">
                                            <div>${phoneNumber}</div>
                                            <div>${website}</div>
                                       </div>`
                                    : ''
                            }
                            ${description ? `<div class="template-6-description">${description}</div>` : ''}
                        </div>     
                    </div>`
                }
            </div> 
            `
}

export const htmlTemplate7 = (props, editedContent, icons) => {
    const { logo, phoneNumber, website, qrCode } = props[0]
    const { editedData, editedFooter } = editedContent ?? {}

    const photoTags = props
        ?.map((prop, index) => {
            const showBedrooms = prop.type === 'Stan' || prop.type === 'Kuća'

            return `<div class="template-7-item">
                            <img id="${prop.photos[0].id}" src="${photoUrl}${prop.photos[0].id}" data-property-id="${prop.id}" data-photo-index="0">
                            ${
                                editedData?.length
                                    ? editedData[index]
                                    : `<div class="template-7-content">
                                            <div class="template-multiple-top">
                                                <h3>${prop.type}</h3>
                                                <h3>${prop.service}</h3>
                                            </div>
                                            <div class="template-multiple-details">
                                                <div class="template-7-icon">
                                                    <img src="${icons.id.src}"/>
                                                    <h5>${prop.internalAppId}</h5>
                                                </div>
                                                <div class="template-7-icon">
                                                    <img src="${icons.area.src}"/>
                                                    <h5>${prop.area}㎡</h5>
                                                </div>
                                                ${
                                                    showBedrooms
                                                        ? `<div class="template-7-icon">
                                                                <img src="${icons.bedrooms.src}"/>
                                                                <h5>${prop.bedrooms ? prop.bedrooms : '-'}</h5>
                                                            </div>`
                                                        : ''
                                                }
                                            </div>
                                            <div>${formatNumberWithTwoDecimals(prop.price)}€</div>
                                        </div>`
                            }
                    </div>`
        })
        .slice(0, 4)
        .join('')

    const initialFooter = `<div class="template-multiple-footer">
                                ${logo ? `<img src="${logo}" class="template-logo">` : ''} 
                                <div class="template-multiple-contact">
                                    <div>${phoneNumber}</div>
                                    <div>${website}</div>
                                </div>
                                ${qrCode ? `<img src="${qrCode}" class="template-horizontal-qrCode"/>` : ''}
                           </div>`

    return `<div class="template">
                <div class="template-multiple-grid img-container">${photoTags}</div>  
                ${editedFooter ? editedFooter : initialFooter}
            </div>`
}

export const htmlTemplate8 = (props, editedContent, icons) => {
    const { logo, phoneNumber, website, qrCode } = props[0]
    const { editedData, editedFooter } = editedContent ?? {}

    const photoTags = props
        ?.map((prop, index) => {
            const showBedrooms = prop.type === 'Stan' || prop.type === 'Kuća'

            return `<div class="template-8-item">
                            <img id="${prop.photos[0].id}" src="${photoUrl}${prop.photos[0].id}" data-property-id="${prop.id}" data-photo-index="0">
                            ${
                                editedData?.length
                                    ? editedData[index]
                                    : `<div class="template-8-content">
                                            <div class="template-multiple-top">
                                                <h3>${prop.type}</h3>
                                                <h3>${prop.service}</h3>
                                            </div>
                                            <div class="template-multiple-details">
                                                <div class="template-8-icon">
                                                    <img src="${icons.id.src}"/>
                                                    <h5>${prop.internalAppId}</h5>
                                                </div>
                                                <div class="template-8-icon">
                                                    <img src="${icons.area.src}"/>
                                                    <h5>${prop.area}㎡</h5>
                                                </div>
                                                ${
                                                    showBedrooms
                                                        ? `<div class="template-8-icon">
                                                                <img src="${icons.bedrooms.src}"/>
                                                                <h5>${prop.bedrooms ? prop.bedrooms : '-'}</h5>
                                                            </div>`
                                                        : ''
                                                }
                                            </div>
                                            <div>${formatNumberWithTwoDecimals(prop.price)}€</div>
                                        </div>`
                            }
                        </div>`
        })
        .slice(0, 6)
        .join('')

    const initialFooter = `<div class="template-multiple-footer">
                                ${logo ? `<img src="${logo}" class="template-logo">` : ''}    
                                <div class="template-multiple-contact">
                                    <div>${phoneNumber}</div>
                                    <div>${website}</div>
                                </div>
                                ${qrCode ? `<img src="${qrCode}" class="template-horizontal-qrCode"/>` : ''}
                            </div> `

    return `     
            <div class="template">
                <div class="template-multiple-grid img-container">${photoTags}</div>  
                ${editedFooter ? editedFooter : initialFooter}
            </div> 
            `
}
