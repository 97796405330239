import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
    createUpdateFormsSubmissions,
    getAnalysisPdf,
    getCompanyAssociateById,
    getFormById,
    getFormsSubmission,
    getFormSubmissionById,
} from './functions'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import i18n from '../../i18n'
import { invalidateQueryInTable } from '../helpers'
import { ContactsConnectedToCompanyData, DepthAnalysis, FormSubmission, FormSubmissionData } from './types'
import { PageListParams } from '../globalTypes'

const inDepthAnalysis = {
    GetFormsSubmission: {
        useQuery: (
            id: string,
            payload: PageListParams,
            fetchForms: boolean,
            refreshToken: string | null,
            options?: UseQueryOptions<FormSubmissionData, AxiosError>
        ) =>
            useQuery({
                queryKey: ['formsSubmissionList', id, payload.page, payload.limit, payload.order, refreshToken],
                queryFn: () => getFormsSubmission(id, payload),
                enabled: fetchForms,
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    GetFormSubmissionById: {
        useQuery: (id: string, refreshToken: string | null, options?: UseQueryOptions<FormSubmission, AxiosError>) =>
            useQuery({
                queryKey: ['formSubmissionEdit', id, refreshToken],
                queryFn: () => getFormSubmissionById(id),
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    GetFormById: {
        useQuery: (id: string, refreshToken: string | null, options?: UseQueryOptions<DepthAnalysis, AxiosError>) =>
            useQuery({
                queryKey: ['formCreate', id, refreshToken],
                queryFn: () => getFormById(id),
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    CreateUpdateFormsSubmission: {
        useMutation: (
            id: string,
            fromSubmissionId?: string,
            isEdit?: boolean,
            options?: UseMutationOptions<FormSubmission, AxiosError, FormSubmission>
        ) => {
            const toastId = isEdit ? id : 'new'
            const history = useHistory()

            return useMutation({
                ...options,
                onMutate: () => {
                    toast.loading(i18n.t('toast.loading') as string, { toastId: toastId })
                },
                mutationFn: (data) => createUpdateFormsSubmissions(data, fromSubmissionId, isEdit),
                onSuccess: (_data, context) => {
                    invalidateQueryInTable('formsSubmissionList')
                    toast.update(toastId, {
                        render: i18n.t('toast.update.success') as string,
                        type: 'success',
                        autoClose: 3000,
                        isLoading: false,
                    })

                    !isEdit &&
                        history.push({
                            pathname: `/${context.form_id === '1' ? 'contacts' : 'companies'}/${context.contact_id}`,
                            state: { activeTab: 'inDepthAnalysis' },
                        })
                },
                onError: (error) => {
                    toast.update(toastId, {
                        render: i18n.t('toast.update.error') as string,
                        type: 'error',
                        autoClose: 3000,
                        isLoading: false,
                    })
                    console.error(error)
                },
            })
        },
    },
    GetCompanyAssociateById: {
        useQuery: (
            id: string,
            payload: PageListParams,
            fetchForms: boolean,
            refreshToken: string | null,
            options?: UseQueryOptions<ContactsConnectedToCompanyData, AxiosError>
        ) =>
            useQuery({
                queryKey: ['companyAssociate', id, payload.page, payload.limit, payload.order, refreshToken],
                queryFn: () => getCompanyAssociateById(id, payload),
                enabled: fetchForms,
                staleTime: 1 * 60 * 1000,
                ...options,
            }),
    },
    GetAnalysisPdf: {
        useQuery: (id: string | undefined, refreshToken: string | null, options?: UseQueryOptions<string, AxiosError>) =>
            useQuery({
                queryKey: ['createAnalysisPdf', id, refreshToken],
                queryFn: () => getAnalysisPdf(id),
                enabled: id !== '',
                staleTime: 0,
                ...options,
            }),
    },
}

export default inDepthAnalysis
