import { Button } from 'reactstrap'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DownloadPdfFileProps } from './DownloadPdfFile.types'
import { useSelector, useDispatch } from 'react-redux'
import { selectBase64String, selectFetchingStatusBase64, downloadDiaryDocument, downloadDiaryDocumentResetStatus } from '../../../store/diaries'

const DownloadPdfFile = (props: DownloadPdfFileProps) => {
    const { t } = useTranslation()
    const { id } = props
    const dispatch = useDispatch()

    const { base64, fetchingStatus } = useSelector((state) => ({
        base64: selectBase64String(state),
        fetchingStatus: selectFetchingStatusBase64(state),
    }))

    const handleDownloadDiaray = () => {
        dispatch(downloadDiaryDocument(id))
    }

    const handlebase64Data = useCallback(() => {
        const byteCharacters = atob(base64)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024)
            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }
            byteArrays.push(new Uint8Array(byteNumbers))
        }

        const blob = new Blob(byteArrays, { type: 'application/pdf' })

        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${t('form.diary.sideBarTitle')}_${id}`

        link.click()
    }, [base64, id, t])

    useEffect(() => {
        if (fetchingStatus === 'success') {
            handlebase64Data()
            dispatch(downloadDiaryDocumentResetStatus())
        }
    }, [dispatch, fetchingStatus, handlebase64Data])

    return (
        <Button tag="a" onClick={handleDownloadDiaray} color="primary" className="btn-label" disabled={false} download={true}>
            <i className="mdi mdi-download align-bottom label-icon align-middle fs-16 me-2"></i>
            {t('button.download')}
        </Button>
    )
}

export default DownloadPdfFile
