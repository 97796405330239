import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout'
import VerticalLayout from '../Layouts/index'
import AdminLayout from '../Layouts/AdminLayout/AdminLayout'
//routes
import { authProtectedRoutes, publicRoutes, adminProtectedRoutes } from './allRoutes'
import { AuthProtected, AccessRoute } from './AuthProtected'

const Index = () => {
    return (
        <React.Fragment>
            <Switch>
                {publicRoutes.map((route, idx) => (
                    <Route path={route.path} key={idx} exact={true}>
                        <NonAuthLayout>
                            <route.component />
                        </NonAuthLayout>
                    </Route>
                ))}

                {adminProtectedRoutes.map((route, idx) => (
                    <Route path={route.path} key={idx} exact={true}>
                        <AuthProtected>
                            <AdminLayout>
                                <AccessRoute path={route.path} component={route.component} />
                            </AdminLayout>
                        </AuthProtected>
                    </Route>
                ))}

                {authProtectedRoutes.map((route, idx) => (
                    <Route path={route.path} key={idx} exact={true}>
                        <AuthProtected>
                            <VerticalLayout>
                                <AccessRoute path={route.path} component={route.component} />
                            </VerticalLayout>
                        </AuthProtected>
                    </Route>
                ))}

                <Route path="*">
                    <AuthProtected>
                        <Redirect to="/projects" />
                    </AuthProtected>
                </Route>
            </Switch>
        </React.Fragment>
    )
}

export default Index
