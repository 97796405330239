import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Accordion, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import TableCheckbox from '../../Components/Table/TableCheckbox'
import { MODAL_TYPES } from '../../common/constants'
import { propertyResponseFields } from '../../common/response/property'
import { contactProfileViewData } from '../../common/views/contactViewData'
import { getModalPayloads } from '../../pages/Properties/PropertyList/ModalPayloads/payloads'
import { deleteContactProfile, selectContactProfiles, fetchContactProfiles } from '../../store/contactProfiles'
import { showModal } from '../../store/modal'
import { fetchPropertiesContactProfile, removePropertiesContactProfile } from '../../store/properties'
import {
    selectPropertiesContactProfile,
    selectPropertiesContactProfileCount,
    selectPropertiesContactProfileFetching,
} from '../../store/properties/selectors'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../store/settings/selectors'
import { selectCheckedTableRows } from '../../store/table'
import { formatNumberWithTwoDecimals } from '../../utils/general'
import { renderCurrency } from '../../utils/property'
import DeleteModal from '../Common/DeleteModal'
import ContactProfileAccordionItem from '../ContactProfileAccordionItem'
import QuickViewModal from '../Modals/QuickViewModal'
import TableContainer from '../Table/TableContainer'

const ContactProfileViewTab = ({ id, contactType }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { properties, propertiesFetching, propertyCount, areaUnit, currency, selectedTableRows, contactProfile } = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        areaUnit: selectApplicationAreaUnit(state),
        selectedTableRows: selectCheckedTableRows(state),
        properties: selectPropertiesContactProfile(state),
        propertiesFetching: selectPropertiesContactProfileFetching(state),
        propertyCount: selectPropertiesContactProfileCount(state),
        contactProfile: selectContactProfiles(state),
    }))
    const [profile, setProfile] = useState(null)
    const [profileDeleteModal, setProfileDeleteModal] = useState(false)
    const [activeProfile, setActiveProfile] = useState('')
    const [quickViewModal, setQuickViewModal] = useState(false)
    const [quickViewData, setQuickViewData] = useState(null)
    const [pageCount, setPageCount] = useState(0)
    const [customFetch, setCustomFetch] = useState(false)

    const toggleAccordion = (profileId) => {
        if (activeProfile === profileId) {
            setActiveProfile('')
            dispatch(removePropertiesContactProfile())
            setPageCount(0)
        } else {
            setActiveProfile(profileId)
            setCustomFetch(true)
            dispatch(
                fetchPropertiesContactProfile({ page: 1, limit: 20, criteria: { contactProfile: profileId } }, (data) => {
                    setPageCount(Math.ceil(data.count / 20))
                })
            )
        }
    }

    useEffect(() => {
        dispatch(removePropertiesContactProfile())
    }, [dispatch])

    useEffect(() => {
        dispatch(fetchContactProfiles(id))
    }, [dispatch, id])

    const toggleQuickViewModal = (data) => {
        if (quickViewModal) {
            setQuickViewModal(false)
            setQuickViewData(null)
        } else {
            setQuickViewModal(true)
            setQuickViewData(data)
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: ({ getToggleAllRowsSelectedProps }) => <TableCheckbox {...getToggleAllRowsSelectedProps()} />,
                Cell: ({ row }) => {
                    return <TableCheckbox {...row.getToggleRowSelectedProps()} />
                },
                id: '#',
                minWidth: 45,
            },
            {
                Header: t('app.common.id'),
                id: propertyResponseFields.INTERNAL_APP_ID,
                accessor: propertyResponseFields.INTERNAL_APP_ID,
                filterable: false,
                minWidth: 60,
            },
            {
                Header: t('app.common.type'),
                accessor: `${propertyResponseFields.PROPERTY_TYPE}.name`,
                filterable: false,
                minWidth: 70,
            },
            {
                Header: t('app.common.city'),
                accessor: `${propertyResponseFields.CITY}.name`,
                filterable: true,
                minWidth: 180,
            },
            {
                Header: t('app.common.street'),
                accessor: `${propertyResponseFields.WIDER_AREA}.name`,
                id: `${propertyResponseFields.WIDER_AREA}`,
                filterable: true,
                minWidth: 300,
            },
            {
                Header: t('table.header.profile.price', { currency: renderCurrency(currency) }),
                id: `${propertyResponseFields.PRICE}`,
                filterable: true,
                Cell: (item) => <>{item.row.original?.price ? formatNumberWithTwoDecimals(item.row.original.price) : ''}</>,
                minWidth: 150,
            },
            {
                Header: '',
                id: 'actions',
                thDivClass: 'text-center',
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0 justify-content-end">
                            <li className="list-inline-item">
                                <div
                                    title={t('button.view')}
                                    className="btn text-warning d-inline-block p-0"
                                    onClick={() => toggleQuickViewModal(cellProps.row.original)}
                                >
                                    <i className="mdi mdi-eye-outline fs-16"></i>
                                </div>
                            </li>
                        </ul>
                    )
                },
                minWidth: 40,
            },
        ],
        [t, toggleQuickViewModal]
    )

    const offerFunctionAction = (modalType, actions = []) => {
        const selectedRowsIds = selectedTableRows.map((row) => row.id)

        const payload = getModalPayloads(modalType, properties, selectedRowsIds)

        if (payload) {
            actions.forEach((action) => dispatch(action(payload)))
        }
    }

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            <UncontrolledDropdown className="">
                <DropdownToggle title={t('table.showMore')} href="#" className="btn btn-primary dropdown" tag="button">
                    <i className="mdi mdi-dots-horizontal align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                        onClick={() => offerFunctionAction(MODAL_TYPES.OFFER_PRINT_MODAL, [showModal])}
                        disabled={!selectedTableRows.length}
                    >
                        <i className="mdi mdi-file-document-multiple-outline align-bottom me-2 text-muted"></i> {t('app.common.offerOrPrint')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(
            fetchPropertiesContactProfile({ page, limit, order, criteria: { ...criteria, contactProfile: activeProfile } }, (data) => {
                setPageCount(Math.ceil(data.count / limit))
            })
        )
    }

    const handleRowClick = (id) => {
        history.push(`/properties/${id}`)
    }

    const onProfileClickDelete = (profileData) => {
        setProfile(profileData)
        setProfileDeleteModal(true)
    }

    const handleDeleteNote = () => {
        if (profile) {
            dispatch(
                deleteContactProfile({
                    contactId: id,
                    id: profile.id,
                })
            )
            setProfile(null)
            setProfileDeleteModal(false)
        }
    }

    return (
        <>
            <DeleteModal show={profileDeleteModal} onDeleteClick={() => handleDeleteNote()} onCloseClick={() => setProfileDeleteModal(false)} />
            <Row>
                <Col sm={12} xxl={3}>
                    <Card>
                        <CardHeader>
                            <span className="fw-medium text-primary fs-13">{t('view.contact.profile.title')}</span>
                        </CardHeader>
                        <CardBody className="pt-2">
                            {contactProfile.map((profile) => {
                                const profileId = profile.id.toString()
                                const profileViewData = contactProfileViewData(profile, {
                                    currency,
                                    areaUnit,
                                })

                                return (
                                    <Accordion key={profile.id} open={activeProfile} toggle={toggleAccordion}>
                                        <ContactProfileAccordionItem
                                            item={profile}
                                            history={history}
                                            profileId={profileId}
                                            id={id}
                                            contactType={contactType}
                                            activeProfile={activeProfile}
                                            profileViewData={profileViewData}
                                            handleDelete={onProfileClickDelete}
                                        />
                                    </Accordion>
                                )
                            })}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} xxl={9}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <TableContainer
                                        columns={columns}
                                        data={properties || []}
                                        isGlobalFilter={false}
                                        isAddUserList={false}
                                        customPageSize={20}
                                        theadClass={'dmn-table-header'}
                                        hasPageSizeSelect={false}
                                        isLoading={propertiesFetching}
                                        tableActions={tableActions}
                                        handleDataFetch={handleDataFetch}
                                        handleRowClick={handleRowClick}
                                        totalCount={propertyCount}
                                        pageCount={pageCount}
                                        noResultMessage={t('table.noResultMessage')}
                                        customFetch={customFetch}
                                        tableHash={'propertiesContactProfile'}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <QuickViewModal isOpen={quickViewModal} toggle={toggleQuickViewModal} data={quickViewData} />
        </>
    )
}

export default ContactProfileViewTab
