import values from 'lodash/values'

import { getQueryData } from '../../api/helpers'
import { stateKey } from './actions'

export const selectContacts = (state) => values(state[stateKey].items)
export const selectContactsAsyncFetching = (state) => state[stateKey].asyncFetching
export const selectContactsAsyncItems = (state) => state[stateKey].asyncItems
export const selectContactsFetching = (state) => state[stateKey].fetching
export const selectContactsCount = (state) => state[stateKey].count
export const selectContactFetchingDetails = (state) => state[stateKey].fetchingDetails
export const selectContact = (state) => state[stateKey].item
export const selectContactsPagination = (state) => state[stateKey].pagination
export const selectContactLinks = (state) => state[stateKey].links

export const selectContactFetchingDocuments = (state) => state[stateKey].fetchingDocuments
export const selectContactsDocumentCount = (state) => state[stateKey].itemdocumentcount

export const selectContactDocument = (state) => values(state[stateKey].itemdocument)
export const selectContactDocumentQue = (state) => values(state[stateKey].documentque)

// used in activity modal
export const setContactNameField = (contactId) => {
    const data = getQueryData(['contacts', contactId])

    if (!data) return []
    const { item } = data

    if (item) {
        return [
            {
                value: item.id,
                label: `${item.contactPerson?.firstName} ${item.contactPerson?.lastName}`,
            },
        ]
    }

    return []
}

// used in activity modal
export const setContactCompanyNameField = (contactId) => {
    const data = getQueryData(['contacts', contactId])

    if (!data) return []
    const { item } = data
    const contactPersonCompany = item?.contactPersonCompany

    if (contactPersonCompany?.length) {
        return [
            {
                value: item?.id,
                label: contactPersonCompany?.[0]?.company?.contactCompany?.name,
            },
        ]
    }

    return []
}

export const selectContactAsyncSelectedFormOptions = (state) => {
    const data = state[stateKey].item?.contactPersonCompany

    if (data?.length) {
        return data?.map((item) => ({
            label: item?.company?.contactCompany?.name,
            value: item?.company?.id?.toString(),
        }))
    }

    return []
}

export const selectContactAsyncDocumentContactFormOptions = (state) => {
    const data = state[stateKey].item?.contactPerson

    if (data) {
        return [
            {
                value: state[stateKey].item?.internalAppId,
                label: data?.firstName + ' ' + data?.lastName,
            },
        ]
    }

    return []
}
