import { setAllowedTabs } from '../AppTopSwitcher/utils'

export const setFeatures = (props) => {
    const { applicationModules, applicationModuleFeatures, feature, link } = props

    switch (feature) {
        case 'Navigation':
            return setNavigation({ applicationModules, link })
        case 'PrintForShopWindow':
            return setPrintForShopWindow({ applicationModuleFeatures })
        case 'AllowedTabs':
            return setAllowedFeatureTabs({ applicationModuleFeatures })
        case 'ActivitiesAccordion':
            return setActivitiesAccordion({ applicationModuleFeatures })
        default:
            break
    }
}

const setNavigation = ({ applicationModules, link }) => {
    let moduleName = link.split('/')[1]
    // TODO: remove if/when page url name is changed to intermediary-diary
    const token = {
        diary: 'intermediary-diary',
    }
    moduleName = token[moduleName] ? token[moduleName] : moduleName

    const availableLinks = applicationModules?.map((module) => module.token)
    const featureLinkAllowed = availableLinks.includes(moduleName)

    return { featureLinkAllowed }
}

const setPrintForShopWindow = ({ applicationModuleFeatures }) => {
    const feature = 'property.printForWindow'
    const hasFeature = setIsFeature(applicationModuleFeatures, feature)

    return { hasFeature }
}

const setAllowedFeatureTabs = ({ applicationModuleFeatures }) => {
    // add more tabs if needed, then destructure them in FormTabNavigation component
    const features = [{ ruleName: 'documents.tab', tabName: 'documents' }]
    const tabs = setAllowedTabs(applicationModuleFeatures, features, setIsFeature, 'featureTabs')

    return tabs
}

const setActivitiesAccordion = ({ applicationModuleFeatures }) => {
    const feature = 'activities.accordion'
    const allowAccordion = setIsFeature(applicationModuleFeatures, feature)

    return { featureActivitiesAccordion: allowAccordion }
}

// use this to define if feature is allowed or not
const setIsFeature = (featuresArray, featureName) => {
    return featuresArray.some((obj) => obj.token === featureName)
}
