import { useState } from 'react'
import { FormFeedback, Input } from 'reactstrap'
import { InputPriceProps } from './InputPrice.types'

const InputPrice = (props: InputPriceProps) => {
    const { text, required, isInvalid, questionName, formik, fieldError } = props

    const [rawInput, setRawInput] = useState('')

    const formatNumber = (value: string) => {
        if (!value) return ''

        value = value?.replace(/[^0-9,]/g, '')
        let [integer] = value.split(',')
        const [_, decimal] = value.split(',')
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

        return decimal !== undefined ? `${integer},${decimal}` : integer
    }

    const formatedDefaultValue = formik.values[questionName] && formatNumber(formik.values[questionName].toString())

    const handleInputChange = (e: { target: { value: string } }) => {
        const input = e.target.value
        const formatted = formatNumber(input)

        setRawInput(formatted)
        formik.setFieldValue(questionName, formatted)
    }

    return (
        <div className="d-flex flex-column justify-content-end" style={{ flexGrow: '1' }}>
            <span style={{ width: '100%', paddingBottom: '10px' }}>
                {text}
                {required && <span style={{ color: '#e82c2c' }}>*</span>}
            </span>
            <span className="d-flex justify-content-center align-items-center">
                <Input
                    invalid={isInvalid}
                    className="p-2 m-0"
                    name={questionName}
                    type="text"
                    value={formatedDefaultValue ?? rawInput}
                    onChange={(e) => handleInputChange(e)}
                />
            </span>
            {fieldError ? (
                <FormFeedback className="d-block" type="invalid">
                    {fieldError.value}
                </FormFeedback>
            ) : null}
        </div>
    )
}

export default InputPrice
