import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { RouteParams } from '../../../../api/globalTypes'
import { CellProps } from './TableColumns.types'
import BadgeRiskAssessment from '../../BadgeRiskAssessment/BadgeRiskAssessment'

const TableColumns = (handlePrint: (id: number) => void, formUrl: (id: string) => string) => {
    const Link = RouterLink as unknown as React.FC<{
        children: React.ReactNode
        title: string
        to: string
        className: string
        onClick?: () => void | string
    }>

    const { id } = useParams<RouteParams>()

    const { t } = useTranslation()

    const columns = [
        {
            Header: t('app.common.id'),
            accessor: 'id',
            filterable: true,
        },
        {
            Header: 'Ime i prezime agenta',
            id: `agent`,
            Cell: (cellProps: CellProps) => (
                <span>
                    {cellProps.row.original.agent.firstName} {cellProps.row.original.agent.lastName}
                </span>
            ),
            filterable: true,
        },
        {
            Header: 'Procjena rizika',
            id: `riskAssessment`,
            Cell: (cellProps: CellProps) => <BadgeRiskAssessment riskAssessment={cellProps?.row?.original?.riskAssessment} />,
            filterable: true,
        },
        {
            Header: t('app.common.creationDate'),
            id: `created`,
            Cell: (cellProps: CellProps) => dayjs(cellProps.row.original?.created).format('DD.MM.YYYY., HH:mm:ss'),
            filterable: true,
        },
        {
            id: 'actions',
            thDivClass: 'text-center',
            Cell: (cellProps: CellProps) => {
                return (
                    <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                        <li className="list-inline-item">
                            <span
                                title={t('app.common.print.title')}
                                className="text-info d-inline-block cursor-pointer"
                                onClick={() => handlePrint(cellProps.row.original?.id)}
                            >
                                <i className="mdi mdi-file-document-multiple-outline align-bottom me-2 text-muted"></i>
                            </span>
                        </li>

                        <li className="list-inline-item edit">
                            <Link
                                title={t('button.edit')}
                                to={formUrl(id) + `&edit=true&id=${cellProps.row.original?.id}`}
                                className="text-info d-inline-block edit-item-btn"
                            >
                                <i className="mdi mdi-pencil-outline fs-16"></i>
                            </Link>
                        </li>
                    </ul>
                )
            },
        },
    ]

    return columns
}

export default TableColumns
