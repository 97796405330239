import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import { formatDateTime } from '../../../../utils/general'
import DownloadPdfFile from '../../DownloadPdfFile/DownloadPdfFile'

const DiarySideBar = ({ id, diaries, sidebarClose, className, handleGetIdDiaryTable }) => {
    const property = diaries.find((x) => x.id === id)
    const history = useHistory()
    const { t } = useTranslation()

    return (
        <div className={`${className} p-3 py-0`}>
            <SimpleBar className="mx-n3 pt-3 px-3">
                <div className="h-100">
                    <div className="d-flex h-100 flex-column">
                        <div className="d-flex align-items-center pb-3 border-bottom border-bottom-dashed mb-3 gap-2">
                            <h5 className="flex-grow-1 fw-light mb-0">{t('form.diary.sideBarTitle')}</h5>
                            <div>
                                <button type="button" className="btn btn-dark btn-icon btn-sm fs-16 close-btn-overview" onClick={sidebarClose}>
                                    <i className="mdi mdi-close align-bottom"></i>
                                </button>
                            </div>
                        </div>

                        <div className="pb-3 border-bottom border-bottom-dashed mb-3">
                            <div className="file-details-box bg-light p-3 text-center rounded-3 border border-light mb-3">
                                <div className="display-4 file-icon">
                                    <i className="mdi mdi-file-document-outline" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 className="fs-12 fw-normal text-uppercase mb-2">{t('form.diary.sideBarDocument')}</h5>
                            <Row className={`align-items-start mb-2`}>
                                <Col sm={6}>
                                    <span className="flex-grow-1 fw-normal fs-12">{t('app.common.name')}:</span>
                                </Col>
                                <Col sm={6}>
                                    <span className="flex-grow-1 fw-light fs-12">{t('app.common.intermediaryDiary')}</span>
                                </Col>
                            </Row>

                            <Row className={`align-items-start mb-2`}>
                                <Col sm={6}>
                                    <span className="flex-grow-1 fw-normal fs-12">{t('app.common.propertyId')}:</span>
                                </Col>
                                <Col sm={6}>
                                    <span className="flex-grow-1 fw-light fs-12">{property?.internalAppPropertyId}</span>
                                </Col>
                            </Row>

                            <Row className={`align-items-start mb-2`}>
                                <Col sm={6}>
                                    <span className="flex-grow-1 fw-normal fs-12">{t('app.common.conclusionDate')}:</span>
                                </Col>
                                <Col sm={6}>
                                    <span className="flex-grow-1 fw-light fs-12">{formatDateTime(property?.intermediaryDiaryDate)}</span>
                                </Col>
                            </Row>
                        </div>
                        <div className="border-top border-top-dashed py-3">
                            <div className="hstack gap-2">
                                <DownloadPdfFile id={id} />
                                <Button color="info" className="btn-label" onClick={() => history.push(`/diary/${id}/edit`)}>
                                    <i className="mdi mdi-pencil-outline align-bottom label-icon align-middle fs-16 me-2" />
                                    {t('button.edit')}
                                </Button>
                                <Button color="danger" className="btn-label" onClick={() => handleGetIdDiaryTable(id, true)}>
                                    <i className="mdi mdi-trash-can-outline align-bottom label-icon align-middle fs-16 me-2"></i>
                                    {t('button.delete')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </SimpleBar>
        </div>
    )
}

export default DiarySideBar
