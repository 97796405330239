import i18n from '../../../i18n'
import { fetchProjectsAsyncInput } from '../../../store/projects/actions'
import { fetchPropertiesAsyncInput } from '../../../store/properties'
import {
    baseAsyncSelectInput,
    baseCheckboxInput,
    baseDateInput,
    baseSelectInput,
    baseTextAreaInput,
    baseTextInput,
    baseTimeInput,
} from '../fieldTypes'
import { activityFields } from './fields'

export const activityFieldTypes = () => {
    return {
        [activityFields.ID]: {
            ...baseTextInput,
            name: activityFields.ID,
            label: i18n.t('modal.activity.field.activityId'),
            disabled: true,
            colProps: {
                sm: 12,
            },
        },
        [activityFields.TITLE]: {
            ...baseTextInput,
            name: activityFields.TITLE,
            label: i18n.t('app.common.name'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [activityFields.ACTIVITY_TYPE_ID]: {
            ...baseSelectInput,
            name: activityFields.ACTIVITY_TYPE_ID,
            label: i18n.t('app.common.activityType'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [activityFields.DATE_FROM]: {
            ...baseDateInput,
            name: activityFields.DATE_FROM,
            colProps: {
                sm: 7,
                className: 'pe-0',
            },
        },
        [activityFields.TIME_FROM]: {
            ...baseTimeInput,
            name: activityFields.TIME_FROM,
            colProps: {
                sm: 5,
            },
        },
        [activityFields.DATE_TO]: {
            ...baseDateInput,
            name: activityFields.DATE_TO,
            colProps: {
                sm: 7,
                className: 'pe-0',
            },
        },
        [activityFields.TIME_TO]: {
            ...baseTimeInput,
            name: activityFields.TIME_TO,
            colProps: {
                sm: 5,
            },
        },
        [activityFields.DURATION_DAY]: {
            ...baseCheckboxInput,
            name: activityFields.DURATION_DAY,
            label: i18n.t('modal.activity.field.duration'),
            colProps: {
                sm: 6,
                className: 'd-flex align-items-center',
            },
        },
        [activityFields.COMPLETED]: {
            ...baseCheckboxInput,
            name: activityFields.COMPLETED,
            label: i18n.t('modal.activity.field.completed'),
            colProps: {
                sm: 6,
                className: 'd-flex align-items-center',
            },
            inputProps: {
                className: 'activity-status-check-input',
            },
        },
        [activityFields.ACTIVITY_LOCATION]: {
            ...baseTextInput,
            name: activityFields.ACTIVITY_LOCATION,
            label: i18n.t('app.common.location'),
            colProps: {
                sm: 12,
            },
        },
        [activityFields.CONTACT_COMPANY]: {
            type: 'contact-company-cascade',
            name: activityFields.CONTACT_COMPANY,
            inputs: [
                {
                    name: activityFields.CONTACT_ID,
                },
                {
                    name: activityFields.CONTACT_COMPANY_COLLECTION,
                },
            ],
        },
        [activityFields.PROPERTY_ID]: {
            ...baseAsyncSelectInput,
            name: activityFields.PROPERTY_ID,
            label: i18n.t('app.common.propertyId'),
            criteria: ['internalAppId'],
            action: fetchPropertiesAsyncInput,
            noLimit: true,
            isMulti: true,
            colProps: {
                sm: 6,
            },
        },
        [activityFields.PROJECT_ID]: {
            ...baseAsyncSelectInput,
            name: activityFields.PROJECT_ID,
            label: i18n.t('app.common.projectId'),
            criteria: ['internalAppId'],
            action: fetchProjectsAsyncInput,
            noLimit: true,
            isMulti: true,
            colProps: {
                sm: 6,
            },
        },
        [activityFields.AGENT_ID]: {
            ...baseSelectInput,
            name: activityFields.AGENT_ID,
            placeholder: i18n.t('app.common.contact.nameAndSurname'),
            colProps: {
                sm: 8,
            },
        },
        [activityFields.NOTIFICATION_SEND]: {
            ...baseCheckboxInput,
            name: activityFields.NOTIFICATION_SEND,
            label: i18n.t('modal.activity.field.notificationSend'),
            colProps: {
                sm: 4,
            },
        },
        [activityFields.REMINDER_TYPE]: {
            ...baseSelectInput,
            name: activityFields.REMINDER_TYPE,
            colProps: {
                sm: 8,
            },
        },
        [activityFields.REMINDER_SEND]: {
            ...baseCheckboxInput,
            name: activityFields.REMINDER_SEND,
            label: i18n.t('app.common.activity.reminder'),
            colProps: {
                sm: 4,
            },
        },
        [activityFields.NOTES]: {
            ...baseTextAreaInput,
            name: activityFields.NOTES,
            label: i18n.t('app.common.comment'),
            colProps: {
                sm: 12,
            },
            inputProps: {
                style: { resize: 'none', height: '90px' },
            },
        },
    }
}
