import QueryString from 'qs'
import { apiReq } from '..'
import { handleFilesFormData, priorityCompareFn } from '../../utils/general'
import { updateFile } from '../globalFunctions'
import { UpdateFileParams, UpdateFileResponse } from '../globalTypes'
import { getQueryData } from '../helpers'
import { handleUpdatePayload } from './helpers'
import { DeleteImageParams, Image, UpdateImageParams, UploadImageParams, UploadImageResponse } from './types'

export const getImagesById = async (id: string, module: string, collection: string) => {
    const paramsLink = `noLimit=1&order[priority]=asc&criteria[module]=${module}&criteria[collection]=${collection}`
    const paramsEncoded = QueryString.stringify(QueryString.parse(paramsLink))

    const response = await apiReq(`v1/file/parent/${id}?${paramsEncoded}`)
    return response?.data.items.sort(priorityCompareFn)
}

// on upload image priority is handled on backend
export const uploadImages = async (params: UploadImageParams) => {
    const { files, entityData, url, module, collection } = params
    const formData = handleFilesFormData(files, entityData, module, collection)

    const response = await apiReq(`v1/file/upload/${url}`, {
        method: 'POST',
        data: formData,
    })

    if (response.status !== 200) {
        throw new Error()
    }

    return response?.data as UploadImageResponse
}

const deleteImageById = async (id: string) => {
    const response = await apiReq(`v1/file/${id}`, {
        method: 'DELETE',
    })

    if (response.status !== 200) {
        throw new Error()
    }

    return response.data as UpdateFileResponse
}

export const deleteImages = async (params: DeleteImageParams) => {
    const { ids, collection, module, parent } = params
    const images = getQueryData([collection, module, parent]) as Image[]

    const imagesToDelete = ids?.map((id) => +id)
    const imagesToUpdate: UpdateImageParams[] = images
        .filter((image) => !imagesToDelete.includes(image.id))
        .map((image, index) => {
            const newPriority = index + 1

            return {
                id: image.id,
                collection: image.collection,
                module: image.module,
                parent: image.parent.toString(),
                priority: newPriority,
                isPriorityUpdate: image.priority !== newPriority,
            }
        })

    const response = await Promise.all(ids.map((id) => deleteImageById(id)))
    await updateImages(imagesToUpdate)

    return response
}

export const updateImages = async (images: UpdateImageParams[]) => {
    const payloads = images.map((image) => handleUpdatePayload(image)).filter((payload): payload is UpdateFileParams => payload !== null)
    const response = await Promise.all(payloads.map((payload) => updateFile(payload).catch((error) => ({ error }))))

    return response as UpdateFileResponse[]
}

export const syncImage = async (id: number) => {
    const response = await apiReq(`v1/file/sync/${id}`, {
        method: 'PUT',
    })

    if (response.status !== 200) {
        throw new Error()
    }

    return response
}
