import { Property } from '../../api/properties/types'

export const getFilteredProperties = (selectedProperties: Property[]) => {
    return selectedProperties.map((obj) => ({
        id: obj.id,
        internalAppId: obj.internalAppId,
        type: obj.propertyType?.name,
        service: obj.propertyService?.name,
        area: obj.area,
        price: obj.price,
        floorNo: obj.floorNo,
        bedrooms: obj.bedroomsNo,
        translations: obj.translations,
        propertySubType: { name: obj.propertySubType?.name },
        propertyService: { name: obj.propertyService?.name },
        city: { name: obj.city?.name },
        widerArea: { name: obj.widerArea.name },
    }))
}
