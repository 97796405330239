import values from 'lodash/values'
import { stateKey } from './actions'

export const selectDiaries = (state) => values(state[stateKey]?.items)
export const selectDiariesFetching = (state) => state[stateKey]?.fetching
export const selectDiariesCount = (state) => state[stateKey]?.count
export const selectDiariesPagination = (state) => state[stateKey]?.pagination
export const selectDiary = (state) => state[stateKey]?.item
export const selectDiaryFetching = (state) => state[stateKey]?.fetchingDetails
export const selectDiarySubmitting = (state) => state[stateKey]?.submitting
export const selectCheckedTableRows = (state) => state[stateKey].selectedTableRows
export const selectBase64String = (state) => state[stateKey].base64
export const selectFetchingStatusBase64 = (state) => state[stateKey].fetchingStatus
