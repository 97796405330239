import classNames from 'classnames'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Col, Label, Row } from 'reactstrap'
import FormActionButtons from '../../../Components/Common/FormActionButtons'
import { selectActivityValuesTypeFormOptions } from '../../../store/activityValues'
import { selectAgentsFormOption } from '../../../store/agents'
import { selectContactValuesStatusFormOptions } from '../../../store/contactValues'
import { selectPropertyValuesDataSourceFormOptions, selectPropertyValuesStatusFormOptions } from '../../../store/propertyValues'
import { FormikValue, ReportFilterFormProps } from './types'
import { handleFilterRender } from './utils'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Flatpickr = require('react-flatpickr').default

const ReportFilterForm = (props: ReportFilterFormProps) => {
    const { reportType, values, errors, touched, setFieldValue, handleSubmit, handleReset } = props
    const { t } = useTranslation()

    const {
        agents = [],
        activityTypes = [],
        contactStatuses = [],
        propertyStatuses = [],
        dataSources = [],
    } = useSelector((state) => ({
        agents: selectAgentsFormOption(state),
        activityTypes: selectActivityValuesTypeFormOptions(state), // TODO: maybe filter activities on CRM ?
        contactStatuses: selectContactValuesStatusFormOptions(state),
        propertyStatuses: selectPropertyValuesStatusFormOptions(state),
        dataSources: selectPropertyValuesDataSourceFormOptions(state),
    }))

    const filterProps = { reportType, agents, activityTypes, contactStatuses, propertyStatuses, dataSources, t }
    const selectData = [...handleFilterRender(filterProps)]

    const datePickerClassNames = () => {
        const isTouched = Object.keys(touched).length
        const isError = Object.keys(errors).length

        return classNames('form-control', {
            'border-danger': isTouched & isError,
        })
    }

    const showDateErrors = () => {
        const dateErrors = []
        const isTouched = Object.keys(touched).length

        if (isTouched && errors.dateFrom) dateErrors.push(errors.dateFrom)
        if (isTouched && errors.dateTo) dateErrors.push(errors.dateTo)

        return dateErrors
    }

    const getSelectValue = (fieldValue: string) => {
        const currentValues = values[fieldValue] as FormikValue[]
        return currentValues?.length ? currentValues : null
    }

    return (
        <form onSubmit={handleSubmit}>
            <Row className="mb-4">
                <Col>
                    <Label for="dateFrom">{t('app.common.dateFrom')}</Label>
                    <div className="flatpickr-container">
                        <Flatpickr
                            id="dateFrom"
                            className={datePickerClassNames()}
                            name="dateFrom"
                            options={{
                                dateFormat: 'd-m-Y',
                            }}
                            onChange={([date]: [Date]) => {
                                setFieldValue('dateFrom', dayjs(date).format('YYYY-MM-DD'))
                            }}
                            value={values.dateFrom ? new Date(values.dateFrom) : ''}
                        />
                    </div>
                </Col>
                <Col>
                    <Label for="dateTo">{t('app.common.dateTo')}</Label>
                    <div className="flatpickr-container">
                        <Flatpickr
                            id="dateTo"
                            className={datePickerClassNames()}
                            name="dateTo"
                            options={{
                                dateFormat: 'd-m-Y',
                            }}
                            onChange={([date]: [Date]) => {
                                setFieldValue('dateTo', dayjs(date).format('YYYY-MM-DD'))
                            }}
                            value={values.dateTo ? new Date(values.dateTo) : ''}
                        />
                    </div>
                </Col>
                {showDateErrors().length > 0 && (
                    <div className="mt-2 mx-2 text-danger">
                        {showDateErrors().map((error, index) => (
                            <div key={index} className="d-block">
                                {error}
                            </div>
                        ))}
                    </div>
                )}
                {selectData.map((data, index) => (
                    <div key={index}>
                        <Label for={`${data.fieldValue}-filter`} className="mt-3">
                            {data.label}
                        </Label>

                        <Select
                            onChange={(selectedOptions) => {
                                setFieldValue(data.fieldValue, (selectedOptions || []) as FormikValue[])
                            }}
                            options={data.options}
                            isMulti={true}
                            name={data.fieldValue}
                            id={`${data.fieldValue}-filter`}
                            value={getSelectValue(data.fieldValue)}
                            noOptionsMessage={() => t('form.common.select.noData')}
                            placeholder=""
                        />
                    </div>
                ))}
            </Row>

            <FormActionButtons hasCancel cancelAction={handleReset} />
        </form>
    )
}

export default ReportFilterForm
