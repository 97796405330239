import { DEFAULT_TRANSPARENCY } from "../../constants"

const WatermarkPreview = ({ watermarkSrc, watermarkTransparency, watermarkPosition }) => {
    const backgroundImage = `${process.env.PUBLIC_URL}/images/watermark/backgroundImage.jpg`
    const opacity = (watermarkTransparency || DEFAULT_TRANSPARENCY) / 100

    const watermarkPositionConfig = {
        top: { top: '10px', left: '50%', transform: 'translateX(-50%)' },
        bottom: { bottom: '10px', left: '50%', transform: 'translateX(-50%)' },
        center: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
        'top-left': { top: '10px', left: '10px' },
        'top-right': { top: '10px', right: '10px' },
        'bottom-left': { bottom: '10px', left: '10px' },
        'bottom-right': { bottom: '10px', right: '10px' },
    }

    const positionStyle = watermarkPositionConfig[watermarkPosition] || { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }

    return (
        <div className="position-relative">
            <img src={backgroundImage} className="w-100" />
            <img
                src={watermarkSrc}
                className="position-absolute w-25"
                style={{
                    opacity: opacity,
                    ...positionStyle,
                }}
            />
        </div>
    )
}

export default WatermarkPreview
