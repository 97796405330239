import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainer from '../../../Components/Table/TableContainer'
import { RowData } from '../globalTypes'
import { PropertyReportProps } from './types'
import { getPropertyOveralls } from './utils'

const PropertyReportTable = (props: PropertyReportProps) => {
    const { reportsData, reportsFetching } = props
    const { t } = useTranslation()

    const reports = useMemo(() => reportsData?.data || [], [reportsData])
    const months = useMemo(() => reportsData?.timePeriod || [], [reportsData])

    const columns = useMemo(() => {
        const dataSourceColumn = {
            Header: t('app.common.dataSource'),
            id: 'dataSource',
            accessor: (row: RowData) => row.dataSource?.name,
            filterable: false,
        }

        const monthColumns = months?.map(({ month, monthName, year }) => ({
            Header: monthName,
            id: `${month}/${year}`,
            accessor: (row: RowData) => {
                const propertyTotal = row.realizedPropertyTotals?.find((pt) => pt?.month === month)
                if (!propertyTotal) return null

                return (
                    <div className="d-flex gap-2 justify-content-center">
                        {propertyTotal.totalSale != null && <span>{propertyTotal.totalSale}</span>}
                        {propertyTotal.totalRent != null && <span className="text-danger">{propertyTotal.totalRent}</span>}
                    </div>
                )
            },
            filterable: false,
        }))

        const totalColumn = {
            Header: t('app.common.total'),
            id: 'dataSourceTotal',
            accessor: (row: RowData) => <div className="text-center fw-bolder">{row.realizedPropertyGrandTotal}</div>,
            filterable: false,
        }

        return [dataSourceColumn, ...monthColumns, totalColumn]
    }, [months, t])

    const tableFooter = useMemo(() => {
        const { monthOveralls, grandOverall } = getPropertyOveralls(reports, months)

        return (
            <tr>
                <td>{t('app.common.overall')}</td>
                {monthOveralls.map((overall, i) => (
                    <td key={i} className="text-center">
                        {overall.count}
                    </td>
                ))}
                <td className="text-center fw-bolder">{grandOverall}</td>
            </tr>
        )
    }, [reports, months, t])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            tableFooter={tableFooter}
            tableHash={'reports_data-source-properties'}
            hidePagination={true}
            {...({} as any)} // temporary fix
        />
    )
}

export default PropertyReportTable
