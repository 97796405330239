import { Container } from 'reactstrap'
import { useLocation, useParams } from 'react-router-dom'
import { RouteParams } from '../../../api/globalTypes'
import inDepthAnalysis from '../../../api/inDepthAnalysis/hooks'
import { useState, useEffect } from 'react'
import contacts from '../../../api/contacts/hooks'
import Loader from '../../Common/Loader'
import { updateRequiredInHiddenSectionsOnLoad, updateSectionsVisibilityOnLoad } from '../helpers'
import InDepthAnalysisCreateEditRenderForm from './InDepthAnalysisCreateEditRenderForm/InDepthAnalysisCreateEditRenderForm'
import { DepthAnalysisSections } from '../../../api/inDepthAnalysis/types'

const InDepthAnalysisCreateEdit = () => {
    const { id } = useParams<RouteParams>()
    const [sections, setSections] = useState<DepthAnalysisSections[]>([])

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const isEdit = JSON.parse(queryParams.get('edit') as string)
    const formSubmissionid = queryParams.get('id') as string
    const formId = queryParams.get('formId') as string

    const refreshToken = localStorage.getItem('refresh_token') as string

    const { isLoading: fetchingFormsSubmission, data: formSubmissionData } = inDepthAnalysis.GetFormSubmissionById.useQuery(
        formSubmissionid,
        refreshToken
    )
    const { isLoading: fetchingForms, data: formData } = inDepthAnalysis.GetFormById.useQuery(formId, refreshToken)
    const { isLoading: fetchingContact, data: contactData } = contacts.GetContact.useQuery(id, refreshToken)

    const form = !fetchingForms ? formData : undefined
    const formSubmisson = !fetchingFormsSubmission ? formSubmissionData : undefined
    const contact = !fetchingContact ? contactData?.item : null

    useEffect(() => {
        setSections(
            isEdit ? updateSectionsVisibilityOnLoad(form?.sections, formSubmisson?.answers) : updateRequiredInHiddenSectionsOnLoad(form?.sections)
        )
    }, [form, formSubmisson, isEdit])

    if (fetchingForms || fetchingFormsSubmission) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    return (
        <InDepthAnalysisCreateEditRenderForm
            id={id}
            sections={sections}
            isEdit={isEdit}
            formSubmisson={formSubmisson}
            contact={contact}
            setSections={setSections}
            formSubmissionid={formSubmissionid}
            form={form}
        />
    )
}

export default InDepthAnalysisCreateEdit
