import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Card, CardBody, Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import reportTypes from '../../../api/reports/hooks'
import { ReportCriteria } from '../../../api/reports/types/globalTypes'
import Loader from '../../../Components/Common/Loader'
import PageTitle from '../../../Components/Common/PageTitle'
import { formatDateTime } from '../../../utils/general'
import AgentActivityReportChart from '../AgentActivityReport/AgentActivityReportChart'
import { AgentActivityReportTable } from '../AgentActivityReport/AgentActivityReportTable'
import AgentContactStatusReportChart from '../AgentContactStatusReport/AgentContactStatusReportChart'
import AgentContactStatusReportTable from '../AgentContactStatusReport/AgentContactStatusReportTable'
import AgentRevenueReportChart from '../AgentRevenueReport/AgentRevenueReportChart'
import { AgentRevenueReportTable } from '../AgentRevenueReport/AgentRevenueReportTable'
import AgentTransactionReportChart from '../AgentTransactionReport/AgentTransactionReportChart'
import AgentTransactionReportTable from '../AgentTransactionReport/AgentTransactionReportTable'
import { defaultEndDate, defaultStartDate, ReportRoute } from '../constants'
import ContactReportChart from '../ContactReport/ContactReportChart'
import ContactReportTable from '../ContactReport/ContactReportTable'
import PropertyReportChart from '../PropertyReport/PropertyReportChart'
import PropertyReportTable from '../PropertyReport/PropertyReportTable'
import ReportListFilter from '../ReportListFilter/ReportListFilter'
import SelectReport from '../SelectReport'
import { ReportChartType, ReportParams, ReportTableType } from './types'

/* eslint-disable-next-line @typescript-eslint/no-var-requires */
const { Switch, Route } = require('react-router-dom')

const ReportComponent = {
    [ReportRoute.agentActivities]: {
        table: AgentActivityReportTable,
        chart: AgentActivityReportChart,
    },
    [ReportRoute.agentRevenues]: {
        table: AgentRevenueReportTable,
        chart: AgentRevenueReportChart,
    },
    [ReportRoute.agentTransactions]: {
        table: AgentTransactionReportTable,
        chart: AgentTransactionReportChart,
    },
    [ReportRoute.agentContactStatus]: {
        table: AgentContactStatusReportTable,
        chart: AgentContactStatusReportChart,
    },
    [ReportRoute.dataSourceContacts]: {
        table: ContactReportTable,
        chart: ContactReportChart,
    },
    [ReportRoute.dataSourceProperties]: {
        table: PropertyReportTable,
        chart: PropertyReportChart,
    },
}

const ReportList = () => {
    const { t } = useTranslation()

    document.title = `${t('app.common.reports')} | ${t('app.title')}`

    const initialCriteria = {
        dateFrom: defaultStartDate,
        dateTo: defaultEndDate,
        agents: [],
        activityTypes: [],
        contactStatuses: [],
        propertyStatuses: [],
        dataSources: [],
    }

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [searchCriteria, setSearchCriteria] = useState<ReportCriteria>(initialCriteria)

    const refreshToken = localStorage.getItem('refresh_token') as string

    const history = useHistory()
    const { path, url, params } = useRouteMatch<ReportParams>()
    const reportType = params.reportType || ReportRoute.agentActivities

    useEffect(() => {
        if (url === '/reports') {
            history.push(`/reports/${ReportRoute.agentActivities}`)
        }
    }, [url, history])

    const { data: reportsData, isLoading: reportsFetching } = reportTypes.getReport.useQuery(reportType, searchCriteria, refreshToken)

    const ReportTable = ReportComponent[reportType].table as ReportTableType<typeof reportType>
    const ReportChart = ReportComponent[reportType].chart as ReportChartType<typeof reportType>

    const handleFilterSubmit = (formValues: ReportCriteria) => {
        setSearchCriteria(formValues)
        setIsDropdownOpen(false)
    }

    const handleFilterReset = () => {
        setSearchCriteria(initialCriteria)
        setIsDropdownOpen(false)
    }

    const currentDateRange = useMemo(() => {
        if (searchCriteria) {
            const { dateFrom, dateTo } = searchCriteria

            const formattedDateFrom = formatDateTime(dateFrom)
            const formattedDateTo = formatDateTime(dateTo)

            return `[${formattedDateFrom} - ${formattedDateTo}]`
        }

        return null
    }, [searchCriteria])

    const tableActions = (
        <Row>
            <Col>
                <SelectReport />
            </Col>
            <Col xs="auto" className="ms-auto align-self-center">
                {currentDateRange}
            </Col>
            <Col xs="auto">
                <Dropdown isOpen={isDropdownOpen} toggle={() => null}>
                    <DropdownToggle className="btn btn-primary" tag="button" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <i className="mdi mdi-filter-variant align-middle pe-none"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-form">
                        <ReportListFilter
                            criteria={searchCriteria}
                            reportType={reportType}
                            onFilterSubmit={handleFilterSubmit}
                            onFilterReset={handleFilterReset}
                        />
                    </DropdownMenu>
                </Dropdown>
            </Col>
        </Row>
    )

    return (
        <div className="page-content">
            <Container fluid>
                <PageTitle title={t('app.common.reports')} />
                <Row>
                    <Col lg={12}>
                        {reportsFetching ? (
                            <Loader />
                        ) : (
                            <Card>
                                <CardBody>
                                    <Switch>
                                        <Route
                                            path={path}
                                            render={() => (
                                                <>
                                                    <ReportChart reportsData={reportsData} />
                                                    {tableActions}
                                                    <ReportTable reportsData={reportsData} reportsFetching={reportsFetching} />
                                                </>
                                            )}
                                        ></Route>
                                    </Switch>
                                </CardBody>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ReportList
