import { getUrlBase } from '../../../utils/general'
import { setPermissions } from './utils'

// module permissions controls actions (view, edit, delete) inside modules
export const setModulePermissions = ({ agentPermissions, isAgentOwnership, isAgentOffice, module, action, permissionName }) => {
     // diary table doesn't have checkboxes for permissions to control
     if (module === 'diary') {
        return { hasModulePermission: true }
    } 

    const { permission, name: actionName } = action ?? {}

    let hasModulePermission = false
    const moduleName = getUrlBase(module)

    permissionName = permissionName ?? `module.${moduleName}.${permission}`

    switch (permission) {
        case 'view':
        case 'edit':
            hasModulePermission = setPermissions(agentPermissions, permissionName, isAgentOwnership, isAgentOffice, module, actionName)
            break
        default:
            break
    }

    return { hasModulePermission }
}
