import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import AppPermissionsSwitcher from '../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import DeleteModal from '../../../Components/Common/DeleteModal'
import PageTitle from '../../../Components/Common/PageTitle'
import QuickViewModal from '../../../Components/Modals/QuickViewModal'
import TableContainer from '../../../Components/Table/TableContainer'
import { useFormatTableData } from '../../../Components/Table/utils'
import properties from '../../../api/properties/hooks'
import { MODAL_TYPES } from '../../../common/constants'
import { fetchAgents } from '../../../store/agents/actions'
import { showModal } from '../../../store/modal'
import { selectSearchCriteriaProperties } from '../../../store/search'
import { resetTableCheckbox, selectCheckedTableRows } from '../../../store/table'
import MapProperty from './MapProperty/MapProperty'
import { getModalPayloads } from './ModalPayloads/payloads'
import TableColumns from './TableColumns/TableColumns'

const PropertyList = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { OFFER_PRINT_MODAL, TEMPLATES_MODAL, CHANGE_AGENT_MODAL } = MODAL_TYPES
    const appModule = 'property'

    const { selectedTableRows, propertySearchCriteria } = useSelector((state) => ({
        propertySearchCriteria: selectSearchCriteriaProperties(state),
        selectedTableRows: selectCheckedTableRows(state),
    }))

    const totalPages = useRef()

    const [rowId, setRowId] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [quickViewModal, setQuickViewModal] = useState(false)
    const [quickViewData, setQuickViewData] = useState(null)
    const [showMap, setShowMap] = useState(false)
    const [propertiesParams, setPropertiesParams] = useState({ page: history.location.state?.page ?? 1 })

    const refreshToken = localStorage.getItem('refresh_token')

    const fetchData =
        (propertiesParams.page !== 0 &&
            propertiesParams.limit !== 0 &&
            propertiesParams.order &&
            Object.keys(propertiesParams.criteria ?? {}).length > 0) ??
        false

    const {
        isLoading: fetchingProperties,
        data: propertiesData,
        isFetched,
    } = properties.GetProperties.useQuery(propertiesParams, refreshToken, fetchData)

    const { mutate: deleteMutate } = properties.DeleteProperty.useMutation()
    const { mutate: deleteMultipleMutate } = properties.DeleteMultipleProperty.useMutation()

    const propertyList = !fetchingProperties ? propertiesData : null
    if (isFetched) totalPages.current = propertyList?.pagination?.totalPages

    // TODO: move this to handleModal function when deleteModal is refactored
    const selectedRowsIds = selectedTableRows.map((row) => row.id)

    const toggleQuickViewModal = useCallback(
        (data) => {
            if (quickViewModal) {
                setQuickViewModal(false)
                setQuickViewData(null)
            } else {
                setQuickViewModal(true)
                setQuickViewData(data)
            }
        },
        [quickViewModal, setQuickViewModal, setQuickViewData]
    )

    const onClickDelete = useCallback(
        (id) => {
            setRowId(id)
            setDeleteModal(true)
        },
        [setDeleteModal]
    )

    const handleShowHideMap = () => {
        setShowMap(!showMap)
    }

    const handleDeleteProperty = () => {
        deleteMutate(rowId)
        setDeleteModal(false)
    }

    const deleteMultipleProperties = () => {
        deleteMultipleMutate(selectedRowsIds)
        dispatch(resetTableCheckbox(true))
        setDeleteModalMulti(false)
    }

    const handleModal = (modalType, actions = []) => {
        const payload = getModalPayloads(modalType, propertyList?.items, selectedRowsIds)

        if (payload) {
            actions.forEach((action) => dispatch(action(payload)))
        }
    }

    const handleDataFetch = (page, limit, order, criteria) => {
        setPropertiesParams({ page, limit, order, criteria })
    }

    const handleRowClick = (id) => {
        history.push(`/properties/${id}`, { page: propertiesParams.page })
    }

    const tableData = useFormatTableData(propertyList?.items, 'properties')
    const columns = TableColumns(onClickDelete, toggleQuickViewModal, 'properties')

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            <Button tag={Link} to="/properties/new" color="add" className="btn-label">
                <i className="mdi mdi-domain align-bottom label-icon align-middle fs-16 me-2"></i>
                {t('button.add')}
            </Button>
            <UncontrolledDropdown className="ms-2">
                <DropdownToggle title={t('table.showMore')} href="#" className="btn btn-primary dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#" onClick={() => setDeleteModalMulti(true)} disabled={!selectedTableRows.length}>
                        <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i> {t('table.action.deleteSelected')}
                    </DropdownItem>
                    <DropdownItem onClick={() => handleModal(OFFER_PRINT_MODAL, [showModal])} disabled={!selectedTableRows.length}>
                        <i className="mdi mdi-file-document-multiple-outline align-bottom me-2 text-muted"></i> {t('app.common.offerOrPrint')}
                    </DropdownItem>
                    <DropdownItem onClick={() => handleModal(TEMPLATES_MODAL, [showModal])} disabled={!selectedTableRows.length}>
                        <i className="mdi mdi-file-document-multiple-outline align-bottom me-2 text-muted"></i> {t('table.action.shopWindowPrint')}
                    </DropdownItem>
                    <AppPermissionsSwitcher permission="ChangeAgent">
                        {({ hasPermission }) =>
                            hasPermission && (
                                <DropdownItem
                                    onClick={() => handleModal(CHANGE_AGENT_MODAL, [showModal, fetchAgents])}
                                    disabled={!selectedTableRows.length}
                                >
                                    <i className="mdi mdi-account-switch-outline align-bottom me-2 text-muted"></i> {t('app.common.switchAgent')}
                                </DropdownItem>
                            )
                        }
                    </AppPermissionsSwitcher>
                    <DropdownItem onClick={handleShowHideMap}>
                        <i className={`mdi ${showMap ? 'mdi-table' : 'mdi-google-maps'} align-bottom me-2 text-muted`}></i> {t('table.action.show')}{' '}
                        {showMap ? t('table.action.show.table') : t('table.action.show.map')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

    return (
        <React.Fragment>
            <div className="page-content">
                {/* TODO: we need better modal management, not a bunch of separate states everywhere... */}
                <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteProperty()} onCloseClick={() => setDeleteModal(false)} />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => deleteMultipleProperties()}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />

                <Container fluid>
                    <PageTitle title={t('app.common.properties')} />
                    <Row>
                        <Col lg={12}>
                            <Card id="RealEstatesList">
                                <CardBody>
                                    {showMap ? (
                                        <MapProperty
                                            tableActions={tableActions}
                                            properties={propertyList?.items}
                                            isDataLoading={fetchingProperties}
                                        />
                                    ) : (
                                        <TableContainer
                                            tableActions={tableActions}
                                            hasColumnSelection={true}
                                            columns={columns}
                                            data={tableData || []}
                                            isGlobalFilter={false}
                                            isAddUserList={false}
                                            customPageSize={20}
                                            className="custom-header-css"
                                            theadClass={'dmn-table-header'}
                                            isRealEstatesListFilter={true}
                                            hasPageSizeSelect={true}
                                            isLoading={fetchingProperties}
                                            handleDataFetch={handleDataFetch}
                                            handleRowClick={handleRowClick}
                                            totalCount={propertyList?.pagination?.totalItems}
                                            pageCount={totalPages.current}
                                            searchCriteria={propertySearchCriteria}
                                            tableHash={appModule}
                                            currentPage={propertiesParams.page}
                                            totalPages={propertyList?.pagination?.totalPages}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <QuickViewModal isOpen={quickViewModal} toggle={toggleQuickViewModal} data={quickViewData} />
            </div>
        </React.Fragment>
    )
}

export default PropertyList
