import { groupByFieldValues } from '../utils'
import { Activity, ActivityData } from './types'

// "Sveukupno" row
export const getActivityOveralls = (reports: ActivityData[], activityTypes: Activity[]) => {
    if (!reports || !activityTypes) return { activityOveralls: [], grandOverall: 0 }

    const groupedData = groupByFieldValues(reports, 'activities', 'id')

    const activityOveralls = activityTypes.map((obj) => {
        const countsPerActivity = groupedData[obj.id] as Activity[]
        const overall = countsPerActivity?.reduce((acc, curr) => acc + curr.count, 0) || 0

        return {
            id: obj.id,
            name: obj.name,
            count: overall,
        }
    })

    const grandOverall = activityOveralls.reduce((acc, curr) => acc + curr.count, 0)

    return { activityOveralls, grandOverall }
}

export const getGroupData = (activity: Activity, agentName: string) => {
    const id = activity.id
    const value = activity.count
    const newValueObj = {
        id,
        name: activity.name,
        [agentName]: value,
    }

    return { id, value, newValueObj }
}
