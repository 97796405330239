import { handleActions } from 'redux-actions'
import {
    fetchDiariesRequest,
    fetchDiariesSuccess,
    fetchDiariesFailure,
    fetchDiaryRequest,
    fetchDiarySuccess,
    fetchDiaryFailure,
    updateDiaryRequest,
    updateDiarySuccess,
    updateDiaryFailure,
    deleteDiaryRequest,
    deleteDiarySuccess,
    deleteDiaryFailure,
    downloadDiaryDocumentRequest,
    downloadDiaryDocumentSuccess,
    downloadDiaryDocumentFailure,
    downloadDiaryDocumentResetStatus,
} from './actions'

export const initialState = {
    items: {},
    item: null,
    count: 0,
    fetching: true,
    fetchingDetails: false,
    deleting: false,
    error: null,
    errorDetails: null,
    submitting: false,
    photoque: {},
    itemphoto: {},
    itemphotocount: 0,
    fetchingPhotos: false,
    itemgroundplancount: 0,
    groundplanque: {},
    itemgroundplan: {},
    fetchingGroundPlans: false,
    pagination: {},
    links: {},
    base64: '',
    fetchingStatus: 'idle',
}

export default handleActions(
    {
        [fetchDiariesRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchDiariesSuccess]: (state, { payload }) => ({
            ...state,
            count: payload?.count ?? 0,
            items: payload?.items,
            fetching: false,
            pagination: payload?.pagination,
        }),
        [fetchDiariesFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [fetchDiaryRequest]: (state) => ({
            ...state,
            fetchingDetails: true,
            errorDetails: null,
        }),
        [fetchDiarySuccess]: (state, { payload }) => {
            const translations = payload?.translations?.reduce((acc, item) => {
                return {
                    ...acc,
                    [`${item.field}Translations`]: {
                        ...acc[`${item.field}Translations`],
                        [item.locale]: item.content,
                    },
                }
            }, {})

            const projectTranslations = payload?.project?.translations?.reduce((acc, item) => {
                return {
                    ...acc,
                    [`${item.field}Translations`]: {
                        ...acc[`${item.field}Translations`],
                        [item.locale]: item.content,
                    },
                }
            }, {})

            return {
                ...state,
                item: {
                    ...payload.item,
                    ...translations,
                    project: {
                        ...payload?.project,
                        ...projectTranslations,
                    },
                },
                links: payload.links,
                fetchingDetails: false,
            }
        },
        [fetchDiaryFailure]: (state, { payload }) => ({
            ...state,
            errorDetails: payload,
            fetchingDetails: false,
        }),
        [updateDiaryRequest]: (state) => {
            return {
                ...state,
                submitting: true,
            }
        },
        [updateDiarySuccess]: (state, { payload }) => {
            return {
                ...state,
                item: payload.data.item,
                submitting: false,
            }
        },
        [updateDiaryFailure]: (state, { payload }) => {
            return {
                ...state,
                submitting: false,
                error: payload,
            }
        },
        [deleteDiaryRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteDiarySuccess]: (state) => {
            return {
                ...state,
                count: state.count - 1,
                deleting: false,
            }
        },
        [deleteDiaryFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        [downloadDiaryDocumentRequest]: (state) => ({
            ...state,
            fetchingStatus: 'request',
            error: null,
        }),
        [downloadDiaryDocumentSuccess]: (state, { payload }) => ({
            ...state,
            base64: payload,
            fetchingStatus: 'success',
        }),
        [downloadDiaryDocumentFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetchingStatus: 'failure',
        }),
        [downloadDiaryDocumentResetStatus]: (state) => ({
            ...state,
            fetchingStatus: 'idle',
        }),
    },
    initialState
)
