import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { ReportParams } from './ReportList/types'
import { ReportRoute } from './constants'

const SelectReport = () => {
    const { t } = useTranslation()
    const { params } = useRouteMatch<ReportParams>()
    const history = useHistory()

    const ReportTypes = useMemo(
        () => [
            { id: ReportRoute.agentActivities, name: t('reports.select.activities') },
            { id: ReportRoute.agentRevenues, name: t('reports.select.revenues') },
            { id: ReportRoute.agentTransactions, name: t('reports.select.transactions') },
            { id: ReportRoute.agentContactStatus, name: t('reports.select.contactStatus') },
            { id: ReportRoute.dataSourceContacts, name: t('reports.select.dataSourceContacts') },
            { id: ReportRoute.dataSourceProperties, name: t('reports.select.dataSourceProperties') },
        ],
        [t]
    )

    const reportType = useMemo(() => {
        if (!params.reportType) return null

        return ReportTypes.find(({ id }) => id === params.reportType)
    }, [params, ReportTypes])

    return (
        <div className="d-flex align-items-center">
            <UncontrolledDropdown className="me-4">
                <DropdownToggle className="btn btn-primary" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                </DropdownToggle>
                <DropdownMenu>
                    {ReportTypes.map(({ id, name }) => (
                        <DropdownItem key={id} onClick={() => history.push(id)} active={id === reportType?.id}>
                            {name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
            {reportType?.name}
        </div>
    )
}

export default SelectReport
