import { colorOptions } from './constants'

/**
 * @param {number} numObjects - number of table columns (categories) displayed on y axis
 * @param {number} numFields - number of data each category displayes
 */
export const handleChartHeight = (numObjects, numFields) => {
    const initialBaseHeight = 500
    const heightPerObject = 50
    const heightPerField = 40
    const additionalHeightPer5Fields = 200

    // increase chart height by 200 every additional 5 numFields
    const additionalBaseHeight = Math.floor(numFields / 5) * additionalHeightPer5Fields
    const baseHeight = initialBaseHeight + additionalBaseHeight

    const totalHeight = baseHeight + numObjects * heightPerObject + numFields * heightPerField

    // totalHeight, barSize, barGap
    return [totalHeight, 8, 2]
}

/**
 * @param {array} data - array of report data objects
 * @param {string} fieldName - name of field from data array which values (objects) we want to extract (eg. activities, revenueTotals...)
 * @param {string} groupByField - name of field from extracted values by which grouping will be applied (eg. id, month...)
 */
export const groupByFieldValues = (data, fieldName, groupByField) => {
    const extractedValues = data.flatMap((item) => item[fieldName] || [])

    return Object.groupBy(extractedValues, (entry) => entry[groupByField])
}

/**
 * @param {array} data - array of report data objects
 * @param {string} dataKey - key to distinct between agent or dataSource object
 * @param {function} handleGroupData - function that returns matching id, value to accumulate & new group data
 */
export const formatGroupedData = ({ data, dataKey, handleGroupData }) => {
    return data?.reduce((acc, curr) => {
        if (!curr) return acc

        const dataObj = curr[dataKey]
        if (!dataObj) return acc

        const fieldName = dataObj.name ? dataObj.name : `${dataObj.firstName} ${dataObj.lastName}`

        const arrayKey = Object.keys(curr).find((key) => Array.isArray(curr[key]))
        if (!arrayKey) return acc

        const arrayData = curr[arrayKey]

        arrayData?.forEach((item) => {
            const { id, value, newValueObj } = handleGroupData(item, fieldName)
            const existingGroup = acc.find((group) => group.id === id)

            if (existingGroup) {
                existingGroup[fieldName] = value
            } else {
                const newGroup = newValueObj
                acc.push(newGroup)
            }
        })

        return acc
    }, [])
}

// "Ukupno" column
export const getTotals = (reports) => {
    return reports?.reduce((acc, curr) => {
        if (!curr || typeof curr !== 'object') return acc

        const isDataSource = 'dataSource' in curr
        const { dataSource, agent, contactGrandTotal, realizedPropertyGrandTotal, agentGrandTotal } = curr
        const dataSourceTotal = contactGrandTotal || realizedPropertyGrandTotal

        const fieldName = isDataSource ? dataSource?.name : `${agent?.firstName} ${agent?.lastName}`
        const grandTotal = isDataSource ? dataSourceTotal : agentGrandTotal

        acc[fieldName] = grandTotal

        return acc
    }, {})
}
export const handleChartColors = (reportsLength) => {
    const overallColor = colorOptions[0]

    const reportColors = []
    for (let i = 0; i < reportsLength; i++) {
        // start from index 1 and cycle through the remaining colors
        const colorIndex = (i % (colorOptions.length - 1)) + 1
        reportColors.push(colorOptions[colorIndex])
    }

    return {
        overallColor,
        reportColors,
    }
}
