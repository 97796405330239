import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainer from '../../../Components/Table/TableContainer'
import { RowData } from '../globalTypes'
import { ContactReportProps } from './types'
import { getContactOveralls } from './utils'

const ContactReportTable = (props: ContactReportProps) => {
    const { reportsData, reportsFetching } = props
    const { t } = useTranslation()

    const reports = useMemo(() => reportsData?.data || [], [reportsData])
    const months = useMemo(() => reportsData?.timePeriod || [], [reportsData])

    const columns = useMemo(() => {
        const dataSourceColumn = {
            Header: t('app.common.dataSource'),
            id: 'dataSource',
            accessor: (row: RowData) => row.dataSource?.name,
            filterable: false,
        }

        const monthColumns = months?.map(({ month, monthName, year }) => ({
            Header: monthName,
            id: `${month}/${year}`,
            accessor: (row: RowData) => {
                const contactTotal = row.contactTotals?.find((ct) => ct?.month === month)
                if (!contactTotal) return null

                return <div className="text-center">{contactTotal.total}</div>
            },
            filterable: false,
        }))

        const totalColumn = {
            Header: t('app.common.total'),
            id: 'dataSourceTotal',
            accessor: (row: RowData) => <div className="text-center fw-bolder">{row.contactGrandTotal}</div>,
            filterable: false,
        }

        return [dataSourceColumn, ...monthColumns, totalColumn]
    }, [months, t])

    const tableFooter = useMemo(() => {
        const { monthOveralls, grandOverall } = getContactOveralls(reports, months)

        return (
            <tr>
                <td>{t('app.common.overall')}</td>
                {monthOveralls.map((overall, i) => (
                    <td key={i} className="text-center">
                        {overall.count}
                    </td>
                ))}
                <td className="text-center fw-bolder">{grandOverall}</td>
            </tr>
        )
    }, [reports, months, t])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            tableFooter={tableFooter}
            tableHash={'reports_data-source-contacts'}
            hidePagination={true}
            {...({} as any)} // temporary fix
        />
    )
}

export default ContactReportTable
