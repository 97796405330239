import { TimePeriod } from '../globalTypes'
import { groupByFieldValues } from '../utils'
import { TransactionData, TransactionTotal } from './types'

// "Sveukupno" row
export const getTransactionOveralls = (reports: TransactionData[], months: TimePeriod[]) => {
    if (!reports || !months) return { monthOveralls: [], grandOverall: 0 }

    const groupedData = groupByFieldValues(reports, 'transactionTotals', 'month')

    const monthOveralls = months.map((obj) => {
        const totalsByMonth = groupedData[obj.month] as TransactionTotal[]

        const overall =
            totalsByMonth?.reduce((acc, curr) => {
                const soldCount = curr.soldCount || 0
                const rentedCount = curr.rentedCount || 0

                return acc + soldCount + rentedCount
            }, 0) || 0

        return {
            id: obj.month,
            monthName: obj.monthName,
            count: overall,
        }
    })

    const grandOverall = monthOveralls.reduce((acc, curr) => acc + curr.count, 0)

    return { monthOveralls, grandOverall }
}

export const getGroupData = (transactionTotal: TransactionTotal, agentName: string) => {
    const id = transactionTotal.month
    const value = (transactionTotal.soldCount || 0) + (transactionTotal.rentedCount || 0)
    const newValueObj = {
        id,
        [agentName]: value,
    }

    return { id, value, newValueObj }
}
