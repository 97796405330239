const realEstateGeneralFields = {
    EMPTY: 'empty',
    EMPTY_COLUMN: 'empty',
    EMPTY_ROW: 'emptyRow',
    ID: 'id',
    INTERNAL_ID: 'internal_id',
    PROPERTY_SERVICE_ID: 'property_service_id',
    PROPERTY_TYPE_ID: 'property_type_id',
    PROPERTY_SUB_TYPE_ID: 'property_sub_type_id',
    PROPERTY_RECEIPT_DATE: 'property_receipt_date',
    OWNER_ID: 'owner_id',
    OWNER_COMPANY_ID: 'owner_company_id',
    DATA_SOURCE_ID: 'data_source_id',
    PROPERTY_LAND_USAGE_ID: 'property_land_usage_id',
    PROPERTY_PRIMARY_USAGE_ID: 'property_primary_usage_id',
    CONTRACT_NO: 'contract_no',
    CONTRACT_DATE: 'contract_date',
    AVAILABLE_FROM_DATE: 'rented_to_date',
    COUNTRY_ID: 'country_id',
    COUNTY_ID: 'county_id',
    CITY_ID: 'city_id',
    WIDER_AREA_ID: 'wider_area_id',
    QUARTER_ID: 'quarter_id',
    ISLAND_ID: 'island_id',
    STREET: 'street',
    STREET_NUMBER: 'street_number',
    STREET_SYNC: 'street_sync',
    PROPERTY_LABEL: 'property_label',
    PROPERTY_POSITION_ID: 'property_position_id',
    PRICE: 'price',
    PRICE_CLIENT: 'price_client',
    PRICE_M2: 'price_m2',
    PRICE_OLD: 'price_old',
    PRICE_INITIAL: 'price_initial',
    VAT_INCLUDED: 'vat_included',
    VAT_NOT_INCLUDED: 'vat_not_included',
    UTILITIES_INCLUDED: 'utilities_included',
    PRICE_ON_REQUEST: 'price_on_request',
    EXCHANGE_POSSIBLE: 'exchange_possible',
    MAINTENCE_COSTS: 'maintenance_costs',
    MAINTENCE_COSTS_TYPE: 'maintenance_costs_type',
    COMMON_MAINTENCE_COSTS: 'common_maintenance_costs',
    COMMON_MAINTENCE_COSTS_TYPE: 'common_maintenance_costs_type',
    COMMON_UTILITY_COSTS: 'common_utility_costs',
    COMMON_UTILITY_COSTS_TYPE: 'common_utility_costs_type',
    PARKING_SPACE_PRICE: 'parking_space_price',
    PARKING_SPACE_NUMBER: 'parking_space_number',
    MORTGAGE_INFO: 'mortgage_info',
    PROPERTY_HOUSE_TYPE_ID: 'property_house_type_id',
    AREA: 'area',
    AREA_NETT: 'area_nett',
    AREA_GARDEN: 'area_garden',
    AREA_STORAGE: 'area_storage',
    PLOT_AREA: 'plot_area',
    PLOT_WIDTH: 'plot_width',
    PLOT_LENGTH: 'plot_length',
    CONSTRUCTION_COEFFICIENT: 'construction_coefficient',
    CONSTRUCTION_FLOORS_NO: 'construction_floors_no',
    MAX_CONTRUCTION_AREA: 'max_construction_area',
    PROPERTY_ROOMS_NO_ID: 'property_rooms_no_id',
    PROPERTY_ROOMS_TYPE_ID: 'property_rooms_type_id', // ERSTE ONLY FIELD
    ROOMS_NO: 'rooms_no',
    BEDROOMS_NO: 'bedrooms_no',
    BED_NO: 'bed_no',
    BATHROOM_TOILET_NO: 'bathroom_toilet_no',
    BATHROOM_NO: 'bathroom_no',
    COMMON_BATHROOM_NO: 'comm_bathroom_no',
    TOILET_NO: 'toilet_no',
    HOUSING_UNITS_NUMBER: 'housing_units_number',
    CEILING_HEIGHT: 'ceiling_height',
    CONSTRUCTION_YEAR: 'construction_year',
    ADAPTATION_YEAR: 'adaptation_year',
    PROPERTY_FLOOR_ID: 'property_floor_id',
    PROPERTY_FLOOR_POSITION_ID: 'property_floor_position_id',
    FLOOR_NO: 'floor_no',
    FLOORS_TOTAL_NO: 'floors_total_no',
    PROPERTY_ORIENTATION_ID: 'property_orientation_id',
    PROPERTY_HEATING_ID: 'property_heating_id',
    PROPERTY_CONDITION_ID: 'property_condition_id',
    PROPERTY_WINDOWS_DOORS_ID: 'property_windows_doors_id',
    PROPERTY_PUBLIC_TRANSPORTATION_ID: 'property_public_transportation_id',
    DISTANCE_SEA: 'distance_sea',
    DISTANCE_RIVER: 'distance_river',
    DISTANCE_BEACH: 'distance_beach',
    DISTANCE_CITY_CENTRE: 'distance_city_centre',
    DISTANCE_SCHOOL: 'distance_school',
    DISTANCE_PUBLIC_TRANSPORTATION: 'distance_public_transportation',
    DISTANCE_PATHWAY: 'distance_pathway',
    PROPERTY_SPECIAL_FEATURE_ID: 'property_special_feature_id',
    PROPERTY_DESCRIPTION: 'property_descriptions_id',
    PROPERTY_TITLE: 'property_title',
    PROPERTY_UTILITY_ID: 'property_utility_id',
    PROPERTY_EQUIPMENT_ID: 'property_equipment_id',
    PROPERTY_SPACE_ID: 'property_space_id',
    PROPERTY_ENERGY_EFFICIENCY_ID: 'property_energy_efficiency_id',
    PROPERTY_ACCESS_ID: 'property_access_id',
    PROPERTY_PERMIT_ID: 'property_permit_id',
    AGENCY_COMMISSION_ID: 'agency_commission_id',
    AGENT_ID: 'agent_id',
    PROJECT_ID: 'project_id',
    SPACE_COUNT: 'space_count',
    SPACE_AREA: 'space_area',
}

const realEstateDescriptionFields = {
    PROPERTY_DESCRIPTION: 'property_description',
    PROPERTY_TITLE: 'property_title',
}

const propertyExportFields = {
    PROPERTY_PORTAL_CUSTOM_VALUE: 'propertyPortalCustomValue',
    PROPERTY_PORTAL_ID: 'property_portal_id',
    PROPERTY_WEBSITE_ID: 'property_website_group',
    EXPORT_NJUSKALO: 'export_njuskalo',
    EXPORT_INDOMIO: 'export_indomio',
    EXPORT_OGLASNIK: 'export_oglasnik',
    EXPORT_NEKRETINE365: 'export_nekretnine365',
    EXPORT_BURZA: 'export_burza',
    EXPORT_WEB: 'export_web',
}

const realEstateMaterials3DVideoFields = {
    VIRTUAL_WALK_URL: 'virtual_walk_url',
    PROPERTY_VIDEO_URL: 'property_video_url',
}

const propertyTransactionsFields = {
    CONTACT_ID: 'contact_id',
    PROPERTY_TRANSACTION_AGENT_ID: 'property_transaction_agent_id',
    PROJECT_ID: 'project_id',
    PROPERTY_STATUS_ID: 'property_status_id',
    PRICE_FINAL: 'price_final',
    COMMISION_SELLER: 'commission_seller',
    COMMISION_SELLER_TYPE_ID: 'commission_seller_type',
    COMMISION_BUYER: 'commission_buyer',
    COMMISION_BUYER_TYPE_ID: 'commission_buyer_type',
    CONCLUSION_PLACE: 'conclusion_place',
    CONCLUSION_DATE: 'conclusion_date',
    ADVANCE_AMOUNT: 'advance_amount',
    ADVANCE_DATE: 'advance_payment_date',
    FINAL_PAYMENT_DATE: 'final_payment_date',
    PROPERTY_TRANSACTION_RISK_DEGREE_ID: 'property_transaction_risk_degree_id',
    PROPERTY_MAIN_REGISTER_ID: 'property_main_register_id',
    PROPERTY_REGISTRY_MUNICIPALITY: 'property_registry_municipality',
    PROPERTY_REGISTRY_INSERT: 'property_registry_insert',
    PROPERTY_REGISTRY_PARCEL_NO: 'property_registry_parcel_no',
    PROPERTY_REGISTRY_SUBINSERT: 'property_registry_subinsert',
    PROPERTY_REGISTRY_DEPARTMENT_KPU_ID: 'property_registry_department_kpu_id',
    PROPERTY_SUBINSERT_KPU: 'property_subinsert_kpu',
    PROPERTY_PUK_DEPARTMENT_ID: 'property_puk_department_id',
    PROPERTY_CADASTRAL_MUNCIPALITY: 'property_cadastral_municipality',
    PROPERTY_CADASTRAL_PARCEL_NO: 'property_cadastral_parcel_no',
    PROPERTY_POSSESSORY_SHEET_NO: 'property_possessory_sheet_no',
    PROPERTY_MAIN_REGISTER_DEPARTMENT_ID: 'property_main_register_department_id',
    PROPERTY_REGISTRY_DEPARTMENT_KPU_SUBINSERT_ID: 'property_registry_department_kpu_subinsert_id',
    PROPERTY_PUK_ID: 'property_puk_id',
    PROPERTY_LAND_REGISTRY_STATUS_ID: 'property_land_registry_status_id',
    PROPERTY_OWNERSHIP_ACQUISITION_ID: 'property_ownership_acquisition_id',
    PROPERTY_OWNERSHIP_ACQUISITION_PROOF_ID: 'property_ownership_acquisition_proof_id',
    RESERVATION_DATE: 'reservation_date',
    PROPERTY_BUILDING_NUMBER: 'property_building_number', // SLO MARKET ONLY FIELD
    PROPERTY_BUILDING_PART_NUMBER: 'property_building_part_number', // SLO MARKET ONLY FIELD
}

const propertyOfferPrintFields = {
    OFFER_LOCALE: 'offer_locale',
    AGENT_ID: 'agent_id',
    CONTACT_ID: 'contact_id',
    CONTACT_EMAIL: 'contact_email',
    COMPANY_ID: 'company_id',
    COMPANY_EMAIL: 'company_email',
    OFFER_TEMPLATE: 'offer_template',

    PROPERTY_ID: 'property_id',
    OFFER_PHOTO_ORDER: 'offer_photo_order',
    OFFER_INCLUDE_GROUND_PLAN: 'offer_include_ground_plan',
    OFFER_INCLUDE_WATERMARK: 'offer_include_watermark',
    OFFER_INCLUDE_MAP: 'offer_include_map',
    OFFER_EXCLUDE_HEADER: 'offer_exclude_header',
    OFFER_EXCLUDE_AGENT: 'offer_exclude_agent',
    OFFER_EXCLUDE_URL: 'offer_exclude_url',
    OFFER_PROPERTY_PRICE: 'offer_property_price',
    OFFER_PROPERTY_PRICEM2: 'offer_property_priceM2',
    OFFER_PROPERTY_AREA: 'offer_property_area',
    OFFER_EMAIL_TITLE: 'offer_email_title',
    OFFER_EMAIL_MESSAGE: 'offer_email_message',
}

const propertyDetailSearchFields = {
    ID: 'id',
    INTERNAL_ID: 'internalId',
    INTERNAL_APP_ID: 'internalAppId',
    PROPERTY_SERVICE: 'propertyService',
    PROPERTY_STATUS: 'propertyStatus',
    PROPERTY_TYPE: 'propertyType',
    PROPERTY_SUB_TYPE: 'propertySubType',
    PRICE_FROM: 'priceFrom',
    PRICE_TO: 'priceTo',
    AREA_FROM: 'areaFrom',
    AREA_TO: 'areaTo',
    ROOMS_NO_FROM: 'roomsNoFrom',
    ROOMS_NO_TO: 'roomsNoTo',
    BEDROOMS_NO_FROM: 'bedroomsNoFrom',
    BEDROOMS_NO_TO: 'bedroomsNoTo',
    FLOOR_NO_FROM: 'floorNoFrom',
    FLOOR_NO_TO: 'floorNoTo',
    PROPERTY_DESCRIPTIONS: 'propertyDescriptions',
    PROPERTY_SPACE: 'propertySpace',
    COUNTRY: 'country',
    COUNTY: 'county',
    CITY: 'city',
    WIDER_AREA: 'widerArea',
    QUARTER: 'quarter',
    ISLAND: 'island',
    STREET: 'street',
    OWNER: 'owner',
    PHONE_NUMBER: 'phoneNumber',
    AGENT: 'agent',
    PROPERTY_RECEIPT_DATE_FROM: 'propertyReceiptDateFrom',
    PROPERTY_RECEIPT_DATE_TO: 'propertyReceiptDateTo',
    AVAILABLE_FROM_DATE_FROM: 'availableFromDateFrom',
    AVAILABLE_FROM_DATE_TO: 'availableFromDateTo',
    WEB: 'propertyWeb',
    ATTRIBUTE: 'attribute',
    PORTAL: 'propertyPortal',
    PROPERTY_CONDITION: 'propertyCondition',
    PROPERTY_ENERGY_EFFICIENCY: 'propertyEnergyEfficiency',
    DISTANCE_SEA: 'distanceSea',
    PROPERTY_UTILITY: 'propertyUtility',
    PROPERTY_EQUIPMENT: 'propertyEquipment',
    PROPERTY_FLOOR: 'propertyFloor',
}

export {
    propertyDetailSearchFields,
    propertyExportFields,
    propertyOfferPrintFields,
    propertyTransactionsFields,
    realEstateDescriptionFields,
    realEstateGeneralFields,
    realEstateMaterials3DVideoFields,
}
