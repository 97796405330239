import dayjs from 'dayjs'

export const defaultStartDate = dayjs().subtract(11, 'months').format('YYYY-MM-DD')
export const defaultEndDate = dayjs().format('YYYY-MM-DD')
export const dataFallback = '--'

export const colorOptions = [
    'darkcyan',
    'deepskyblue',
    'cornflowerblue',
    'darkorchid',
    'crimson',
    'tomato',
    'violet',
    'gold',
    'olive',
    'mediumseagreen',
    'darkturquoise',
]

export const ReportRoute = {
    agentActivities: 'agent-activities',
    agentRevenues: 'agent-revenues',
    agentTransactions: 'agent-transactions',
    agentContactStatus: 'agent-contact-status',
    dataSourceContacts: 'data-source-contacts',
    dataSourceProperties: 'data-source-properties',
}
