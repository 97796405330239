import { renderCurrency } from '../../../utils/property'
import { FORM_DIRECTION, REAL_ESTATE_TYPES } from '../../constants'
import responseFields from '../../constants/responseFields'
import i18n from '../../../i18n'
import {
    contactDetailSearchFields,
    contactDocumentFields,
    contactGeneralFields,
    contactNoteFields,
    contactOtherInfoFields,
    contactProfileFields,
} from './fields'
import {
    contactDetailSearchFieldTypes,
    contactDocumentFieldTypes,
    contactGeneralFieldTypes,
    contactNoteFieldTypes,
    contactOtherInfoFieldTypes,
    contactProfileDetailSearchFieldTypes,
    contactProfileFieldTypes,
} from './fieldTypes'
import { IS_ERSTE } from '../../constants'

export const contactGeneralInputs = () => {
    const inputs = contactGeneralFieldTypes()
    return {
        sideForm: [
            inputs[contactGeneralFields.ID],
            inputs[contactGeneralFields.CONTACT_TITLE_ID],
            inputs[contactGeneralFields.FIRST_NAME],
            inputs[contactGeneralFields.LAST_NAME],
            inputs[contactGeneralFields.INITIAL_CONTACT_DATE],
            inputs[contactGeneralFields.CONTACT_PRIORITY_ID],
            inputs[contactGeneralFields.DATA_SOURCE_ID],
            IS_ERSTE && inputs[contactGeneralFields.CONTACT_SOURCE_ID],
            IS_ERSTE && inputs[contactGeneralFields.CONTACT_ABOUT],
            inputs[contactGeneralFields.CONTACT_STATUS_ID],
        ],
        mainForm: [
            {
                type: 'fieldArray',
                name: 'contact_phone_group',
                children: [inputs[contactGeneralFields.PHONE_NUMBER], inputs[contactGeneralFields.CONTACT_PHONE_TYPE_ID]],
            },
            {
                type: 'fieldArray',
                name: 'contact_email_group',
                children: [inputs[contactGeneralFields.EMAIL], inputs[contactGeneralFields.CONTACT_EMAIL_TYPE_ID]],
            },
            {
                type: 'array',
                name: 'addressGroup',
                children: [inputs[contactGeneralFields.STREET], inputs[contactGeneralFields.STREET_NUMBER]],
            },
            {
                type: 'array',
                name: 'postalCodeGroup',
                children: [inputs[contactGeneralFields.ZIP_CODE], inputs[contactGeneralFields.CITY], inputs[contactGeneralFields.CONTACT_COUNTRY_ID]],
            },
            {
                type: 'array',
                name: 'queryCategoryroup',
                children: [inputs[contactGeneralFields.CONTACT_QUERY_CATEGORY_ID]],
            },
            // {
            //     type: "array",
            //     name: 'contactPersonGroup',
            //     children: [
            //         inputs[contactGeneralFields.ADDITIONAL_CONTACT_ID],
            //     ]
            // },
            {
                type: 'array',
                name: 'agentGroup',
                children: [inputs[contactGeneralFields.AGENT_ID]],
            },
        ],
    }
}

export const contactOtherInfoInputs = () => {
    const inputs = contactOtherInfoFieldTypes()
    return {
        sideForm: [
            inputs[contactOtherInfoFields.CONTACT_DESCRIPTION_ID],
            inputs[contactOtherInfoFields.CONTACT_GENDER_ID],
            inputs[contactOtherInfoFields.OIB],
        ],
        mainForm: [
            {
                type: 'fieldArray',
                name: 'contact_person_company_group',
                children: [
                    inputs[contactOtherInfoFields.COMPANY_ID],
                    inputs[contactOtherInfoFields.CONTACT_JOB_TITLE_ID],
                    inputs[contactOtherInfoFields.OWNERSHIP_PERCENTAGE],
                    inputs[contactOtherInfoFields.EMPLOYMENT_TYPE],
                ],
            },
            {
                type: 'array',
                name: 'birthGroup',
                children: [
                    // inputs[contactOtherInfoFields.CONTACT_DEFINITION_ID],
                    inputs[contactOtherInfoFields.BIRTH_DATE],
                    inputs[contactOtherInfoFields.CONTACT_MARTIAL_STATUS_ID],
                    inputs[contactOtherInfoFields.BIRTH_PLACE],
                    inputs[contactOtherInfoFields.BIRTH_COUNTRY_ID],
                ],
            },
            // inputs[contactOtherInfoFields.INITIAL_CALL_INFO],
            inputs[contactOtherInfoFields.NOTES],
        ],
    }
}

export const contactNoteInputs = () => {
    const inputs = contactNoteFieldTypes()

    return {
        mainForm: [
            inputs[contactNoteFields.NOTE_TITLE],
            inputs[contactNoteFields.NOTE_DATE],
            inputs[contactNoteFields.NOTE],
            inputs[contactNoteFields.CONTACT_NOTE_AGENT_ID],
        ],
    }
}

export const contactProfileInputs = (appSettings, propertyTypeValue, propertyServiceValue, isEdit) => {
    const inputs = contactProfileFieldTypes(isEdit, appSettings)
    return {
        sideForm: [
            inputs[contactProfileFields.CONTACT_PROFILE_SERVICE_ID],
            inputs[contactProfileFields.PROPERTY_TYPE_ID],
            inputs[contactProfileFields.PROPERTY_SUB_TYPE_ID],
            inputs[contactProfileFields.CONTACT_PROFILE_STATUS_ID],
            inputs[contactProfileFields.CONTACT_FINANCING_ID],
            inputs[contactProfileFields.CONTACT_PROFILE_DURATION_ID],
            inputs[contactProfileFields.CONTACT_DEFINITION_ID],
            // inputs[contactProfileFields.CONTACT_PROFILE_REMARK_ID],
        ],
        mainForm: [
            {
                type: 'array',
                name: 'locationGroup',
                children: [
                    inputs[contactProfileFields.COUNTRY_ID],
                    inputs[contactProfileFields.COUNTY_ID],
                    inputs[contactProfileFields.CITY_ID],
                    inputs[contactProfileFields.WIDER_AREA_ID],
                    inputs[contactProfileFields.QUARTER_ID],
                    inputs[contactProfileFields.ISLAND_ID],
                ],
            },
            {
                type: 'array',
                name: 'priceGroup',
                children: [
                    {
                        type: 'grouped',
                        name: 'priceGrouped',
                        colProps: {
                            sm: 12,
                            xxl: 4,
                        },
                        items: [inputs[contactProfileFields.PRICE_FROM], inputs[contactProfileFields.PRICE_TO]],
                    },
                    // {
                    //     type: 'grouped',
                    //     name: 'pricem2Grouped',
                    //     colProps: {
                    //         sm: 12,
                    //         xxl: 4,
                    //     },
                    //     items: [
                    //         inputs[contactProfileFields.PRICE_M2_FROM],
                    //         inputs[contactProfileFields.PRICE_M2_TO],
                    //     ]
                    // }
                ],
            },
            {
                type: 'array',
                name: 'roomsGroup',
                children: [
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                        ? [
                              {
                                  type: 'grouped',
                                  name: 'roomsGrouped',
                                  colProps: {
                                      sm: 12,
                                      xxl: 4,
                                  },
                                  items: [inputs[contactProfileFields.ROOMS_FROM], inputs[contactProfileFields.ROOMS_TO]],
                              },
                          ]
                        : []),
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString()
                        ? [
                              {
                                  type: 'grouped',
                                  name: 'bedRoomsGrouped',
                                  colProps: {
                                      sm: 12,
                                      xxl: 4,
                                  },
                                  items: [inputs[contactProfileFields.BEDROOMS_FROM], inputs[contactProfileFields.BEDROOMS_TO]],
                              },
                          ]
                        : []),
                ],
            },
            {
                type: 'array',
                name: 'areaGroup',
                children: [
                    {
                        type: 'grouped',
                        name: 'areaGrouped',
                        items: [
                            inputs[contactProfileFields.AREA_FROM],
                            inputs[contactProfileFields.AREA_TO],
                            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.land.toString()
                                ? [inputs[contactProfileFields.MAX_CONTSRUCTION_AREA]]
                                : []),
                        ],
                    },
                ],
            },
            {
                type: 'array',
                name: 'floorGroup',
                children: [
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ? [inputs[contactProfileFields.PROPERTY_FLOOR_ID]] : []),
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                        ? [
                              inputs[contactProfileFields.PROPERTY_FLOOR_POSITION_ID],
                              inputs[contactProfileFields.FLOOR_NO_FROM],
                              inputs[contactProfileFields.FLOOR_NO_TO],
                          ]
                        : []),
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString()
                        ? [inputs[contactProfileFields.EXCLUDE_BASEMENT], inputs[contactProfileFields.EXCLUDE_LAST_FLOOR]]
                        : []),
                ],
            },
            {
                type: 'array',
                name: 'relatedStuffGroup',
                children: [
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                        ? [inputs[contactProfileFields.PROPERTY_SPACE_ID]]
                        : []),
                    // {
                    //     type: 'select-with-inputs',
                    //     name: 'propertyPropertySpace_group',
                    //     label: 'Prostori vezani uz nekretninu',
                    //     selectOptionsName: contactProfileFields.PROPERTY_SPACE_ID,
                    //     inputs: [
                    //         inputs[contactProfileFields.PROPERTY_SPACE_ID],
                    //         inputs[contactProfileFields.SPACE_COUNT],
                    //         inputs[contactProfileFields.SPACE_AREA],
                    //     ]
                    // },
                    ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString()
                        ? [inputs[contactProfileFields.PROPERTY_DESCRIPTIONS_ID]]
                        : []),
                    ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString() &&
                    propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.land.toString()
                        ? [inputs[contactProfileFields.PROPERTY_EQUIPMENT_ID]]
                        : []),
                    ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString()
                        ? [inputs[contactProfileFields.PROPERTY_UTILITY_ID]]
                        : []),
                    ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString() &&
                    propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.land.toString()
                        ? [inputs[contactProfileFields.PROPERTY_HEATING_ID]]
                        : []),
                ],
            },
            {
                type: 'array',
                name: 'specialRequest',
                children: [inputs[contactProfileFields.SPECIAL_REQUEST]],
            },
        ],
    }
}

export const contactDetailSearchInputs = () => {
    const inputs = contactDetailSearchFieldTypes()

    return {
        config: {
            direction: FORM_DIRECTION.COLUMN,
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        columns: [
            [
                inputs[contactDetailSearchFields.NAME],
                inputs[contactDetailSearchFields.CONTACT_PHONE],
                inputs[contactDetailSearchFields.CONTACT_EMAIL],
                inputs[contactDetailSearchFields.AGENT],
                {
                    type: 'grouped',
                    name: 'initialContactGrouped',
                    label: i18n.t('app.common.contact.establishmentDate'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[contactDetailSearchFields.INITIAL_CONTACT_DATE_FROM], inputs[contactDetailSearchFields.INITIAL_CONTACT_DATE_TO]],
                },
                {
                    type: 'grouped',
                    name: 'previousActivityGrouped',
                    label: i18n.t('app.common.company.previousActivity'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[contactDetailSearchFields.LAST_ACTIVITY_DATE_FROM], inputs[contactDetailSearchFields.LAST_ACTIVITY_DATE_TO]],
                },
            ],
            [inputs[contactDetailSearchFields.CONTACT_COUNTRY], inputs[contactDetailSearchFields.CITY]],
            [
                inputs[contactDetailSearchFields.CONTACT_STATUS],
                inputs[contactDetailSearchFields.CONTACT_PROFILE_SERVICE],
                inputs[contactDetailSearchFields.CONTACT_PROFILE_STATUS],
                inputs[contactDetailSearchFields.CONTACT_QUERY_CATEGORY],
                inputs[contactDetailSearchFields.CONTACT_PRIORITY],
                inputs[contactDetailSearchFields.DATA_SOURCE],
            ],
        ],
    }
}

export const contactProfileDetailSearchInputs = (appSettings) => {
    const inputs = contactProfileDetailSearchFieldTypes()
    return {
        config: {
            direction: FORM_DIRECTION.COLUMN,
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        columns: [
            [
                inputs[responseFields.PROPERTY_TYPE],
                inputs[responseFields.PROPERTY_SUB_TYPE],
                {
                    type: 'grouped',
                    name: 'priceGrouped',
                    label: `${i18n.t('table.header.profile.price')} ${renderCurrency(appSettings?.currency)}`,
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.PRICE_FROM], inputs[responseFields.PRICE_TO]],
                },
                {
                    type: 'grouped',
                    name: 'roomsGrouped',
                    label: i18n.t('app.common.property.numberOfRooms'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.ROOMS_FROM], inputs[responseFields.ROOMS_TO]],
                },
                {
                    type: 'grouped',
                    name: 'bedroomsGrouped',
                    label: i18n.t('app.common.bedrooms'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.BEDROOMS_FROM], inputs[responseFields.BEDROOMS_TO]],
                },
            ],
            [
                inputs[responseFields.CONTACT_PROFILE_DURATION],
                {
                    type: 'empty-column',
                    name: 'emptyColumn1',
                    colProps: {
                        sm: 0,
                        xl: 12,
                    },
                },
                {
                    type: 'grouped',
                    name: 'areaGrouped',
                    label: i18n.t('app.common.spaceArea'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.AREA_FROM], inputs[responseFields.AREA_TO]],
                },
                {
                    type: 'grouped',
                    name: 'floorGrouped',
                    label: i18n.t('app.common.floorNumber'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.FLOOR_FROM], inputs[responseFields.FLOOR_TO]],
                },
                inputs[responseFields.PROPERTY_FLOOR],
            ],
            [
                inputs[responseFields.COUNTRY],
                inputs[responseFields.COUNTY],
                inputs[responseFields.CITY],
                inputs[responseFields.WIDER_AREA],
                inputs[responseFields.QUARTER],
            ],
        ],
    }
}

export const contactDocumentInputs = () => {
    const inputs = contactDocumentFieldTypes()

    return {
        sideForm: [
            inputs[contactDocumentFields.DOCUMENT_PROPRETY_ID],
            inputs[contactDocumentFields.DOCUMENT_PROJECT_ID],
            inputs[contactDocumentFields.DOCUMENT_CONTACT_ID],
            inputs[contactDocumentFields.DOCUMENT_TYPE],
            inputs[contactDocumentFields.DOCUMENT_CREATION_DATE],
            inputs[contactDocumentFields.DOCUMENT_VALIDITY_DATE],
            inputs[contactDocumentFields.FILE_SYNC],
        ],
    }
}
